const initialState = [
    {
        lable: 'Москва',
        value: '2000000',
    },
    {
        lable: 'Санкт-Петербург',
        value: '2004000',
    },
    {
        value: '2004660',
        label: 'Бологое',
    },
    {
        lable: 'Тверь',
        value: '2004600',
    },
    {
        lable: 'Москва-Октябрьская (Ленинградский вкз.)',
        value: '2006004',
    },
    {
        lable: 'Санкт-Петербург-Главный (Московский вкз.)',
        value: '2004001',
    },
    {
        lable: 'Нижний Новгород (Московский вкз.)',
        value: '2060001',
    },
    {
        lable: 'Дзержинск',
        value: '2060420',
    },
    {
        lable: 'Владимир-Пассажирский',
        value: '2060340',
    },
    {
        lable: 'Москва-Курская (Курский вкз.)',
        value: '2000001',
    },
    {
        lable: 'Вышний Волочек',
        value: '2004460',
    },
    {
        lable: 'Угловка',
        value: '2004578',
    },
    {
        lable: 'Окуловка',
        value: '2004579',
    },
    {
        lable: 'Чудово Московское',
        value: '2004615',
    },
];

export default (state = initialState,
                action) => {

    switch (action.type) {

        default:
            return state;
    }
};
