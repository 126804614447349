import axiosDispatch from './../axios/dispatch';
import * as types from './../constants/ActionTypesApp';

export function notification(type, message) {
  console.log('APP NOTIFICATION', type, message);
}

export function appError(msg) {
  return {
    type   :  types.APP_ERROR_HANDLE,
    payload: {msg},
  };
}

export function loaderShow(active) {
  return {
    type   : types.APP_LOADER_ACTIVE,
    payload: {active},
  }
}

export function canNot(active) {
  return {
    type   : types.APP_LOADER_ABORT,
    payload: {active},
  }
}
export function canError(error) {
  return {
    type   : types.APP_LOADER_ABORT_ERROR,
    payload: {error},
  }
}

export function waitTicketAction(active) {
  return {
    type   : types.APP_LOADER_WAIT_TICKET,
    payload: {active},
  }
}

export function contactFormSend(params) {
  return dispatch => axiosDispatch(dispatch, '/index/contact', params);
}

