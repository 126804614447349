import axiosDispatch from './../axios/dispatch';
import {getTasks} from './User';
import {modalOpen} from './Auth';
import {appError} from './App';

export function create(params) {
  return (dispatch,
          getState) => {
    return new Promise(resolve => {

        if (!getState().auth.jwt) return dispatch(modalOpen('signIn'));

        axiosDispatch(dispatch, '/tasks/create', params)
          .then(data => resolve(data))
          .catch(error => dispatch(appError(error)));
      });
  }
}

export function createNew(params) {
  return (dispatch,
          getState) => {
    return new Promise((resolve,
                        reject) => {

      if (!getState().auth.jwt) return dispatch(modalOpen('signIn'));

      axiosDispatch(dispatch, '/tasks/createNew', params)
        .then(data => {
            resolve(data);
          })
        .catch(error => {
            dispatch(appError(error))
            reject(error``);
          });
    });
  }
}

export function remove(id) {
  return dispatch => {
      axiosDispatch(dispatch, '/tasks/remove', {id})
        .then(data => dispatch(getTasks()))
        .catch(error => dispatch(appError(error)));
    }
}
