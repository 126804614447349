import React, {Component} from 'react';
import * as time from './../../utility/time';
import {withRouter} from 'react-router-dom';
import Progress from './../../containers/Search/Progress';
import Car from './../Cars';
import {Helmet} from 'react-helmet';

class Train extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availableSeats: [],
            selectedStart: 0,
            selectedEnd: 1,
            selectedSeats: [],
            selectedCarNumber: null,
            fee: 0,
            carType: null,
            classesName: null,
            additionalPlaceRequirements: null,
            classCode: null,
            amount:''
        }
        this.schemaRef = React.createRef();
        this.seatsRef = React.createRef();
    }

    seatSelect = (seatSelect,
                  price,
                  fee,
                  additionalPlaceRequirements) => {
        const seat = parseInt(seatSelect);
        const {classesName, selectedStart, selectedEnd, carType, selectedCarNumber} = this.state;
        if (classesName === 'Переговорная') {
            this.setState({
                selectedStart: 27,
                selectedEnd: 30,
                selectedSeats: [
                    '27',
                    '28',
                    '29',
                    '30',
                ],
            })
        } else if(classesName === 'Купе-сьют'){
            if(seat === 45 || seat === 47) {
                this.setState({
                    selectedStart: 45,
                    selectedEnd: 47,
                    selectedSeats: [
                        '45',
                        '47',
                    ],
                })
            } else if(seat === 49 || seat === 51) {
                this.setState({
                    selectedStart: 49,
                    selectedEnd: 51,
                    selectedSeats: [
                        '49',
                        '51',
                    ],
                })
            }
        } else {
            const childSeatsAll = [
                45,
                46,
                47,
                48,
                49,
                51,
                52,
            ];
            if ((selectedCarNumber === '10' ||
                    selectedCarNumber === '20'
                ) &&
                childSeatsAll.includes(seat)
            ) {
                const childSeats = [
                    45,
                    46,
                    47,
                    48,
                    51,
                    52,
                ];

                if (seat == 49) {
                    this.setState({
                        selectedStart: 49,
                        selectedEnd: 49,
                        selectedSeats: ['49'],
                    })
                }
                else if (childSeats.includes(seat)) {

                    if (selectedStart !== selectedEnd ||
                        !childSeats.includes(selectedStart) ||
                        !childSeats.includes(selectedEnd)
                    ) {
                        this.setState({
                            selectedStart: seat,
                            selectedEnd: seat,
                            selectedSeats: [seatSelect],
                        })
                    }
                    else {
                        let select = [];
                        if (selectedStart < seat) {
                            select = childSeats.filter(function (x) {
                                return (x >= selectedStart && x <= seat)
                            });
                            this.setState({
                                selectedStart: selectedStart,
                                selectedEnd: seat,
                                selectedSeats: select.map(String),
                            })
                        }
                        else {
                            select = childSeats.filter(function (x) {
                                return (x >= seat && x <= selectedStart)
                            });
                            this.setState({
                                selectedStart: seat,
                                selectedEnd: selectedEnd,
                                selectedSeats: select.map(String),
                            })
                        }
                    }
                }

            }
            else {
                if (additionalPlaceRequirements === 'WithPets' || this.state.additionalPlaceRequirements === 'WithPets') {
                    if (additionalPlaceRequirements !== 'WithPets' || this.state.additionalPlaceRequirements !== 'WithPets') {
                        this.setState({
                            selectedStart: seat,
                            selectedEnd: seat,
                            selectedSeats: [seatSelect],
                        })
                    }
                    else if (additionalPlaceRequirements === 'WithPets' && this.state.additionalPlaceRequirements === 'WithPets') {
                        let select = [];
                        if (seat < selectedStart) {
                            for (let i = seat;
                                 i <= selectedStart;
                                 i++
                            ) {
                                select.push(i.toString());
                            }
                            this.setState({
                                selectedStart: seat,
                                selectedEnd: selectedStart,
                                selectedSeats: select,
                            })
                        }
                        else {
                            for (let i = selectedStart;
                                 i <= seat;
                                 i++
                            ) {
                                select.push(i.toString());
                            }
                            this.setState({
                                selectedStart: selectedStart,
                                selectedEnd: seat,
                                selectedSeats: select,
                            })
                        }
                    }
                }
                else {
                    if (selectedCarNumber === '10' ||
                        selectedCarNumber === '20'
                    ) {
                        if (selectedStart !== selectedEnd ||
                            childSeatsAll.includes(selectedStart) ||
                            childSeatsAll.includes(selectedEnd)
                        ) {
                            this.setState({
                                selectedStart: seat,
                                selectedEnd: seat,
                                selectedSeats: [seatSelect],
                            })
                        } else {
                            let select = [];
                            if(selectedStart && (
                                ((seat >= 1 && seat <= 24) && (selectedStart >= 33 && selectedStart <= 44)) ||
                                ((seat >= 33 && seat <= 44) && (selectedStart >= 1 && selectedStart <= 24))
                            )) {
                                this.setState({
                                    selectedStart: seat,
                                    selectedEnd: seat,
                                    selectedSeats: [seatSelect],
                                })
                            } else if (((seat >= 1 && seat <= 24) && (selectedStart >= 1 && selectedStart <= 24)) || 
                                ((seat >= 33 && seat <= 44) && (selectedStart >= 33 && selectedStart <= 44))
                            ) {
                                if (seat < selectedStart) {
                                    for (let i = seat;
                                         i <= selectedStart;
                                         i++
                                    ) {
                                        select.push(i.toString());
                                    }
                                    this.setState({
                                        selectedStart: seat,
                                        selectedEnd: selectedStart,
                                        selectedSeats: select,
                                    })
                                }
                                else {
                                    for (let i = selectedStart;
                                         i <= seat;
                                         i++
                                    ) {
                                        select.push(i.toString());
                                    }
                                    this.setState({
                                        selectedStart: selectedStart,
                                        selectedEnd: seat,
                                        selectedSeats: select,
                                    })
                                }                                
                            }
                        }
                    } else {
                        if (selectedStart !== selectedEnd) {
                            this.setState({
                                selectedStart: seat,
                                selectedEnd: seat,
                                selectedSeats: [seatSelect],
                            })
                        } else {
                            let select = [];
                            if (seat < selectedStart) {
                                for (let i = seat;
                                     i <= selectedStart;
                                     i++
                                ) {
                                    select.push(i.toString());
                                }
                                this.setState({
                                    selectedStart: seat,
                                    selectedEnd: selectedStart,
                                    selectedSeats: select,
                                })
                            }
                            else {
                                for (let i = selectedStart;
                                     i <= seat;
                                     i++
                                ) {
                                    select.push(i.toString());
                                }
                                this.setState({
                                    selectedStart: selectedStart,
                                    selectedEnd: seat,
                                    selectedSeats: select,
                                })
                            }
                        }
                    }
                }
            }
        }
        this.setState({
            fee,
            additionalPlaceRequirements,
        });
    };

    handleAmount = (amount) => {
        this.setState({amount:amount})
    }

    handleBooking = () => {
        const forward = this.createFwdDate();
        if (this.props.booking.isSearchBack)
            this.props.paramSet('returnData', forward);
        else
            this.props.paramSet('forwardData', forward);
        this.props.history.push('/checkout');
    };

    handleSearch = () => {
        const {destinationFrom, destinationTo, dateTo} = this.props;
        const forward = this.createFwdDate();
        this.props.paramSet('forwardData', forward);
        this.props.paramSet('step', 2);
        this.props.getList({
            destinationFrom: destinationTo,
            destinationTo: destinationFrom,
            date: dateTo,
        });
        this.props.history.push('/search');
    };

    createFwdDate = () => {
        const data = {
            selectedStart: this.state.selectedStart,
            selectedEnd: this.state.selectedEnd,
            train: this.props.train,
            fee: this.state.fee,
            availableSeats: this.state.availableSeats,
            carType: this.state.carType,
            selectedSeats: this.state.selectedSeats,
            selectedCarNumber: this.state.selectedCarNumber,
            additionalPlaceRequirements: this.state.additionalPlaceRequirements,
            classesName: this.state.classesName,
            classCode: this.state.classCode,
        };
        return data;
    }

    getSeats = (groups) => {
        var availableSeats = {};
        groups.forEach(function (group) {
            group.places.forEach(function (seat) {
                availableSeats[seat.toString()] = [
                    group.amount,
                    group.amount_fee,
                    group.CarPlaceType,
                ];
            });
        });
        return availableSeats;
    };

    carSelect = (selectedCarNumber,
                 carType,
                 availableSeats,
                 classesName, classCode) => {
        this.setState({
            selectedCarNumber,
            carType,
            availableSeats,
            selectedSeats: [],
            classesName,
            classCode
        });
        setTimeout(() => {
            this.schemaRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }, 100);
    };

    handleGoBack = (event) => {
        event.preventDefault();
        window.history.back();
    }

    render() {
        const {train, booking} = this.props;
        const {selectedSeats, carType, availableSeats, selectedCarNumber, selectedStart, selectedEnd, classesName} = this.state;
        const carScheme = (carType !== null)
            ? (<Car
                carType={carType}
                availableSeats={availableSeats}
                seatSelect={this.seatSelect}
                selectedSeats={selectedSeats}
                handleAmount={this.handleAmount}
                classesName={classesName}
            />)
            : null;
        const passengers = (selectedSeats.length !== 0 ?
                (selectedStart === selectedEnd ? 'Выбрано одно место: ' + selectedStart
                        : 'Выбран диапазон мест: с ' + selectedStart + ' по ' + selectedEnd
                ) : null

        );

        const button = (selectedSeats.length !== 0 ?
                (<div className="btn-wrap text-center">
                        {!booking.isSearchBack || booking.step === 2 ? (
                            <button className="btn btn__main" onClick={this.handleBooking}>Перейти к вводу данных
                                пасажиров</button>
                        ) : (
                            <button className="btn btn__main" onClick={this.handleSearch}>Перейти к выбору поезда и мест
                                обратно</button>
                        )}
                    </div>
                ) : null
        );

        const {amount} = this.state


        return (<main>
                <Progress/>
                <div className="main train-view-wrapper">
                    <Helmet>
                        <title>Поезд: {train.destinationFromName} > {train.destinationToName}</title>
                    </Helmet>
                    <div className="inner-page buy-ticket-page hiddenPhonePage">
                        <div className="go-back-btn" onClick={
                            event => this.handleGoBack(event)}>Назад
                        </div>
                        <div className="form formmobile">
                            <div className="top">
                                <div className="head d-flex j-content-between align-items">
                                    <div>Поезд номер {train.displayTrainNumber}</div>
                                    <div className="title">
                                        {train.destinationFromName}
                                        <span className="icon-item">
                                   <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                </span>
                                        {train.destinationToName}
                                    </div>
                                </div>
                                <ul className="list-desc">
                                    <li className="list-desc__item">
                                        <span className="text">Отправление:</span>
                                        <span className="text text__red">
                                           <span className="time">{time.getTime(train.localDepartureDateTime)}</span>
                                           <span className="date">{time.getDate(train.localDepartureDateTime)}</span>
                                         </span>
                                    </li>
                                    <li className="list-desc__item">
                                        <span className="text">Прибытие:</span>
                                        <span className="text text__red">
                                           <span className="time">{time.getTime(train.localArrivalDateTime)}</span>
                                           <span className="date">{time.getDate(train.localArrivalDateTime)}</span>
                                         </span>
                                    </li>
                                    <li className="list-desc__item">
                                        <span className="text">В пути:</span>
                                        <span
                                            className="text text__red">{time.getTripDuration(train.tripDuration)}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="train-car-classes">
                                {Object.keys(train.serviceClasses)
                                    .map(key => {
                                        const serviceClass = train.serviceClasses[key];
                                        const freePlacesKeys = Object.keys(serviceClass.freePlaces);
                                        if (freePlacesKeys.length === 0) return null;

                                        return (<div className="train-car-class" key={key}>
                                                <div className="train-car-class-title" style={{'whiteSpace' : 'nowrap'}}>
                                                    {key} {serviceClass.name}
                                                </div>
                                                <div className="train-car-class-list">
                                                    <div className="train-car-list-head"></div>
                                                    {freePlacesKeys.map((carNum,
                                                                         index) => {
                                                        const car = serviceClass.freePlaces[carNum];
                                                        const groupArray = this.getSeats(car.group);
                                                        return (<div className="car-icon-wrapper"
                                                                     key={carNum + '_' + index}>
                                                                <label
                                                                    className={(selectedCarNumber === carNum ? 'active' : '') + ' car-icon-item'}
                                                                    onClick={() => this.carSelect(carNum, car.type, groupArray, serviceClass.name, key)}>
                                                                    <span className="car-icon-item-num">{carNum}</span>
                                                                    <span className="car-icon-item-text">мест</span>
                                                                    <span
                                                                        className="car-icon-item-seats">{Object.keys(groupArray).length}</span>
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>

                            <div ref={this.schemaRef} className="sheme-train">
                                {carScheme}
                            </div>
                            <div className='mobileCars'>
                                <div ref={this.seatsRef}>{passengers}</div>
                                <div className='mobileUpdate'>{amount}</div>
                            </div>
                            <div>{button}</div>

                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default withRouter(Train);