const documentOptions = [
  {value : 'RussianPassport',
    label: 'Паспорт РФ',
  },
  {value : 'RussianForeignPassport',
    label: 'Загранпаспорт РФ',
  },
  {value : 'ForeignPassport',
    label: 'Иностранный документ',
  },
  {value : 'BirthCertificate',
    label: 'Свидетельство о рождении',
  },
  {value : 'MilitaryCard',
    label: 'Военный билет',
  },
  {value : 'MilitaryOfficerCard',
    label: 'Удостоверение личности для военнослужащих',
  },
  {value : 'ReturnToCisCertificate',
    label: 'Свидетельство на возвращение',
  },
  {value : 'DiplomaticPassport',
    label: 'Дипломатический паспорт',
  },
  {value : 'ServicePassport',
    label: 'Служебный паспорт',
  },
  {value : 'SailorPassport',
    label: 'Паспорт моряка',
  },
  {value : 'StatelessPersonIdentityCard',
    label: 'Удостоверение личности лица без гражданства',
  },
  {value : 'ResidencePermit',
    label: 'Вид на жительство',
  },
  {value : 'RussianTemporaryIdentityCard',
    label: 'Временное удостоверение личности',
  },
  {value : 'UssrPassport',
    label: 'Паспорт СССР',
  },
  {value : 'MedicalBirthCertificate',
    label: 'Медицинское свидетельство о рождении (для детей не старше 1 мес.)',
  },
  {value : 'LostPassportCertificate',
    label: 'Справка об утере паспорта',
  },
  {value : 'PrisonReleaseCertificate',
    label: 'Справка об освобождении из мест лишения свободы',
  },
];

export default documentOptions;
