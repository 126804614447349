import React, {Fragment, PureComponent} from 'react';
import Tabs from './Tabs';
import Helmet from 'react-helmet';
import {dateFormatter, getFile} from '../Helpers';
import OrderItem from "../../containers/Cabinet/OrderItem";
import Reservation from "./Reservation";
import {ArchiveDelete, getReservationsArchiveDelete} from "../../actions/User";

class Archive extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            errorMessage: null,
            message: null,
            classs: null,
        };
        props.getReservationsArchive();
    }

    render() {
        const {cities, list,status} = this.props;
        console.log(list.length)
        return (<Fragment>
                <Helmet>
                    <title>Личный кабинет</title>
                </Helmet>
                <Tabs tab="archive"/>
                <div className="cabinet-page__content">
                    <div className="flight">{list.map((reservation, i) => (
                            <Reservation
                                flag={list.length === 0 ? false : true}
                                delete={(item) => this.props.getReservationsArchiveDelete(item)}
                                cities={cities}
                                status={status}
                                type={"archive"}
                                key={reservation.order_data.id + '_' + i}
                                reservation={reservation}
                                i={i}
                            />
                    ))}
                    </div>
                </div>
                {this.state.message &&
                <div className={this.state.classs + ' toaster active z-999'}>
                    {this.state.errorMessage}
                </div>
                }
            </Fragment>
        )
    }
}

export default Archive;

