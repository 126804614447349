import axios from 'axios';

export function dateFormatter(inputFormat,
                              withTime = false,
                              timeinbegin = true) {
    var d, a, fd;
    if (withTime) {
        a = inputFormat.split(' ');
        d = new Date(timeinbegin ? a[1] : a[0]);
        fd = [
            pad(d.getDate()),
            pad(d.getMonth() + 1),
            d.getFullYear(),
        ].join('.');
        return timeinbegin ? a[0] + ' ' + fd : fd + ' ' + a[1];
    } else {
        d = new Date(inputFormat);
        return [
            pad(d.getDate()),
            pad(d.getMonth() + 1),
            d.getFullYear(),
        ].join('.')
    }
}

function pad(s) {
    return (s < 10) ? '0' + s : s;
}

export function getFile(global_order_id) {
    return new Promise(function (resolve, reject) {
        axios({
            url: process.env.REACT_APP_API_URL + '/file/getFile',
            method: 'POST',
            responseType: 'blob', // important
            data: {global_order_id: global_order_id}
        }).then((response) => {
            const blob = new Blob([response.data])
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', global_order_id + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                // resolve('Билет временно не досутпен к скачиванию');
            // if ((blob.size / 1024) > 40) {
            //     const url = window.URL.createObjectURL(blob);
            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute('download', global_order_id + '.pdf'); //or any other extension
            //     document.body.appendChild(link);
            //     link.click();
            // } else {
            //     resolve('Билет временно не досутпен к скачиванию');
            // }
        }).catch(e => {
            console.log(e)
            throw new Error('Билет временно не досутпен к скачиванию')
        });
    })
}



export function getTariffName(tariff){
    const tarifs = {
        Full: 'Полный',
        Child: 'Детский (со скидкой)',
        Child17: 'Детский (со скидкой)',
        FreeChild: 'Бесплатный детский (для детей без мест)',
        AdditionalFreeChild: 'Безденежный детский ДОПП',
        Junior: 'Юниор',
        Senior: 'Сениор',
        BackwardWayInRoundTripWithDiscount: 'Обратный выезд для Туда/обратно со скидкой',
        BackwardWayInRoundTripWithDiscountInInterstateDirection: 'Обратный выезд для Туда/обратно со скидкой в межгосударственном сообщении',
        UniversalCard: 'Универсальная карта',
        RzhdBonusCardWithDiscount: 'Скидка по карте "РЖД Бонус"',
        Pupil: 'Школьный',
        Holiday: 'Праздничный',
        Wedding: 'Свадебный',
        Family: 'Семейный',
        Kupek: 'Купек',
        Single: 'Сингл',
        Tks: 'Не используется',
        ChildBackwardWayInRoundTripWithDiscount: 'Детский со скидкой туда-обратно',
        ChildBackwardWayInRoundTripWithDiscountInInterstateDirection: 'Детский со скидкой туда-обратно в межгосударственном сообщении',
        Birthday: 'День рождения',
        BirthdayAccompanying: 'Сопровождение именинника',
        BusinessTravelCard: 'Деловой проездной',
        InternationalAdult: 'Взрослый международный',
        InternationalChild: 'Детский международный',
        InternationalFreeChild: 'Младенческий международный (безденежный детский)',
        FullForMongolia: 'Полный тариф для Монголии',
        BackwardWayInRoundTripWithDiscountForUpperPlaces: 'Обратный выезд для туда/обратно со скидкой для верхних мест',
        ChildBackwardWayInRoundTripWithDiscountForUpperPlaces: 'Детский со скидкой туда-обратно для верхних мест',
        Vtt: 'Виртуальное транспортное требование',
        ChildVtt: 'Виртуальное транспортное требование (детский)',
        Fss: 'Талон ФСС',
        ChildFss: 'Талон ФСС (детский)',
        Uszn: 'Талон УСЗН',
        ChildUszn: 'Талон УСЗН (детский)',
        Promo: 'Промо-тариф',
        PartialBonus: 'Бонусный с частичной оплатой бонусами',
        FullBonus: 'Бонусный с полной оплатой бонусами',
        ChildPartialBonus: 'Детский бонусный с частичный оплатой бонусами',
        ChildFullBonus: 'Детский бонусный с полной оплатой бонусами',
        InternationalBonus100: 'Бонусный в международном сообщении с полной оплатой',
        InternationalBonus75: 'Бонусный в международном сообщении с оплатой 75%',
        InternationalBonus50: 'Бонусный в международном сообщении с оплатой 50%',
        InternationalBonus25: 'Бонусный в международном сообщении с оплатой 25%',
        DeputyIdentification: 'Депутат Государственной Думы',
        BabyWithoutPlace: 'Детский без места',
    }
    return tarifs[tariff];
}
