import React, {Component} from 'react';
import * as Types from './Types';

class Car extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            seat: 0,
            hover: false,
            desc: '',
        }
    }

    handleSelectSeat =
        seat => {
            if (this.props.availableSeats.hasOwnProperty(seat)) {
                const tmp = this.props.availableSeats[seat];
                const price = tmp[0];
                const fee = tmp[1];
                const additionalPlaceRequirements = tmp[2];
                this.props.seatSelect(seat, price, fee, additionalPlaceRequirements);
            }
        };

    handleHoverSeat = (seat) => {
        var amount;
        const carType = this.props.carType.replace('С', 'C');
        if (this.props.availableSeats.hasOwnProperty(seat)) {
            amount = 'Цена : от ' + this.props.availableSeats[seat][0] + ' руб.';
        } else {
            amount = 'Занятое место';
        }
        const tmp = this.props.availableSeats[seat];

        if (this.props.handleAmount) {
            return this.props.handleAmount(amount)
        }


        this.setState({
            amount: amount,
            seat: seat,
            desc: tmp !== undefined ? tmp[2] : '',
            hover: true,
            x: 0,
            y: 0,
        });
    };

    _onMouseMove(e) {
        this.setState({
            x: e.clientX,
            y: e.clientY,
        });
    }

    handleHoverEnd = () => {
        this.setState({
            hover: false,
        });
    };

    render() {
        const carType = this.props.carType.replace('С', 'C'); // Replace russian to english
        const Type = Types['T' + carType]; // Component name must start from letter
        const {hover, seat, amount, desc, x, y} = this.state,
            coupeFlag = this.props.classesName === 'Переговорная' || this.props.classesName === 'Купе-сьют',
            coupe = (coupeFlag ? 'Билеты продаются только при выкупе целого купе' : null);

        if (Type === undefined) return null; // Schema not included.030
        return (
            <div>

                <div onMouseMove={this._onMouseMove.bind(this)}>

                    <Type
                        availableSeats={this.props.availableSeats}
                        selectedSeats={this.props.selectedSeats}
                        handleClick={this.handleSelectSeat}
                        handleHover={this.handleHoverSeat}
                        handleEndHover={this.handleHoverEnd}
                    />
                    {hover &&
                    <div className="toltip" style={{
                        left: x,
                        top: (coupeFlag ? y - 75 : y - 57),
                    }}>
                        <div className="rel-div">
                            <div className="carrot"></div>
                            <div>Место : <b>{seat}</b></div>
                            <div>{amount}</div>
                            {coupeFlag && 'Цена указана за целое купе.'}
                        </div>
                    </div>
                    }
                    {coupe}
                </div>

            </div>
        );
    }
}

export default Car;
