export const getTime =
  msg => msg.split('T')[1].substring(0, 5);

export const getDate = (msg,
                        params) => {
  const date = new Date(msg);

  if (params === undefined) params = {
    weekday: 'short',
    month  : 'long',
    day    : 'numeric',
  };

  return date.toLocaleDateString('ru-RU', params);
};

export const dateToString =
  date => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
  }

export const getTripDuration =
  msg => {
    const hours = msg / 60;
    const minutes = (hours - Math.floor(hours)) * 60;

    return Math.floor(hours) + 'ч ' + Math.floor(minutes) + 'м';
  };