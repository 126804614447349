import * as types from './../constants/ActionTypesApp';

const initialState = {
    loader: false,
    first: true,
    abort: true,
    wait: false,
    message : "",
};

export default (state = initialState,
                action) => {
    let newState = {...state};

    switch (action.type) {

        case types.APP_LOADER_ACTIVE:
            newState.loader = action.payload.active
            newState.first = false
            return newState

        case types.APP_LOADER_WAIT_TICKET:
            newState.wait = action.payload.active
            return newState

        case types.APP_LOADER_ABORT:
            newState.abort = action.payload.active
            newState.first = false
            return newState

        case types.APP_LOADER_ABORT_ERROR:
            newState.message = action?.payload?.error?.Message
            return newState

        default:
            return newState;
    }
};
