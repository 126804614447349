import React, {PureComponent, Fragment} from 'react';
import Tabs from './Tabs';
import Helmet from 'react-helmet';
import Reservation from "./Reservation";


class Tickets extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            errorMessage: null,
            message: null,
            classs: null,

        };
        props.getReservations()
    }

    render() {
        const {cities, list, status, wait} = this.props;

        return (<Fragment>
                <Helmet>
                    <title>Личный кабинет</title>
                </Helmet>
                <Tabs tab="tickets"/>
                <div className={wait ? 'cabinet-page__content await' : 'cabinet-page__content'} >
                    <div className="flight">{list.map((reservation, i) => (
                        <Reservation
                            flag={false}
                            cities={cities}
                            status={status}
                            key={reservation.order_data.id + '_' + i}
                            reservation={reservation}
                            i={i}
                        />
                    ))}
                    </div>
                </div>
                {this.state.message &&
                <div className={this.state.classs + ' toaster active z-999'}>
                    {this.state.errorMessage}
                </div>
                }
            </Fragment>
        )
    }
}

export default Tickets;
