import React from 'react';
import {Helmet} from 'react-helmet';
import Navigation from '../../containers/Navigation/Cabinet';

function PublicOffer() {

  return (<React.Fragment>
      <Helmet>
        <title>Публичная оферта</title>
      </Helmet>
      <Navigation/>
      <div className="main">
        <div className="inner-page conditions-page">
          <h3 className="conditions__title">
            Договор Оферты
          </h3>
          <p>
            Использование сайта возможно только на условиях Оферты. Если Вы не принимаете в полном объеме условия
            Оферты, использование сайта Исполнителя не допускается. Изложенный ниже текст Оферты является, адресованным
            физическим лицам, официальным публичным предложением заключить договор в соответствии с п. 2 ст. 437 ГК РФ.
            Договор считается заключенным и приобретает силу с момента совершения Вами действий, предусмотренных в
            Оферте и означающих Ваше безоговорочное присоединение ко всем условиям Оферты без каких-либо изъятий или
            ограничений.
          </p>
          <h3 className="conditions__title">
            ПУБЛИЧНАЯ ОФЕРТА <span className="public-offer__tlc">договора на оформление проездных документов</span>
          </h3>
          <p>
            Общество с ограниченной ответственностью «Общество с ограниченной ответственностью «ЭПСИЛОН»», именуемое в
            дальнейшем «Исполнитель», заключает настоящий Договор с любым лицом, именуемым в дальнейшем «Заказчик».
            Данный Договор является договором оказания услуг, заключаемым путем публичной оферты, и регламентирует
            порядок действий и обязательства, возникающие в связи с этим между Исполнителем и Заказчиком.
          </p>

          <h4 className="condition__topic">1. Термины и определения, используемые в настоящем договоре </h4>

          <p>1.1. ОПЕРАТОР – организация, оказывающая услуги транспортных пассажирских перевозок и иные услуги. </p>
          <p>1.2. Заказчик - пользователь сайта spsn.express</p>
          <p>1.3. ПАССАЖИР – пользователь услуг ОПЕРАТОРА.</p>
          <p>1.4 Сайт Исполнителя – сайт, расположенный по адресу spsn.express и позволяющий Заказчику формировать заказ
            для Исполнителя.</p>
          <p>1.5. Платежная Система – юридическое лицо, Агент Исполнителя по приему платежей у Заказчика за услуги
            Исполнителя.</p>
          <p>1.6. Электронный билет – запись в системе учета ОПЕРАТОРА, содержащая идентификационные данные Заказчика,
            ПАССАЖИРА, заказа (информация о маршруте, местах и пр.) и статус заказа, подтверждающий права ПАССАЖИРА
            воспользоваться услугой ОПЕРАТОРА и обязанности ОПЕРАТОРА оказать услугу ПАССАЖИРУ.</p>
          <p>1.7. БРОНИРОВАНИЕ электронного билета – изменение статуса заказа в системе учета ОПЕРАТОРА, на статус,
            означающий, что заказ зарезервирован на определенное время и, в течение этого времени может быть продан
            только Заказчику, совершившему данный заказ.</p>
          <p>1.8. ПРОДАЖА/ПОКУПКА проездного документа - изменение статуса заказа в системе учета ОПЕРАТОРА, на статус,
            означающий, получение ПАССАЖИРОМ права воспользоваться услугой ОПЕРАТОРА и обязанности ОПЕРАТОРОМ оказать
            услуги ПАССАЖИРУ. Данное право установлено и предоставляется ОПЕРАТОРОМ. ОПЕРАТОР самостоятельно несет
            ответственность и гарантии предоставления своих услуг ПАССАЖИРАМ. Право воспользоваться услугой ОПЕРАТОРА
            возникает у третьих лиц (ПАССАЖИРОВ), если Заказчиком при оформлении заказа были указаны необходимые
            сведения о ПАССАЖИРАХ.</p>

          <h4 className="condition__topic">2. Предмет договора</h4>

          <p>2.1. Исполнитель на возмездной основе оказывает Заказчику услуги по предоставлению содержащейся в системе
            Оператора информации, бронированию услуг Оператора в соответствии с заданными Заказчиком параметрами (дата,
            время, маршрут и иные условия перевозки), доставке проездных документов на email, оформлению процедуры
            возврата электронных проездных документов по требованию Заказчика (Пассажира). Заказчик дает Исполнителю
            заказ, используя Сайт Исполнителя. Услуги Исполнителя могут предоставляться в режиме реального времени.
            Заказчик производит оплату за проездные документы непосредственно Оператору. Оплата услуг Исполнителя
            автоматически списывается с карточного счета Заказчика только после оформления проездных документов.</p>

          <h4 className="condition__topic">3. Права и обязанности сторон</h4>
          <h6 className="condition__subtopic">3.1 Заказчик ВПРАВЕ</h6>

          <p>3.1.1. Самостоятельно принимать решение об использовании сайта Исполнителя. При этом Заказчик признает, что
            в случае использования сайта он в полной мере и безоговорочно принимает условия данной оферты в
            независимости от того, каким способом был совершен заказ.</p>
          <p>3.1.2. Отказаться от услуг ОПЕРАТОРА после оплаты проездных документов на условиях ОПЕРАТОРА.</p>
          <p>3.1.3. Самостоятельно из открытых источников наводить справки о рыночной стоимости проезда и сопутствующих
            услуг. При этом Исполнитель несет ответственность только за ту информацию, которая опубликована на сайте
            spsn.express</p>
          <p>3.1.4. Выбирать, из предложенных на сайте spsn.express, способ оплаты проездных документов. При этом
            Заказчик обязуется самостоятельно ознакомиться с правилами, которые опубликованы на сайте spsn.express</p>
          <p>3.1.5. Оформлять заказ на БРОНИРОВАНИЕ проездных документов с использованием Интернета, звонка на телефон и
            прочими способами, указанными на сайте spsn.express При этом Исполнитель имеет право без объяснения причин
            отказать Заказчику в оформлении заказа.</p>
          <p>3.1.6. Самостоятельно проверить данные заказа перед его оформлением и БРОНИРОВАНИЕМ проездного документа.
            Заказчик несет полную ответственность за достоверность и правомерность употребления данных, использованных
            им при оформлении заказа.</p>
          <p>3.1.7. Для решения сложных ситуаций обратиться в службу поддержки Исполнителя, пользуясь контактами,
            указанными в разделе «Контакты» сайта <a
              href="https://spsn.express/contacts">https://spsn.express/contacts</a></p>
          <p>3.1.8. Направлять отзывы о работе САЙТА Исполнителя, по контактам, указанным в разделе «Контакты» сайта <a
            href="https://spsn.express/contacts">https://spsn.express/contacts</a></p>
          <p>3.1.9. Заказчик вправе возвратить неиспользованные проездные документы в соответствии с правилами
            ОПЕРАТОРА, установленными для возврата проездных документов и правилами перевозчика. За операцию по
            оформлению возврата платежей за неиспользованные поездки Исполнитель может взимать плату за каждый проездной
            документ в заказе.</p>

          <h6 className="condition__subtopic">3.2 Исполнитель ВПРАВЕ</h6>

          <p>3.2.1. Требовать от Заказчика придерживаться соблюдения всех процедур заказа и БРОНИРОВАНИЯ проездных
            документов строго по правилам, изложенным на сайте spsn.express и в данной Оферте. Какие бы действия не
            совершал Заказчик, Исполнитель несет ответственность за надлежащее исполнение только действий и процедур,
            совершенных с полным соблюдением этих правил.</p>
          <p>3.2.2. Требовать от Заказчика полного согласия с условиями Оферты при использовании Заказчиком напрямую или
            через третьих лиц данных с САЙТА Исполнителя. Без согласия с условиями оферты отказать Заказчику в
            выполнении заказа.</p>
          <p>3.2.3. Удерживать с Заказчика или требовать оплаты полной стоимости услуги за добровольный или вынужденный
            возврат, аннулирование проездного документа через Исполнителя. В этом случае оплата за услуги Исполнителя не
            подлежит возврату. При этом Заказчик признает действия Исполнителя полностью правомерными, не имеет
            претензий.</p>
          <p>3.2.6. Отключать и включать сайт, производить профилактические работы на сервере и другом оборудовании,
            задействованном в оказании услуг в удобное время, информируя об этом Заказчика на сайте spsn.express</p>
          <p>3.2.7. Устанавливать и изменять стоимость услуг при оформлении проездных документов, а так же концепцию
            ценообразования на свои услуги в любое время.</p>
          <p>3.2.8. Использовать данные Заказчика/ПАССАЖИРОВ, предоставленные в процессе заказа, в маркетинговых целях.
            В том числе проводить маркетинговые исследования, рассылки предложений, информировать Заказчика об акциях.
            Заказчик признает, что все данные, полученные путем анализа, данных Заказчика/ПАССАЖИРОВ и его покупок
            являются полной собственностью Исполнителя. Заказчик гарантирует и несет ответственность, что данные,
            указанные при заказе являются добровольно предоставленными им и третьими лицами, и третьи лица ознакомлены с
            данной Офертой.</p>
          <p>3.2.9. Предоставлять Заказчику информацию об ОПЕРАТОРАХ и способах оплаты в объеме, полученном от ОПЕРАТОРА
            и платежных Агентов, участвующими в процессе ОПЛАТЫ ЭЛЕКТРОННЫХ БИЛЕТОВ.</p>
          <p>3.2.10. Указывать стоимость заказа в виде обобщающей суммы на услуги ОПЕРАТОРА, включающей стоимость услуг
            Исполнителя. Заказчик признает, что согласен с данной формулировкой стоимости заказа и ориентируется при
            заказе на конечную стоимость всех услуг.</p>
          <p>3.2.11. Консультировать Заказчика по ходу оформления заказа, БРОНИРОВАНИЯ, ПОКУПКИ ЭЛЕКТРОННЫХ БИЛЕТОВ. В
            том числе самостоятельно перезванивать Заказчику на указанный при заказе номер мобильного телефона. Заказчик
            вправе отказаться от консультаций Исполнителя, если сможет аргументировать свой отказ.</p>
          <p>3.2.12. Требовать от Заказчика полной оплаты услуг Исполнителя в соответствии со стоимостью произведенного
            заказа.</p>
          <p>3.2.13. Перенаправить Заказчика в службу поддержки или на интернет-ресурс ОПЕРАТОРА или платежной системы,
            для более подробного описания услуг.</p>

          <h6 className="condition__subtopic">3.3. Заказчик ОБЯЗАН</h6>

          <p>3.3.1. Согласиться с условиями данной Оферты. В случае несогласия заказ не принимается. Если Заказчик
            сделал заказ или оплатил проездной документ, то Исполнитель автоматически считает принятие всех условий
            Оферты Заказчиком.</p>
          <p>3.3.2. Подробно ознакомиться со всеми правилами использования сайта spsn.express</p>
          <p>3.3.3. Не приступать к оформлению заказа, предварительно не ознакомившись с правилами САЙТА
            Исполнителя. <br/>Если Заказчик приступил к оформлению заказа, то Исполнитель вправе считать, что Заказчик
            полностью ознакомлен и согласен с правилами. </p>
          <p>3.3.4. Ознакомиться и принять полную стоимость заказа в виде общей суммы перед подтверждением заказа. <br/>БРОНИРОВАНИЕ
            производится после нажатия кнопки «Оформить» в интерфейсе САЙТА Исполнителя, либо после устного
            подтверждения Заказчика, либо после события, установленного на Сайте Исполнителя соответствии с размещенными
            правилами.</p>
          <p>3.3.5. Оплатить в полном объеме самостоятельно или через третьих лиц стоимость услуг Исполнителя и
            стоимость проездных документов ОПЕРАТОРА с помощью способов платежа, представленных на сайте <a
              href="https://spsn.express/howtopay">https://spsn.express/howtopay</a></p>
          <p>3.3.6. Удостовериться в том, что проездной документ оформлен правильно.</p>

          <h6 className="condition__subtopic">3.4. Исполнитель ОБЯЗАН</h6>

          <p>3.4.1. Предоставить Заказчику доступ к САЙТУ Исполнителя по формированию заказа и выполнять заказ строго на
            условиях настоящей Оферты. На других условиях заказ не выполняются.</p>
          <p>3.4.2. Самостоятельно и за свой счет совершенствовать работу САЙТА Исполнителя и проводить
            профилактику.</p>
          <p>3.4.3. Предоставить Заказчику необходимую информацию и инструкции для оформления заказа. Исчерпывающей
            информацией считается та информация, которую Исполнитель публикует на сайте spsn.express</p>
          <p>3.4.4. Предоставить Заказчику информацию об ОПЕРАТОРАХ и способах оплаты услуг. Исчерпывающей информацией
            считается информация, предоставленная ОПЕРАТОРАМИ.</p>
          <p>3.4.5. Предоставить Заказчику возможность выбора между услугами с помощью интерфейса САЙТА Исполнителя и
            телефонного обращения в случае, если сайт не отключен и работоспособен на момент запроса Заказчика.</p>
          <p>3.4.6. Принять оплату от Заказчика (в том числе через третьих лиц) после надлежащего оформления заказа и
            успешного БРОНИРОВАНИЯ, получить подтверждение об оплате заказа on-line от платежных агентов (третьих лиц),
            выступающих в роли агентов по приему платежей за услуги в случае их доступности и работоспособности на
            момент запроса об оплате. Без получения подтверждения от платежной системы оплаты услуг заказ остается не
            оплаченным. Статус проездного документа меняется, и он считается купленным только в случае получения такого
            подтверждения Исполнителем.</p>

          <h4 className="condition__topic">4. Оформление заказа</h4>

          <p style={{marginBottom: '0px'}}>4.1. Заказ считается оформленным при совершении Заказчиком (напрямую или
            опосредованно) следующих действий:</p>
          <ul className="public-offer__list">
            <li>Выбор маршрута поездки на сайте spsn.express;</li>
            <li>Подтверждение выбранного маршрута;</li>
            <li>Предоставление в полном объеме и корректно всех необходимых данных о Заказчик/ПАССАЖИРАХ. Паспортных
              данных и прочих данных, подтверждающих личность. Номер мобильного телефона указывается в международном
              формате (Пример: +7921*******). В случае неверного указания номера телефона Заказчик лишается возможности
              получать уведомления Исполнителя о статусе заказа;
            </li>
            <li>Согласие с данной Офертой и нажатием соответствующей кнопки «ОФОРМИТЬ» в интерфейсе КОМПЛЕКСА САЙТА
              Исполнителя.
            </li>
          </ul>

          <h6 className="condition__subtopic">4.2. ПОДТВЕРЖДЕНИЕ ЗАКАЗА</h6>

          <p style={{marginBottom: '0px'}}>4.2.1. Заказ считается подтвержденным при наступлении следующих событий:</p>
          <ul className="public-offer__list">
            <li>успешное оформление заказа Заказчиком;</li>
            <li>прохождение успешно процедуры проверки данных ПАССАЖИРОВ и Заказчика, данных заказа;</li>
            <li>при необходимости обращения Исполнителя к ОПЕРАТОРУ для дополнительной проверки данных заказа и
              получения от него положительного ответа.
            </li>
          </ul>

          <h6 className="condition__subtopic">4.3. БРОНИРОВАНИЕ </h6>

          <p style={{marginBottom: '0px'}}>4.3.1. Место в поезде считается забронированным при совершении следующих
            событий:</p>
          <ul className="public-offer__list">
            <li>Успешное подтверждение заказа;</li>
            <li>Отправки Исполнителем запроса на БРОНИРОВАНИЕ в систему учета ОПЕРАТОРА, содержащего необходимые
              сведения о заказе;
            </li>
            <li>Успешное изменение статуса заказа ОПЕРАТОРОМ в учетной системе ОПЕРАТОРА, на статус, означающий, что
              заказ зарезервирован на определенное время и, в течение этого времени может быть продан только Заказчику,
              совершившему данный заказ;
            </li>
            <li>Получение Исполнителем положительного ответа (подтверждения брони) от ОПЕРАТОРА по данному заказу.</li>
          </ul>

          <h6 className="condition__subtopic">4.5. ПРОДАЖА/ПОКУПКА/ВОЗВРАТ</h6>

          <p>4.5.1. Проездной документ считается оформленным на Заказчика при направлении на электронный адрес Заказчика
            письма с сообщением об оформлении билета. <br/> Исполнитель имеет право взимать с Заказчика стоимость услуг
            по бронированию и оформлению электронных проездных документов, а также за их возврат. Наличие и размер
            стоимости услуг зависит от условий, предоставляемых перевозчиком или иным лицом, оказывающим услуги.
            Стоимость услуг Исполнителя включается в стоимость заказа, и в случае добровольного или вынужденного
            возврата, аннулирования проездных документов или отказа Заказчика от услуг третьих лиц, не подлежит
            возврату. Стоимость услуг Исполнителя за операцию возврата по заказанной услуге третьих лиц зависит от
            стоимости проездного документа и услуг. </p>
          <p>4.5.2. Для проведения процедуры возврата проездного документа необходимо:</p>
          <p>Если проездной документ оформлен как электронный билет:</p>
          <p>Заказчик составляет письменное заявление на возврат проездных документов/ДЕНЕЖНЫХ СРЕДСТВ и отправляет на
            e-mail: <a href="mailto: info@spsn.express">info@spsn.express</a> <br/> Исполнитель проводит действия по
            оформлению возврата в системе ОПЕРАТОРА, связывается с Заказчиком по e-mail или телефону для подтверждения
            проведения операции возврата, озвучивая денежную сумму, подлежащую к возврату, с учетом штрафных санкций
            ОПЕРАТОРА и стоимость услуг Исполнителя за операцию возврата.</p>
          <p>Если проездной документ оформлен на фирменном бланке ОПЕРАТОРА:</p>
          <p>Заказчик самостоятельно обращается в кассы ОПЕРАТОРА и сдает проездной документ согласно правилам
            Оператора.</p>
          <p>И для электронного билета и для проездного документа на фирменном бланке ОПЕРАТОРА:</p>
          <p>ВОЗВРАТ денежных средств за проездной документ, оплаченный за наличный расчет, производится непосредственно
            в день совершения данной операции и личной явки Заказчика в офис Исполнителя, указанный на сайте <a
              href="https://spsn.express/contacts">https://spsn.express/contacts</a></p>
          <p>ВОЗВРАТ денежных средств за проездной документ, оплаченный банковской картой, осуществляется до 30 рабочих
            дней;</p>
          <p>ВОЗВРАТ денежных средств за проездной документ, оплаченный иными способами, до 5 рабочих дней.</p>

          <h4 className="condition__topic">5. ОТВЕТСТВЕННОСТЬ. РАЗРЕШЕНИЕ СПОРОВ</h4>

          <p>5.1. Исполнитель не несет ответственности за не надлежащее функционирование сотовой сети и доступность
            отдельных сегментов сети Интернет в ходе оформления заказа.</p>
          <p>5.2. Заказчик принимает на себя полную ответственность и риски за достоверность вводимой контактной
            информации при оформлении заказа. </p>
          <p>5.3. Исполнитель не несет ответственности за любые убытки и моральный вред, понесенные Заказчиком в
            результате ошибочного понимания или непонимания Заказчиком информации о порядке получения и использования
            проездных документов. </p>
          <p>5.4. Стороны будут прилагать все усилия с целью достижения согласия по спорным вопросам путем переговоров.
            При невозможности достижения согласия на переговорах, возникшие споры подлежат рассмотрению в Арбитражном
            суде г. Москвы. </p>
          <p>5.5. По всем остальным вопросам, не предусмотренным в настоящем Договоре, Стороны руководствуются
            действующим законодательством Российской Федерации. Все возможные споры, вытекающие из положений Регламента,
            будут разрешаться в судах Российской Федерации в соответствии с действующим законодательством Российской
            Федерации. </p>
          <p style={{marginBottom: '0px'}}>5.6 Исполнитель не несет ответственность за негативные последствия и убытки,
            возникшие не по вине Исполнителя, в том числе:</p>
          <ul className="public-offer__list">
            <li>в случае невозможности выполнения обязательств, вследствие недостоверности или недостаточности сведений,
              предоставленных Заказчиком, или нарушения Заказчиком установленных правил оформления и оплаты заказа;
            </li>
            <li>за действия Оператора (изменение, отмена, перенос, задержка отправления авиарейсов, поездов, изменение
              стоимости билетов, изъятие тарифа из продажи, аннуляция неоплаченных или оплаченных бронирований), за
              сохранность, потерю или повреждение багажа, груза, ценностей и документов пассажиров в течение всего срока
              их поездки. В этих случаях ответственность перед Заказчиком несут и железнодорожные перевозчики в
              соответствии с международными правилами и действующим законодательством РФ;
            </li>
            <li>за действия таможенных и иммиграционных властей;</li>
            <li>вследствие ограничения права Заказчика на выезд из РФ и въезд в РФ компетентными органами;</li>
            <li>за последствия нарушения Заказчиком таможенных и пограничных формальностей, правил проезда и провоза
              багажа, а также нарушения особых правил поведения в стране временного пребывания;
            </li>
            <li>за отсутствие у Заказчика проездных документов, выданных ему Исполнителем/ОПЕРАТОРОМ;</li>
            <li>за неявку или опоздание Заказчика к месту отправления поезда;</li>
            <li>за несоблюдение Заказчиком установленных Оператором правил поведения в вагоне поезда;</li>
            <li>за отсутствие у Заказчика оформленных заграничных паспортов к моменту начала поездки, соответствующих
              документов, регулирующих вопросы вывоза детей;
            </li>
            <li>за отстранение от проезда в случае, если предполагаемый срок пребывания в стране назначения превышает
              срок действия документа, удостоверяющего личность Заказчика.
            </li>
          </ul>

          <h4 className="condition__topic">6. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</h4>

          <p>6.1. Стороны освобождаются от ответственности за полное или частичное неисполнение своих обязательств по
            Договору, если такое неисполнение явилось следствием обстоятельств непреодолимой силы, то есть чрезвычайных
            и непредотвратимых при данных условиях обстоятельств.</p>
          <p>6.2. К обстоятельствам непреодолимой силы, в частности, относятся: стихийные бедствия; военные действия;
            общегосударственный кризис; забастовки в отрасли или регионе; действия и решения государственных органов
            власти; сбои, возникающие в телекоммуникационных и энергетических сетях; действие вредоносных программ, а
            также недобросовестные действия третьих лиц, выразившиеся в действиях, направленных на несанкционированный
            доступ и/или выведение из строя программного и/или аппаратного комплекса каждой из Сторон.</p>

          <h4 className="condition__topic">7. ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА ОФЕРТЫ</h4>

          <p>7.1. Настоящая Оферта вступает в силу с момента начала процесса оформления заказа Заказчиком и действует
            бессрочно.</p>
          <p>7.2. Заказчик вправе в любой момент отказаться от услуг Исполнителя и/или ОПЕРАТОРА. Отказ Заказчика влечет
            за собой прекращение перед ним всех обязанностей Исполнителя с момента такого отказа. </p>
          <p>7.5. Исполнитель вправе изменять условия настоящего Договора и его Приложений, вводить новые Приложения к
            настоящему Договору без предварительного уведомления. Заказчик, зная о возможности таких изменений, согласен
            с тем, что они будут производиться. Если Заказчик продолжает пользоваться услугами Исполнителя после таких
            изменений, это означает его согласие с ними.</p>

          <h4 className="condition__topic">8. КОНФИДЕНЦИАЛЬНОСТЬ ДАННЫХ</h4>

          <p>8.1. Заказчику гарантируется конфиденциальность данных, предоставленных им с целью оформления заказа.</p>
          <p>8.2. Заказчик несет ответственность за конфиденциальность своего регистрационного имени (логина) и пароля
            (если таковой предусмотрен), а также за все действия, произведенные под данным именем (логином) и паролем.
            Исполнитель не несет ответственности и не возмещает убытки, возникшие по причине несанкционированного
            использования третьими лицами идентификационных данных Заказчика.</p>

          <h4 className="condition__topic">9. РЕКВИЗИТЫ Исполнителя</h4>

          <p>Общество с ограниченной ответственностью «Эпсилон»</p>
          <p>119019, ГОРОД МОСКВА, УЛИЦА АРБАТ, д.6/2, Э 4 ПОМ I К 1 ОФ 107</p>
          <p>ОГРН 1187746916477</p>
          <p>ИНН 7704464273</p>
          <p>КПП 770401001</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PublicOffer;