import {connect} from 'react-redux';
import {changeER, getDataArchive, updateBlank, waitTicket} from '../../actions/User';
import OrderItem from "../../components/Cabinet/OrderItem";
import {refund, refundAmount, refundOneAction} from "../../actions/Bag";

function mapStateToProps(state) {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, {
    updateBlank, refundAmount, changeER, refundOneAction, refund, getDataArchive, waitTicket
})(OrderItem);
