import React, {useState} from 'react';
import * as time from './../../utility/time';

const Train = props => {
    const [description1, setDescription1] = useState(false)
    const [description2, setDescription2] = useState(false)

    const onMouseEnter = () => {
        setDescription1(true)
    }

    const onMouseLeave = () => {
        setDescription1(false)
    }

    const onMouseEnter_2 = () => {
        setDescription2(true)
    }

    const onMouseLeave_2 = () => {
        setDescription2(false)
    }

    return (<section className="search-filter pointer" onClick={props.handleClick}>
            <div className="search-filter__item">
                <div className="row">
                    <div className="col-md-8 d-flex">
                        <div
                            className={props.speedee === props.tripDuration ? 'very very__speed' : (props.cheepee === props.minPrice
                                ? 'very very__cheap' : 'd-none')}>
                            {props.speedee === props.tripDuration ?
                                <div className="text">Самый быстрый</div> : (props.cheepee === props.minPrice ? <div
                                    className="text">Самый дешевый</div> : null)}
                        </div>
                        <div className="col-left">
                            <div className="flight">
                                <div className="flight__number">
                                    <span className="text">{props.displayTrainNumber} </span>
                                    <span className="icon-star"><i className="fa fa-star" aria-hidden="true"></i></span>
                                </div>
                                <div className="flight__text">
                                    {description1 &&
                                    <div onMouseLeave={onMouseLeave} className='windowDescription'>ЭР - Доступна
                                        электронная регистрация. Для
                                        посадки в вагон необходимо показать проводнику оригинал удостоверения личности и
                                        купон, распечатав его или сохранив на мобильном устройстве.
                                    </div>}
                                    {description2 &&
                                    <div onMouseLeave={onMouseLeave_2} className='windowDescription'>ДЦ -Динамическое
                                        ценообразование. Программа, формирующая цену билета. Главный принцип - чем выше
                                        спрос и меньше свободных мест, тем выше цена билета.
                                    </div>}
                                    <span onMouseEnter={onMouseEnter} className="desc">эр</span>
                                    <span onMouseEnter={onMouseEnter_2} className="desc">дц</span>
                                </div>
                                {props.carriers &&
                                <div className='carrier__list'>Тип {props.description}</div>
                                }
                                {props.carriers &&
                                <ul className='carrier__list'>
                                    <li>Перевозчик</li>
                                    {props.carriers.map((item) => {
                                        return (
                                            <li>{item}</li>
                                        )
                                    })}
                                </ul>
                                }

                                {/* <ul className="flight-list">
               <li className="flight-list__item">
               <span className="icon-item">
               <i className="fa fa-map-marker" aria-hidden="true"></i>
               </span>
               <span className="text">Маршрут</span>
               </li>
               <li className="flight-list__item">
               <span className="icon-item">
               <i className="fa fa-map-marker" aria-hidden="true"></i>
               </span>
               <span className="text">Годовой график</span>
               </li>
               <li className="flight-list__item">
               <span className="icon-item">
               <i className="fa fa-map-marker" aria-hidden="true"></i>
               </span>
               <span className="text">Рейтинг рейса</span>
               </li>
               <li className="flight-list__item">
               <span className="icon-item">
               <i className="fa fa-map-marker" aria-hidden="true"></i>
               </span>
               <span className="text">Тарифы</span>
               </li>
               </ul> */}
                            </div>
                        </div>
                        <div className="col-center">
                            <div className="location">
                                <div className="top-box d-flex j-content-between">
                                    <div className="time-date">
                                        <div className="time">{time.getTime(props.localDepartureDateTime)}</div>
                                        <div className="date">{time.getDate(props.localDepartureDateTime)}</div>
                                    </div>
                                    <div className="flight-time">
                                        {time.getTripDuration(props.tripDuration)} в пути
                                    </div>
                                    <div className="time-date">
                                        <div className="time">{time.getTime(props.localArrivalDateTime)}</div>
                                        <div className="date">{time.getDate(props.localArrivalDateTime)}</div>
                                    </div>
                                </div>
                                <div className="bottom-box d-flex align-items-center j-content-between">
                                    <div className="start-location">
                                        <div className="station">
                                            {props.destinationFrom}
                                            <span className="icon-item">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                                        </div>
                                        <div className="station__location">
                                            {props.initialStationName}
                                        </div>
                                    </div>
                                    <div className="finish-location">
                                        <div className="station">
                    <span className="icon-item">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                                            {props.destinationTo}
                                        </div>
                                        <div className="station__location">
                                            {props.finalStationName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info-list">
                            <div className="info-list__item d-flex j-content-between">
                                <div className="col-item">
                                    <span className="text">Класс</span>
                                </div>
                                <div className="col-item">
                                    <span className="text">Количество мест</span>
                                </div>
                                <div className="col-item col-price">
                                    <span className="text">Стоимость</span>
                                </div>
                            </div>
                            {Object.keys(props.serviceClasses)
                                .map(key => {
                                    const serviceInfo = props.serviceClasses[key];

                                    if (serviceInfo.placeQuantity === 0) return null;

                                    return (<div className="info-list__item d-flex j-content-between" key={key}>
                                            <div className="col-item">
                                                <span className="text">{serviceInfo.name}</span>
                                                <span className="sid">{/*serviceInfo.typeName.toLowerCase()*/}</span>
                                            </div>
                                            <div className="col-item">
                                                <span className="text">{serviceInfo.placeQuantity}</span>
                                            </div>
                                            <div className="col-item col-price">
                                                <span className="text">от {serviceInfo.minPrice} ₽</span>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Train