import React, {Component} from 'react';
import DatePicker from 'react-date-picker';
import {dateToString} from '../../utility/time';
import Select from 'react-select';
import SelectSaved from './../../containers/Passengers/SelectSaved';
import {getData} from '../../actions/User';
import nationalityOptions from './_nationalityOptions';
import documentOptions from './_documentOptions';
import genderOptions from './_genderOptions';
import InputMask from 'react-input-mask';
import {connect} from 'react-redux';
import Tariff from '../Tariff';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            phone:'',
            lastName:'',
            firstName:'',
            middleName:'',
            documentType:'',
            nationality:'',
            gender:'',
            documentNumber:'',
            birthdate:'',
            rzhdBonus:'',
            universalRzhdCard:'',
            businessTravel:'',
            validationError    : null,
            error: null,
            tariffUpdate: 'update',
        };
        this.props.fieldUpdate('tariff', 'Adult');
        this.props.fieldUpdate('rzhdBonus', '');
        this.props.fieldUpdate('universalRzhdCard', '');
        this.props.fieldUpdate('businessTravel', '');
        this.props.fieldUpdate('isSaved', true);
        this.props.getData();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.passenger.error !== this.props.passenger.error)
            this.setState({error: this.props.passenger.error});
    }

    handleInput =        e => {
        var name = e.target.name

            var value = e.target.value
            var firstChar = value.charAt(0)
            if (name === 'lastName' || name === 'firstName' || name === 'middleName') {
                value = firstChar === '-' || firstChar === '.' ? value.slice(1) : value
                value = value.replace(/[^A-z А-я . -]/g, '')
            }

            this.props.fieldUpdate(name, value);
            this.setState({[name]: value});
        }

    validation = () => {
        const {firstName,lastName,middleName,email,phone,documentType,nationality,gender,documentNumber,birthdate} = this.state
        let validationError = null;
        if (firstName.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (lastName.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (middleName.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (email.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (phone.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (this.props.passenger?.birthdate) {
            const date = this.props.passenger?.birthdate
            const dateNow = new Date().getFullYear()
            if(Number(date.slice(0,4)) > Number(dateNow)) validationError = 'Некорректно введена дата рождения';
        }

        if (!this.props.passenger?.birthdate) validationError = 'Необходимо заполнить поле даты.';

        if (documentType.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (gender.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (nationality.length < 1) validationError = 'Необходимо заполнить обязательные поля';

        if (documentNumber.length < 1) validationError = 'Необходимо заполнить обязательные поля';


        if (validationError === null) return true;

        this.setState({validationError: validationError});
        return false;
    }

    handleCreate = (e) => {
        // const {firstName,lastName,middleName, tariff, email,phone,documentType,nationality,gender,documentNumber,birthdate,rzhdBonus,universalRzhdCard,businessTravel} = this.state
        // this.props.handleCreate({firstName,lastName,middleName})
        if(this.validation()){
            this.props.handleCreate()
            e.preventDefault();
        }
    }

    toggle = () =>{
        this.props.toggle()
    }

    handleInputBlur =
        e => {
            var name = e.target.name
            var value = e.target.value
            var lastChar = value.slice(-1)
            value = lastChar === '-' || lastChar === '.' ? value.slice(0, -1) : value
            this.props.fieldUpdate(name, value);
            this.setState({[name]: value});
        }

    handleInputDate =
        birthdate => {
            this.props.fieldUpdate('birthdate', dateToString(birthdate));
            this.setState({[birthdate]: dateToString(birthdate)});
            setTimeout(function () {
                    this.props.fieldUpdate('tariff', 'Adult');
                    this.setState({["tariff"]: "Adult"});
                    this.setState({tariffUpdate: 'update'});
                }
                    .bind(this),
                100,
            );
        }
    handleSelect =
        key =>
            option => {
                this.props.fieldUpdate(key, option.value);
                this.setState({[key]: option.value});
            }

    handleSelectNationality =
        key =>
            option => {
                this.props.fieldUpdate(key, option.value);
                this.setState({[key]: option.value});
                setTimeout(function () {
                        this.props.fieldUpdate('tariff', 'Adult');
                        this.setState({["tariff"]: "Adult"});
                        this.setState({tariffUpdate: 'update'});
                    }
                        .bind(this),
                    100,
                );
            }
    tariffReady = () => {
        this.setState({tariffUpdate: 'ready'});
    }

    handleCheckbox =
        key =>
            e => {
                this.props.fieldUpdate(key, e.target.checked);
                this.setState({[key]: e.target.checked});
            }

    handleTariffSelectCheck = (e) => {
        this.props.fieldUpdate('tariff', e);
        this.setState({['tariff']: e});
    }

    handleTariffSelectCheckReturn = (e) => {
        this.props.fieldUpdate('tariffReturn', e);
        this.setState({['tariffReturn']: e});
    }

    handleSelectSaved =
        el => {
            Object.keys(el)
                .map(key => this.props.fieldUpdate(key, el[key]));
            if (el.id == 666666) return false;
            this.props.fieldUpdate('isSaved', false);
            this.setState({['isSaved']: false});
            if (el.email === null) {
                this.props.fieldUpdate('email', this.props.data.email);
                this.setState({['email']: this.props.data.email});
            }
            if (el.phone === null) {
                this.props.fieldUpdate('phone', this.props.data.phone);
                this.setState({['phone']: this.props.data.phone});
            }
            this.setState({tariffUpdate: 'update'});
        }

    renderDocumentNumber =
        documentType => {
            const {documentNumber} = this.props.passenger;

            if (documentType === 'RussianPassport' || documentType === undefined) {
                return (<InputMask maskChar="0" className="input input__first-name" type="text" name="documentNumber"
                                   placeholder="№ документа:"
                                   onChange={this.handleInput} value={documentNumber || this.state.documentNumber}
                                   mask="9999 999999"
                                   maskChar={null}
                                   required

                    />
                )
            }

            if (documentType === 'UssrPassport') {
                return (<input className="input input__first-name" type="text" name="documentNumber"
                               placeholder="№ документа:"
                               onChange={this.handleInput}
                               value={documentNumber || this.state.documentNumber}
                               required

                    />
                )
            }

            if (documentType === 'BirthCertificate') {
                return (<input className="input input__first-name" type="text" name="documentNumber"
                               placeholder="№ документа:"
                               onChange={this.handleInput} value={documentNumber || this.state.documentNumber}
                               required

                    />
                )
            }

            if (documentType === 'ForeignPassport') {
                return (<input className="input input__first-name" type="text" name="documentNumber"
                               placeholder="№ документа:"
                               onChange={this.handleInput} value={documentNumber || this.state.documentNumber}
                               required

                    />
                )
            }

            if (documentType === 'RussianForeignPassport') {
                return (<InputMask maskChar="0" className="input input__first-name" type="text" name="documentNumber"
                                   placeholder="№ документа:"
                                   onChange={this.handleInput} value={documentNumber || this.state.documentNumber}
                                   mask="999999999"
                                   maskChar={null}
                                   required
                    />
                )
            }

            return (
                <input className="input input__first-name" type="text" name="documentNumber" placeholder="№ документа:"
                       onChange={this.handleInput} value={documentNumber || this.state.documentNumber}
                       required
                />
            )
        }

    render() {
        const {title = null, passenger = {}, passengers = [], saveOption = false, selectSavedOption = false, handleRemove = null, error = null, tariff = false, booking} = this.props;
        const {email, phone, lastName, firstName, middleName, documentType, nationality, gender, documentNumber, birthdate, rzhdBonus, universalRzhdCard, businessTravel} = passenger;
        const price = passenger.price ? passenger.price : null;
        const {validationError} = this.state
        return (<div className="inner-page apply-page">
                <form>
                    <div className="form train-form">
                        {title ? (<div className="title">{title} {price &&
                        <span className="price">Цена: {price} руб.</span>} </div>) : null}

                        {handleRemove === null ? null : (<div className="deletePassenger" onClick={this.props.handleRemove}>
                                <span>&times;</span>
                            </div>
                        )}
                        <div className="box-info">
                            {selectSavedOption === false ? null : (<div className="mb-4">
                                    <SelectSaved onSelect={this.handleSelectSaved}/>
                                </div>
                            )}

                            <div className="desc-info d-flex align-items-center f-wrap">
                                <div className="desc-info__item">
                                    <span className="desc-info__item-required">*</span>
                                    <input className="input input__last-name" maxLength={40} type="text" name="lastName"
                                           placeholder="Фамилия:"
                                           onChange={this.handleInput} value={lastName || this.state.lastName}
                                           onBlur={this.handleInputBlur}
                                           required
                                    />
                                </div>
                                <div className="desc-info__item">
                                    <span className="desc-info__item-required">*</span>
                                    <input className="input input__first-name" maxLength={20} type="text" name="firstName"
                                           placeholder="Имя:"
                                           onChange={this.handleInput} value={firstName || this.state.firstName}
                                           onBlur={this.handleInputBlur}
                                           required
                                    />
                                </div>
                                <div className="desc-info__item">
                                    <input className="input input__first-name" maxLength={20} type="text" name="middleName"
                                           placeholder="Отчество:"
                                           onChange={this.handleInput} value={middleName || this.state.middleName}
                                           onBlur={this.handleInputBlur}
                                           required
                                    />
                                </div>
                                <div className="desc-info__item">
                                    <span className="desc-info__item-required desc-info__item-required--select">*</span>
                                    <Select options={documentOptions} placeholder="Вид документа"
                                            onChange={this.handleSelect('documentType')}
                                            value={documentOptions.find(el => el.value === documentType)}
                                            noOptionsMessage={() => ('Выберите из списка')}

                                    />
                                </div>
                                <div className="desc-info__item">
                                    <span className="desc-info__item-required desc-info__item-required--select">*</span>
                                    <Select options={nationalityOptions} placeholder="Гражданство"
                                            value={nationalityOptions.find(el => el.value === nationality)}
                                        //  || ( nationality === undefined && el.value === 'RU' )
                                            onChange={this.handleSelectNationality('nationality')}
                                            noOptionsMessage={() => ('Выберите из списка')}
                                    />
                                </div>
                                <div className="desc-info__item">
                                    <span className="desc-info__item-required desc-info__item-required--select">*</span>
                                    <Select options={genderOptions} placeholder="Пол"
                                            onChange={this.handleSelect('gender')}
                                            value={genderOptions.find(el => el.value === gender)}
                                            noOptionsMessage={() => ('Выберите из списка')}
                                    />
                                </div>
                                <div className="desc-info__item">
                                    <span className="desc-info__item-required">*</span>
                                    {
                                        this.renderDocumentNumber(documentType)
                                    }
                                </div>
                                <div className="desc-info__item">

                                    {/*<span className="title">Дата рождения: <span className="required-item">*</span></span>*/}
                                    <span className="desc-info__item-required">*</span>

                                    <DatePicker
                                        className="input input__first-name input__birthdate" name="birthdate"
                                        onChange={
                                            birthdate => this.handleInputDate(birthdate)}
                                        value={birthdate || this.state.birthdate}
                                        minDate={new Date(1900, 0, 0)}
                                        maxDate={new Date(3000, 0, 0)}
                                        yearPlaceholder="гггг"
                                        monthPlaceholder="мм"
                                        dayPlaceholder="дд"
                                        disableCalendar={true}
                                        format="d-M-y"
                                        showLeadingZeros={false}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="box-info user-data">
                                <div className="desc-info d-flex align-items-center">
                                    {/*<div className="title phone">Телефон: <span className="required-item">*</span></div>*/}
                                    <div className="desc-info__item_user">

                                        <span className="desc-info__item-required">*</span>

                                        <InputMask maskChar="0" className="input input__phone" type="tel" name="phone"
                                                   placeholder="+7 985 000 0000"
                                                   mask="+9 999 999 9999"
                                                   onChange={this.handleInput}
                                                   value={phone || this.state.phone}
                                                   maskChar={null}
                                        />
                                    </div>
                                    <div className="desc-info__item_user">

                                        <span className="desc-info__item-required">*</span>

                                        <input className="input input__email" type="email" name="email"
                                               placeholder="E-mail:"
                                               onChange={this.handleInput} value={email || this.state.email}
                                               required
                                        />
                                    </div>
                                </div>
                                {saveOption === false ? null : this.props.jwt === undefined ? null : (
                                    <div className="save-data">
                                        <label className="checkbox">
                                        <span className="icon-item">
                                          <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                            <input type="checkbox" onChange={this.handleCheckbox('isSaved')}/>
                                            <div className="checkbox__text">
                                                Сохранить данные пассажира в моём профиле
                                            </div>
                                        </label>
                                    </div>
                                )}


                            </div>
                        </div>
                        {tariff === false ? null : (<Tariff
                                tariff={this.tariff}
                                birthdate={birthdate}
                                nationality={nationality}
                                onClickForvard={this.handleTariffSelectCheck}
                                onClickReturn={this.handleTariffSelectCheckReturn}
                                booking={booking}
                                tariffUpdate={this.state.tariffUpdate}
                                tariffReady={this.tariffReady}
                                disabled={universalRzhdCard || businessTravel ? 'disabled' : ''}
                            />
                        )}
                        <div className="box-info mt-25">
                            <div className="title">Дорожная карта:</div>
                            <div className="desc-info d-flex align-items-center f-wrap">
                                <div className="desc-info__item">
                                    <InputMask
                                        maskChar="x"
                                        className="input input__last-name"
                                        type="text"
                                        name="rzhdBonus"
                                        placeholder="Карта «РЖД Бонус»"
                                        mask="9999999999999"
                                        onChange={this.handleInput}
                                        value={rzhdBonus || ''}
                                    />
                                </div>
                                <div className="desc-info__item">
                                    <InputMask
                                        maskChar="x"
                                        className="input input__first-name"
                                        type="text"
                                        name="universalRzhdCard"
                                        placeholder="Универсальная карта РЖД"
                                        mask="9999999999999"
                                        onChange={this.handleInput}
                                        value={universalRzhdCard || ''}
                                        disabled={businessTravel}
                                    />
                                </div>
                                {/*<div className="desc-info__item">*/}
                                {/*    <InputMask*/}
                                {/*        maskChar="x"*/}
                                {/*        className="input input__first-name"*/}
                                {/*        type="text"*/}
                                {/*        name="businessTravel"*/}
                                {/*        placeholder="Деловой проездной"*/}
                                {/*        mask="9999999999999"*/}
                                {/*        onChange={this.handleInput}*/}
                                {/*        value={businessTravel || ''}*/}
                                {/*        disabled={universalRzhdCard}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {(error !== null || this.state.error !== null) ? (
                            <p className="error-block">{error}{this.state.error}</p>) : null}
                        {validationError !== null ? (<p className="signup-error-block">{validationError}</p>) : null}
                    </div>
                    {this.props.activeButton && <div className="btn-wrap d-flex  align-items-center">
                        <button type={this.state.nationality.length < 1 && this.state.documentNumber.length < 1 && this.state.documentType.length < 1 ? 'submit' : 'button'}
                                className="btn btn__white active" onClick={this.handleCreate}>Сохранить</button>
                        <button className="btn btn__white" onClick={this.toggle}>Отменить</button>
                    </div>}
                </form>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.user.data,
        jwt: state.auth.jwt,
    }
}

export default connect(mapStateToProps, {getData})(Form);
