import * as types from './../constants/ActionTypesBag';

const initialState = {
  msg: '',
};

export default (state = initialState,
                action) => {

  switch (action.type) {

    case types.BAG_TIME_EXPIRED:
      return action.payload;

    default:
      return state;
  }
};
