import React, {PureComponent} from 'react';

class Tariff extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      passengers: [],
      tariff: 'Adult',
      tariffReturn: 'Adult',
    };

  }

  handleClick = (val) => () => {
    if (val === 'ReturnTariff') {
      this.setState({
                      tariffReturn: 'ReturnTariff',
                      tariff: 'ReturnTariff',
                    });
      this.props.onClickForvard(val);
      this.props.onClickReturn(val);
    }
    else {
      if (this.state.tariffReturn === 'ReturnTariff') {
        this.setState({tariffReturn: 'Adult'});
      }
      this.setState({tariff: val});
      this.props.onClickForvard(val);
      this.props.onClickReturn(this.state.tariffReturn);
    }

  };

  handleClickReturn = (val) => () => {
    if (val === 'ReturnTariff') {
      this.setState({
                      tariff: 'ReturnTariff',
                      tariffReturn: 'ReturnTariff',
                    });
      this.props.onClickForvard(val);
      this.props.onClickReturn(val);
    }
    else {
      if (this.state.tariff === 'ReturnTariff') {
        this.setState({tariff: 'Adult'});
      }
      this.setState({tariffReturn: val});
      this.props.onClickForvard(this.state.tariff);
      this.props.onClickReturn(val);
    }

  };

  getAge = (dateString, depart) => {
    var today = new Date(depart);
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (
        m === 0 && today.getDate() < birthDate.getDate()
    )) {
      age--;
    }
    return age;
  };

  render() {
    const {booking, disabled} = this.props;
    let birthdate = 0;
    if (booking.forwardData && booking.forwardData.train) {
      birthdate = this.props.birthdate !== undefined ? this.getAge(
          this.props.birthdate, booking.forwardData.train.departureDate) : 0;
    }else{
      return false;
    }
    let birthdateBack = 0;
    if (booking.returnData && booking.returnData.train) {
      birthdateBack = this.props.birthdate !== undefined &&
                      booking.returnData !== undefined ? this.getAge(
          this.props.birthdate, booking.returnData.train.departureDate) : 0;
    }
    const {tariff, tariffReturn} = this.state;
    const ru = this.props.nationality === 'RU';
    if (this.props.tariffUpdate === 'update') {
      this.setState({
                      tariffReturn: 'Adult',
                      tariff: 'Adult',
                    });
      this.props.tariffReady();
    }
    return (
        <div className={disabled + ' col tariff'}>
          <form ref={
            form => this.form = form}>
            <div className="box-info rate-type">
              <div className="title">Выберите тариф:</div>

              <div
                  className="desc-info d-flex justify-content-sm-start justify-content-around align-items-center">
                <label className="checkbox">
                  <input
                      onChange={this.handleClick('Adult')}
                      checked={tariff === 'Adult' ? 'checked' : ''}
                      type="radio" value="Adult" name="tariff"
                  />
                  <div className="checkbox__text">
                    Полный
                  </div>
                </label>
                {birthdate < 10 &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClick('Child')}
                      checked={tariff === 'Child' ? 'checked' : ''}
                      type="radio" value="Child" name="tariff"/>
                  <div className="checkbox__text">
                    Детский
                  </div>
                </label>
                }

                {birthdate < 5 &&

                <label className="checkbox">
                  <input
                      onChange={this.handleClick('BabyWithoutPlace')}
                      checked={tariff === 'BabyWithoutPlace' ? 'checked' : ''}
                      type="radio" value="BabyWithoutPlace" name="tariff"/>
                  <div className="checkbox__text">
                    Детский без места
                  </div>
                </label>
                }

                <label className="checkbox">
                  <input
                      onChange={this.handleClick('Single')}
                      checked={tariff === 'Single' ? 'checked' : ''}
                      type="radio" value="Single" name="tariff"/>
                  <div className="checkbox__text">
                    Single
                  </div>
                </label>
                {(
                    birthdate >= 10 && birthdate <= 21 && ru
                ) &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClick('Junior')}
                      checked={tariff === 'Junior' ? 'checked' : ''}
                      type="radio" value="Junior" name="tariff"/>
                  <div className="checkbox__text">
                    Junior
                  </div>
                </label>
                }
                {(
                    birthdate >= 60 && ru
                ) &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClick('Senior')}
                      checked={tariff === 'Senior' ? 'checked' : ''}
                      type="radio" value="Senior" name="tariff"/>
                  <div className="checkbox__text">
                    Senior
                  </div>
                </label>
                }
                {booking.isSearchBack &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClick('ReturnTariff')}
                      checked={tariff === 'ReturnTariff' ? 'checked' : ''}
                      type="radio" value="ReturnTariff" name="tariff"/>
                  <div className="checkbox__text">
                    Туда-обратно
                  </div>
                </label>
                }
              </div>
            </div>
            {booking.isSearchBack &&
            <div className="box-info rate-type">
              <div className="title">Выберите тариф обратно:</div>

              <div
                  className="desc-info d-flex justify-content-sm-start justify-content-around align-items-center">
                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('Adult')}
                      checked={tariffReturn === 'Adult' ? 'checked' : ''}
                      type="radio" value="Adult" name="tariffReturn"
                  />
                  <div className="checkbox__text">
                    Полный
                  </div>
                </label>
                {birthdateBack < 10 &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('Child')}
                      checked={tariffReturn === 'Child' ? 'checked' : ''}
                      type="radio" value="Child" name="tariffReturn"/>
                  <div className="checkbox__text">
                    Детский
                  </div>
                </label>
                }

                {birthdateBack < 5 &&

                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('BabyWithoutPlace')}
                      checked={tariffReturn === 'BabyWithoutPlace'
                               ? 'checked'
                               : ''}
                      type="radio" value="BabyWithoutPlace"
                      name="tariffReturn"/>
                  <div className="checkbox__text">
                    Детский без места
                  </div>
                </label>
                }

                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('Single')}
                      checked={tariffReturn === 'Single' ? 'checked' : ''}
                      type="radio" value="Single" name="tariffReturn"/>
                  <div className="checkbox__text">
                    Single
                  </div>
                </label>
                {(
                    birthdateBack >= 10 && birthdateBack <= 21 && ru
                ) &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('Junior')}
                      checked={tariffReturn === 'Junior' ? 'checked' : ''}
                      type="radio" value="Junior" name="tariffReturn"/>
                  <div className="checkbox__text">
                    Junior
                  </div>
                </label>
                }
                {(
                    birthdateBack >= 60 && ru
                ) &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('Senior')}
                      checked={tariffReturn === 'Senior' ? 'checked' : ''}
                      type="radio" value="Senior" name="tariffReturn"/>
                  <div className="checkbox__text">
                    Senior
                  </div>
                </label>
                }
                {booking.isSearchBack &&
                <label className="checkbox">
                  <input
                      onChange={this.handleClickReturn('ReturnTariff')}
                      checked={tariffReturn === 'ReturnTariff' ? 'checked' : ''}
                      type="radio" value="ReturnTariff" name="tariffReturn"/>
                  <div className="checkbox__text">
                    Туда-обратно
                  </div>
                </label>
                }

              </div>
            </div>
            }
          </form>
        </div>
    );
  }
}

export default Tariff;
