import React from 'react';

export default
props => {
  const {handleEndHover, handleHover, handleClick, availableSeats, selectedSeats} = props;

  return (<svg version="1.1" id="Layer_1" pointerEvents="bounding-box" xmlnssvg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1140 306.8" style={{enableBackground: 'new 0 0 1140 306.8'}} xmlSpace="preserve">
      <g>
        <polyline className="t60-arrow" points="16.2,138.7 1,153.9 16.2,169.1 	"></polyline>
        <g>
          <g>
            <path className="fill-lightgray" d="M221.9,136.1c6.1,0,11.1,5,11.1,11.1v27v2h2h50.3c6.1,0,11.1,5,11.1,11.1V222c0,6.1-5,11.1-11.1,11.1h-63.4
            H105.7c-6.1,0-11.1-5-11.1-11.1v-34.8v-40.1c0-6.1,5-11.1,11.1-11.1H221.9 M221.9,134.1H105.7c-7.2,0-13.1,5.8-13.1,13.1v40.1
            V222c0,7.2,5.8,13.1,13.1,13.1h116.2h63.4c7.2,0,13.1-5.8,13.1-13.1v-34.8c0-7.2-5.8-13.1-13.1-13.1h-50.3v-27
            C234.9,139.9,229.1,134.1,221.9,134.1L221.9,134.1z"></path>
          </g>
          <g id="_x33_fYnTB.tif_2_">
            <g>
              <path className="fill-darkgray" d="M177.5,178.8c2.6-0.4,4.2,0.2,5.2,2.1c0.8,1.6,0.5,3.6-0.8,4.9c-1.1,1.1-3.2,1.9-5.4,0.5
              c-0.1,0.1-0.1,0.2-0.2,0.4c-0.4,0.9-0.9,1.8-1.7,2.4c-0.8,0.6-1.7,1-2.7,1c-2.1,0-4.3,0-6.4,0c-1.7,0-2.8-0.9-3.7-2.2
              c-0.8-1.3-1.2-2.8-1.5-4.3c-0.4-2-0.4-4.1-0.4-6.2c0-0.7,0.4-1,1-1c3.7,0,7.4,0,11.1,0c1.4,0,2.9,0,4.3,0c0.7,0,1,0.3,1.1,1
              C177.5,177.8,177.5,178.3,177.5,178.8z M180.5,182.8c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6
              C179.8,184.4,180.5,183.7,180.5,182.8z"></path>
              <path className="fill-darkgray" d="M168.7,191.7c4.6,0,9.1,0,13.7,0c0.7,0,0.9,0.4,0.6,1c-0.3,0.4-0.7,0.7-1.1,0.9c-1,0.5-2,0.7-3.1,0.9
              c-3.6,0.6-7.3,0.7-11,0.6c-2.3,0-4.6-0.2-7-0.3c-1.6-0.1-3.1-0.4-4.6-0.9c-0.4-0.1-0.8-0.3-1.2-0.6c-0.3-0.2-0.5-0.6-0.7-1
              c-0.2-0.4,0.1-0.6,0.5-0.6c1.5,0,3.1,0,4.6,0C162.6,191.7,165.7,191.7,168.7,191.7C168.7,191.7,168.7,191.7,168.7,191.7z"></path>
              <path className="fill-darkgray" d="M171.6,165c1,2,0.8,4.2-1.4,5.4c-0.3,0.2-0.6,0.4-0.9,0.5c-1.7,0.6-2.4,1.9-2.4,3.6c0,0.2,0,0.3,0,0.5
              c-0.8,0-1.6,0-2.3,0c-0.2-1.6,0.3-3.8,1.8-4.8c0.6-0.4,1.2-0.7,1.8-1c0.5-0.3,1-0.5,1.4-0.8c1.2-0.7,1.8-1.8,1.9-3.1
              C171.6,165.2,171.6,165.1,171.6,165C171.6,165,171.6,165,171.6,165z"></path>
              <path className="fill-darkgray" d="M172.5,175c-0.8,0-1.5,0-2.3,0c-0.1-1,0.3-2,1.2-2.4c0.3-0.2,0.6-0.3,1-0.4c1-0.3,1.6-0.9,1.5-2
              c0.4,0.7,0.8,1.4,0.8,2.3c0,0.4-0.2,0.7-0.5,0.8c-0.2,0.1-0.5,0.3-0.7,0.4C172.7,174.1,172.6,174.3,172.5,175z"></path>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path className="fill-lightgray" d="M994.5,176.9c7.4,0,13.3,6,13.3,13.3V234h-26.7v-43.7C981.1,182.9,987.1,176.9,994.5,176.9 M994.5,175.9
                L994.5,175.9c-7.9,0-14.4,6.4-14.4,14.4V235h28.7v-44.7C1008.8,182.3,1002.4,175.9,994.5,175.9L994.5,175.9z"></path>
                <path className="fill-lightgray" d="M1007.8,72.8v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V72.8H1007.8 M1008.8,71.7h-28.7v44.7
                c0,7.9,6.4,14.4,14.4,14.4l0,0c7.9,0,14.4-6.4,14.4-14.4V71.7L1008.8,71.7z"></path>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path className="fill-lightgray" d="M869.5,176.9c7.4,0,13.3,6,13.3,13.3V234h-26.7v-43.7C856.1,182.9,862.1,176.9,869.5,176.9 M869.5,175.9
                L869.5,175.9c-7.9,0-14.4,6.4-14.4,14.4V235h28.7v-44.7C883.8,182.3,877.4,175.9,869.5,175.9L869.5,175.9z"></path>
                <path className="fill-lightgray" d="M882.8,72.8v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V72.8H882.8 M883.8,71.7h-28.7v44.7
                c0,7.9,6.4,14.4,14.4,14.4l0,0c7.9,0,14.4-6.4,14.4-14.4V71.7L883.8,71.7z"></path>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path className="fill-lightgray" d="M744.5,176.9c7.4,0,13.3,6,13.3,13.3V234h-26.7v-43.7C731.1,182.9,737.1,176.9,744.5,176.9 M744.5,175.9
                L744.5,175.9c-7.9,0-14.4,6.4-14.4,14.4V235h28.7v-44.7C758.8,182.3,752.4,175.9,744.5,175.9L744.5,175.9z"></path>
                <path className="fill-lightgray" d="M757.8,72.8v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V72.8H757.8 M758.8,71.7h-28.7v44.7
                c0,7.9,6.4,14.4,14.4,14.4l0,0c7.9,0,14.4-6.4,14.4-14.4V71.7L758.8,71.7z"></path>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path className="fill-lightgray" d="M619.5,176.9c7.4,0,13.3,6,13.3,13.3V234h-26.7v-43.7C606.1,182.9,612.1,176.9,619.5,176.9 M619.5,175.9
                L619.5,175.9c-7.9,0-14.4,6.4-14.4,14.4V235h28.7v-44.7C633.8,182.3,627.4,175.9,619.5,175.9L619.5,175.9z"></path>
                <path className="fill-lightgray" d="M632.8,72.8v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V72.8H632.8 M633.8,71.7h-28.7v44.7
                c0,7.9,6.4,14.4,14.4,14.4l0,0c7.9,0,14.4-6.4,14.4-14.4V71.7L633.8,71.7z"></path>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path className="fill-lightgray" d="M494.5,176.9c7.4,0,13.3,6,13.3,13.3V234h-26.7v-43.7C481.1,182.9,487.1,176.9,494.5,176.9 M494.5,175.9
                L494.5,175.9c-7.9,0-14.4,6.4-14.4,14.4V235h28.7v-44.7C508.8,182.3,502.4,175.9,494.5,175.9L494.5,175.9z"></path>
                <path className="fill-lightgray" d="M507.8,72.8v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V72.8H507.8 M508.8,71.7h-28.7v44.7
                c0,7.9,6.4,14.4,14.4,14.4h0c7.9,0,14.4-6.4,14.4-14.4V71.7L508.8,71.7z"></path>
              </g>
            </g>
          </g>
          {/* <g>
           <g>
           <g>
           <path className="fill-lightgray" d="M369.5,176.9c7.4,0,13.3,6,13.3,13.3V234h-26.7v-43.7C356.1,182.9,362.1,176.9,369.5,176.9 M369.5,175.9
           L369.5,175.9c-7.9,0-14.4,6.4-14.4,14.4V235h28.7v-44.7C383.8,182.3,377.4,175.9,369.5,175.9L369.5,175.9z"></path>
           <path className="fill-lightgray" d="M382.8,72.8v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V72.8H382.8 M383.8,71.7h-28.7v44.7
           c0,7.9,6.4,14.4,14.4,14.4h0c7.9,0,14.4-6.4,14.4-14.4V71.7L383.8,71.7z"></path>
           </g>
           </g>
           </g> */}
        </g>
        <polyline className="t60-arrow" points="1123.4,169.5 1139,153.9 1123.4,138.3 	"></polyline>
        <g id="G1dcqi_3_">
          <g>
            <path className="fill-lightgray" d="M1081.7,82.7c0,2.6,0,5.3,0,7.9c0,1.7-1.3,3-3,3c-5.3,0-10.6,0-15.8,0c-1.7,0-3-1.3-3-3c0-3.7,0-7.4,0-11
            c0-1.6,0-3.1,0-4.7c0-1.8,1.3-3.1,3.1-3.1c1.8,0,3.7,0,5.5,0c3.4,0,6.8,0,10.2,0c1.4,0,2.6,0.9,2.9,2.3c0,0.2,0.1,0.4,0.1,0.6
            c0,1.6,0,3.2,0,4.7C1081.7,80.5,1081.7,81.6,1081.7,82.7C1081.7,82.7,1081.7,82.7,1081.7,82.7z M1060.8,82.7
            C1060.8,82.7,1060.9,82.7,1060.8,82.7c0,2.6,0,5.2,0,7.8c0,1.2,0.9,2.2,2.2,2.2c5.2,0,10.4,0,15.6,0c1.3,0,2.2-0.9,2.2-2.2
            c0-3.5,0-7,0-10.5c0-1.7,0-3.5,0-5.2c0-1.3-0.9-2.1-2.2-2.1c-4.2,0-8.3,0-12.5,0c-1.1,0-2.1,0-3.2,0c-1.2,0-2.1,0.9-2.1,2.1
            c0,1.2,0,2.4,0,3.6C1060.8,79.8,1060.8,81.2,1060.8,82.7z"></path>
            <path className="fill-darkgray" d="M1077.6,82.7c0,3.7-3,6.7-6.6,6.7c-3.7,0.1-6.8-2.9-6.8-6.6c-0.1-3.8,2.9-6.8,6.6-6.9
            C1074.3,75.8,1077.5,78.7,1077.6,82.7z M1070.3,76.9c-2.5,0.1-5.2,2.2-5.3,5.6c-0.1,3.4,2.6,5.8,5.3,5.9c0,0,0-0.1,0-0.1
            c0-0.3,0-0.7,0-1c0-0.3,0.2-0.4,0.4-0.5c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.2,0,0.4c0,0.3,0,0.5,0,0.8c1.3,0,3.5-0.8,4.6-2.9
            c1.1-2.1,1-4.2-0.3-6.1c-1-1.5-2.5-2.3-4.3-2.5c0,0.4,0,0.8,0,1.1c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5
            C1070.3,77.6,1070.3,77.3,1070.3,76.9z"></path>
            <path className="fill-darkgray"
                  d="M1073.8,81.6c0.6,0,1.1,0.5,1,1c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1.1C1072.7,82.1,1073.2,81.6,1073.8,81.6z"></path>
            <path className="fill-darkgray"
                  d="M1066.8,82.7c0-0.6,0.5-1,1-1c0.6,0,1,0.4,1.1,1c0,0.6-0.4,1-1,1C1067.3,83.7,1066.8,83.3,1066.8,82.7z"></path>
          </g>
        </g>

        <g className={(availableSeats.hasOwnProperty('7') ?
          (selectedSeats.includes('7') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('7')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('7')}>
          <path d="M937.9,234.9L937.9,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C939.3,234.3,938.7,234.9,937.9,234.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 944.2717 226.5312)" className="train-seat__text">7</text>
          <path d="M962.7,206.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V209
                c0-1.4,1.2-2.6,2.6-2.6H962.7 M962.7,205.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C968.4,208.4,965.9,205.9,962.7,205.9L962.7,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('8') ?
          (selectedSeats.includes('8') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('8')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('8')}>
          <path d="M937.9,202L937.9,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C939.3,201.4,938.7,202,937.9,202z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 944.2717 193.6377)" className="train-seat__text">8</text>
          <path d="M962.7,173.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H962.7 M962.7,173h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
                v-17.5C968.4,175.5,965.9,173,962.7,173L962.7,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('4') ?
          (selectedSeats.includes('4') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('4')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('4')}>
          <path d="M937.9,133.7L937.9,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C939.3,133.1,938.7,133.7,937.9,133.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 944.2717 125.3184)" className="train-seat__text">4</text>
          <path d="M962.7,105.2c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H962.7 M962.7,104.6h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C968.4,107.2,965.9,104.6,962.7,104.6L962.7,104.6z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('3') ?
          (selectedSeats.includes('3') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('3')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('3')}>
          <path d="M937.9,100.8L937.9,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C939.3,100.2,938.7,100.8,937.9,100.8z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 944.2717 92.4248)" className="train-seat__text">3</text>
          <path d="M962.7,72.3c2.9,0,5.2,2.4,5.2,5.2V95c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V74.9
                c0-1.4,1.2-2.6,2.6-2.6H962.7 M962.7,71.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8V77.5C968.4,74.3,965.9,71.8,962.7,71.8L962.7,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('5') ?
          (selectedSeats.includes('5') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('5')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('5')}>
          <path d="M1049.5,205.9L1049.5,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C1048.1,206.5,1048.7,205.9,1049.5,205.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 1023.9689 226.5312)" className="train-seat__text">5</text>
          <path d="M1044.9,206.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H1044.9 M1044.9,205.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V209C1048,207.3,1046.6,205.9,1044.9,205.9L1044.9,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('6') ?
          (selectedSeats.includes('6') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('6')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('6')}>
          <path d="M1049.5,173L1049.5,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C1048.1,173.6,1048.7,173,1049.5,173z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 1023.9689 193.6377)" className="train-seat__text">6</text>
          <path d="M1044.9,173.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H1044.9 M1044.9,173h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1v-22.8C1048,174.4,1046.6,173,1044.9,173L1044.9,173z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('2') ?
          (selectedSeats.includes('2') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('2')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('2')}>
          <path d="M1049.5,104.6L1049.5,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C1048.1,105.3,1048.7,104.6,1049.5,104.6z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 1023.9689 125.3184)" className="train-seat__text">2</text>
          <path d="M1044.9,105.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H1044.9 M1044.9,104.7h-20.2c-3.2,0-5.8,2.6-5.8,5.8V128c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1v-22.8C1048,106.1,1046.6,104.7,1044.9,104.7L1044.9,104.7z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('1') ?
          (selectedSeats.includes('1') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('1')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('1')}>
          <path d="M1049.5,71.7L1049.5,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
              C1048.1,72.4,1048.7,71.7,1049.5,71.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 1023.9689 92.4248)" className="train-seat__text">1</text>
          <path d="M1044.9,72.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.5
                c0-2.9,2.4-5.2,5.2-5.2H1044.9 M1044.9,71.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V74.9C1048,73.2,1046.6,71.8,1044.9,71.8L1044.9,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('15') ?
          (selectedSeats.includes('15') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('15')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('15')}>
          <path d="M812.9,234.9L812.9,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C814.3,234.3,813.7,234.9,812.9,234.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 819.2717 226.5312)" className="train-seat__text">15</text>
          <path d="M837.7,206.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V209
                c0-1.4,1.2-2.6,2.6-2.6H837.7 M837.7,205.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C843.4,208.4,840.9,205.9,837.7,205.9L837.7,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('16') ?
          (selectedSeats.includes('16') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('16')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('16')}>
          <path d="M812.9,202L812.9,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C814.3,201.4,813.7,202,812.9,202z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 819.2717 193.6377)" className="train-seat__text">16</text>
          <path d="M837.7,173.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H837.7 M837.7,173h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
                v-17.5C843.4,175.5,840.9,173,837.7,173L837.7,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('12') ?
          (selectedSeats.includes('12') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('12')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('12')}>
          <path d="M812.9,133.7L812.9,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C814.3,133.1,813.7,133.7,812.9,133.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 819.2717 125.3184)" className="train-seat__text">12</text>
          <path d="M837.7,105.2c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H837.7 M837.7,104.6h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C843.4,107.2,840.9,104.6,837.7,104.6L837.7,104.6z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('11') ?
          (selectedSeats.includes('11') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('11')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('11')}>
          <path d="M812.9,100.8L812.9,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C814.3,100.2,813.7,100.8,812.9,100.8z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 819.2717 92.4248)" className="train-seat__text">11</text>
          <path d="M837.7,72.3c2.9,0,5.2,2.4,5.2,5.2V95c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V74.9
                c0-1.4,1.2-2.6,2.6-2.6H837.7 M837.7,71.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8V77.5C843.4,74.3,840.9,71.8,837.7,71.8L837.7,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('13') ?
          (selectedSeats.includes('13') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('13')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('13')}>
          <path d="M924.5,205.9L924.5,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C923.1,206.5,923.7,205.9,924.5,205.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 898.9689 226.5312)" className="train-seat__text">13</text>
          <path d="M919.9,206.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H919.9 M919.9,205.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V209C923,207.3,921.6,205.9,919.9,205.9L919.9,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('14') ?
          (selectedSeats.includes('14') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('14')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('14')}>
          <path d="M924.5,173L924.5,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C923.1,173.6,923.7,173,924.5,173z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 898.9689 193.6377)" className="train-seat__text">14</text>
          <path d="M919.9,173.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H919.9 M919.9,173h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
                v-22.8C923,174.4,921.6,173,919.9,173L919.9,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('10') ?
          (selectedSeats.includes('10') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('10')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('10')}>
          <path d="M924.5,104.6L924.5,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C923.1,105.3,923.7,104.6,924.5,104.6z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 898.9689 125.3184)" className="train-seat__text">10</text>
          <path d="M919.9,105.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H919.9 M919.9,104.7h-20.2c-3.2,0-5.8,2.6-5.8,5.8V128c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1v-22.8C923,106.1,921.6,104.7,919.9,104.7L919.9,104.7z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('9') ?
          (selectedSeats.includes('9') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('9')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('9')}>
          <path d="M924.5,71.7L924.5,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
              C923.1,72.4,923.7,71.7,924.5,71.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 898.9689 92.4248)" className="train-seat__text">9</text>
          <path d="M919.9,72.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.5
                c0-2.9,2.4-5.2,5.2-5.2H919.9 M919.9,71.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V74.9C923,73.2,921.6,71.8,919.9,71.8L919.9,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('23') ?
          (selectedSeats.includes('23') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('23')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('23')}>
          <path d="M687.9,234.9L687.9,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C689.3,234.3,688.7,234.9,687.9,234.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 694.2717 226.5312)" className="train-seat__text">23</text>
          <path d="M712.7,206.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V209
                c0-1.4,1.2-2.6,2.6-2.6H712.7 M712.7,205.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C718.4,208.4,715.9,205.9,712.7,205.9L712.7,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('24') ?
          (selectedSeats.includes('24') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('24')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('24')}>
          <path d="M687.9,202L687.9,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C689.3,201.4,688.7,202,687.9,202z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 694.2717 193.6377)" className="train-seat__text">24</text>
          <path d="M712.7,173.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H712.7 M712.7,173h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
                v-17.5C718.4,175.5,715.9,173,712.7,173L712.7,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('20') ?
          (selectedSeats.includes('20') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('20')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('20')}>
          <path d="M687.9,133.7L687.9,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C689.3,133.1,688.7,133.7,687.9,133.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 694.2717 125.3184)" className="train-seat__text">20</text>
          <path d="M712.7,105.2c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H712.7 M712.7,104.6h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C718.4,107.2,715.9,104.6,712.7,104.6L712.7,104.6z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('19') ?
          (selectedSeats.includes('19') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('19')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('19')}>
          <path d="M687.9,100.8L687.9,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C689.3,100.2,688.7,100.8,687.9,100.8z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 694.2717 92.4248)" className="train-seat__text">19</text>
          <path d="M712.7,72.3c2.9,0,5.2,2.4,5.2,5.2V95c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V74.9
                c0-1.4,1.2-2.6,2.6-2.6H712.7 M712.7,71.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8V77.5C718.4,74.3,715.9,71.8,712.7,71.8L712.7,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('21') ?
          (selectedSeats.includes('21') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('21')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('21')}>
          <path d="M799.5,205.9L799.5,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C798.1,206.5,798.7,205.9,799.5,205.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 773.9689 226.5312)" className="train-seat__text">21</text>
          <path d="M794.9,206.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H794.9 M794.9,205.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V209C798,207.3,796.6,205.9,794.9,205.9L794.9,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('22') ?
          (selectedSeats.includes('22') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('22')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('22')}>
          <path d="M799.5,173L799.5,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C798.1,173.6,798.7,173,799.5,173z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 773.9689 193.6377)" className="train-seat__text">22</text>
          <path d="M794.9,173.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H794.9 M794.9,173h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
                v-22.8C798,174.4,796.6,173,794.9,173L794.9,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('18') ?
          (selectedSeats.includes('18') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('18')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('18')}>
          <path d="M799.5,104.6L799.5,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C798.1,105.3,798.7,104.6,799.5,104.6z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 773.9689 125.3184)" className="train-seat__text">18</text>
          <path d="M794.9,105.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H794.9 M794.9,104.7h-20.2c-3.2,0-5.8,2.6-5.8,5.8V128c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1v-22.8C798,106.1,796.6,104.7,794.9,104.7L794.9,104.7z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('17') ?
          (selectedSeats.includes('17') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('17')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('17')}>
          <path d="M799.5,71.7L799.5,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
              C798.1,72.4,798.7,71.7,799.5,71.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 773.9689 92.4248)" className="train-seat__text">17</text>
          <path d="M794.9,72.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.5
                c0-2.9,2.4-5.2,5.2-5.2H794.9 M794.9,71.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V74.9C798,73.2,796.6,71.8,794.9,71.8L794.9,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('31') ?
          (selectedSeats.includes('31') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('31')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('31')}>
          <path d="M562.9,234.9L562.9,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C564.3,234.3,563.7,234.9,562.9,234.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 569.2717 226.5312)" className="train-seat__text">31</text>
          <path d="M587.7,206.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V209
                c0-1.4,1.2-2.6,2.6-2.6H587.7 M587.7,205.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C593.4,208.4,590.9,205.9,587.7,205.9L587.7,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('32') ?
          (selectedSeats.includes('32') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('32')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('32')}>
          <path d="M562.9,202L562.9,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C564.3,201.4,563.7,202,562.9,202z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 569.2717 193.6377)" className="train-seat__text">32</text>
          <path d="M587.7,173.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H587.7 M587.7,173h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
                v-17.5C593.4,175.5,590.9,173,587.7,173L587.7,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('28') ?
          (selectedSeats.includes('28') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('28')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('28')}>
          <path d="M562.9,133.7L562.9,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C564.3,133.1,563.7,133.7,562.9,133.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 569.2717 125.3184)" className="train-seat__text">28</text>
          <path d="M587.7,105.2c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H587.7 M587.7,104.6h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C593.4,107.2,590.9,104.6,587.7,104.6L587.7,104.6z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('27') ?
          (selectedSeats.includes('27') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('27')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('27')}>
          <path d="M562.9,100.8L562.9,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C564.3,100.2,563.7,100.8,562.9,100.8z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 569.2717 92.4248)" className="train-seat__text">27</text>
          <path d="M587.7,72.3c2.9,0,5.2,2.4,5.2,5.2V95c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V74.9
                c0-1.4,1.2-2.6,2.6-2.6H587.7 M587.7,71.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8V77.5C593.4,74.3,590.9,71.8,587.7,71.8L587.7,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('29') ?
          (selectedSeats.includes('29') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('29')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('29')}>
          <path d="M674.5,205.9L674.5,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C673.1,206.5,673.7,205.9,674.5,205.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 648.9689 226.5312)" className="train-seat__text">29</text>
          <path d="M669.9,206.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H669.9 M669.9,205.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V209C673,207.3,671.6,205.9,669.9,205.9L669.9,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('30') ?
          (selectedSeats.includes('30') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('30')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('30')}>
          <path d="M674.5,173L674.5,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C673.1,173.6,673.7,173,674.5,173z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 648.9689 193.6377)" className="train-seat__text">30</text>
          <path d="M669.9,173.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H669.9 M669.9,173h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
                v-22.8C673,174.4,671.6,173,669.9,173L669.9,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('26') ?
          (selectedSeats.includes('26') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('26')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('26')}>
          <path d="M674.5,104.6L674.5,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C673.1,105.3,673.7,104.6,674.5,104.6z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 648.9689 125.3184)" className="train-seat__text">26</text>
          <path d="M669.9,105.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H669.9 M669.9,104.7h-20.2c-3.2,0-5.8,2.6-5.8,5.8V128c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1v-22.8C673,106.1,671.6,104.7,669.9,104.7L669.9,104.7z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('25') ?
          (selectedSeats.includes('25') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('25')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('25')}>
          <path d="M674.5,71.7L674.5,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
              C673.1,72.4,673.7,71.7,674.5,71.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 648.9689 92.4248)" className="train-seat__text">25</text>
          <path d="M669.9,72.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.5
                c0-2.9,2.4-5.2,5.2-5.2H669.9 M669.9,71.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V74.9C673,73.2,671.6,71.8,669.9,71.8L669.9,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('39') ?
          (selectedSeats.includes('39') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('39')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('39')}>
          <path d="M437.9,234.9L437.9,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C439.3,234.3,438.7,234.9,437.9,234.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 444.2717 226.5312)" className="train-seat__text">39</text>
          <path d="M462.7,206.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V209
                c0-1.4,1.2-2.6,2.6-2.6H462.7 M462.7,205.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C468.4,208.4,465.9,205.9,462.7,205.9L462.7,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('40') ?
          (selectedSeats.includes('40') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('40')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('40')}>
          <path d="M437.9,202L437.9,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C439.3,201.4,438.7,202,437.9,202z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 444.2717 193.6377)" className="train-seat__text">40</text>
          <path d="M462.7,173.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H462.7 M462.7,173h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
                v-17.5C468.4,175.5,465.9,173,462.7,173L462.7,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('36') ?
          (selectedSeats.includes('36') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('36')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('36')}>
          <path d="M437.9,133.7L437.9,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C439.3,133.1,438.7,133.7,437.9,133.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 444.2717 125.3184)" className="train-seat__text">36</text>
          <path d="M462.7,105.2c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
                c0-1.4,1.2-2.6,2.6-2.6H462.7 M462.7,104.6h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8v-17.5C468.4,107.2,465.9,104.6,462.7,104.6L462.7,104.6z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('35') ?
          (selectedSeats.includes('35') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('35')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('35')}>
          <path d="M437.9,100.8L437.9,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
              C439.3,100.2,438.7,100.8,437.9,100.8z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 444.2717 92.4248)" className="train-seat__text">35</text>
          <path d="M462.7,72.3c2.9,0,5.2,2.4,5.2,5.2V95c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V74.9
                c0-1.4,1.2-2.6,2.6-2.6H462.7 M462.7,71.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2
                c3.2,0,5.8-2.6,5.8-5.8V77.5C468.4,74.3,465.9,71.8,462.7,71.8L462.7,71.8z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('37') ?
          (selectedSeats.includes('37') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('37')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('37')}>
          <path d="M549.5,205.9L549.5,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C548.1,206.5,548.7,205.9,549.5,205.9z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 523.9689 226.5312)" className="train-seat__text">37</text>
          <path d="M544.9,206.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H544.9 M544.9,205.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V209C548,207.3,546.6,205.9,544.9,205.9L544.9,205.9z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('38') ?
          (selectedSeats.includes('38') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('38')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('38')}>
          <path d="M549.5,173L549.5,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C548.1,173.6,548.7,173,549.5,173z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 523.9689 193.6377)" className="train-seat__text">38</text>
          <path d="M544.9,173.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H544.9 M544.9,173h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
                v-22.8C548,174.4,546.6,173,544.9,173L544.9,173z" className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('34') ?
          (selectedSeats.includes('34') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('34')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('34')}>
          <path d="M549.5,104.6L549.5,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
              C548.1,105.3,548.7,104.6,549.5,104.6z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 523.9689 125.3184)" className="train-seat__text">34</text>
          <path d="M544.9,105.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
                c0-2.9,2.4-5.2,5.2-5.2H544.9 M544.9,104.7h-20.2c-3.2,0-5.8,2.6-5.8,5.8V128c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1v-22.8C548,106.1,546.6,104.7,544.9,104.7L544.9,104.7z"
                className="train-seat__border"></path>
        </g>
        <g className={(availableSeats.hasOwnProperty('33') ?
          (selectedSeats.includes('33') ? 'selected' : 'available')
          : 'disabled') + ' train-seat'}
           onMouseEnter={() => handleHover('33')}
           onMouseLeave={() => handleEndHover()}
           onClick={() => handleClick('33')}>
          <path d="M549.5,71.7L549.5,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
              C548.1,72.4,548.7,71.7,549.5,71.7z" className="train-seat__dash"></path>
          <text transform="matrix(1 0 0 1 523.9689 92.4248)" className="train-seat__text">33</text>
          <path d="M544.9,72.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.5
                c0-2.9,2.4-5.2,5.2-5.2H544.9 M544.9,71.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
                c1.7,0,3.1-1.4,3.1-3.1V74.9C548,73.2,546.6,71.8,544.9,71.8L544.9,71.8z"
                className="train-seat__border"></path>
        </g>
      </g>
    </svg>
  );
}
