import React from 'react';
import Select from 'react-select';

export default
props => {
  let full_list = props.list;
  if (props.list.length > 0) {
    var found = false;
    for (var i = 0;
      i < full_list.length;
      i++
    ) {
      if (full_list[i].id === 666666) {
        found = true;
        break;
      }
    }
    if (!found) {
      full_list.unshift({id: 666666},
      );
    }
  }
  return (props.list.length === 0 ? null : (<Select
      placeholder="Выбрать пассажира из сохраненных"
      onChange={
        option => props.onSelect(props.list.find(el => el.id === option.value))}
      options={props.list.map(el => {
          if (el.id !== 666666) {
            return {
              value: el.id,
              label: el.firstName + ' ' + el.lastName,
            }
          }
          else {
            return {
              value: el.id,
              label: 'Новый пассажир',
            }
          }
        })}
    />
  ))
};
