import {connect} from 'react-redux';
import Widget from "../../components/Bag/Widget";
import {getList as getBag} from './../../actions/Bag';

const mapStateToProps =
    state => ({
        bag: state.bag,
        ticketsCount: state.bag.reservations.length
    });

export default connect(mapStateToProps, {getBag})(Widget);
