import React, {PureComponent, Fragment} from 'react';
import Navigation from './../../containers/Navigation/Cabinet';
import serviceClassOptions from './_serviceClassOptions';
import departureTimeOptions from './_departureTimeOptions';
import {options as trainsOptions} from './../../_data/trainsOptions';
import Passenger from './../Passengers/Form';
import {getDate} from './../../utility/time';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import {Helmet} from 'react-helmet';
import {options as timeOptions} from '../../_data/timeOptions';
import {Link} from 'react-router-dom';

class Alarm extends PureComponent {
  constructor(props) {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 90);
    super(props);
    this.props.paramSet('isSearchBack', false);
    this.state = {
      trainNumber    : '',
      destinationFrom: null,
      destinationTo  : null,
      serviceClass   : null,
      together       : true,
      nearby         : true,
      timeFrom       : '',
      timeTo         : '',
      date           : dateNow,
      error          : null,
      tariff         : 'full',
      isSaved        : true,
      textReadMore   : false,
      passengers     : [
        {
          email    : props.user.email,
          phone    : props.user.phone,
          firstName: props.user.firstName,
          lastName : props.user.lastName,
          ref      : React.createRef(),
        },
      ],
    };
  }

  componentDidMount() {
    // if ( this.state.passengers[0] && this.state.passengers[0].email === undefined && this.props.user.email != null )
    // { this.setState({ passengers: [{ email: this.props.user.email, phone: this.props.user.phone, firstName:
    // this.props.user.firstName, lastName: this.props.user.lastName }]}); }
  }

  passengerAdd = () => {
    let passengers = [...this.state.passengers];
    let ref = React.createRef();
    let t = {
      email    : this.props.user.email,
      phone    : this.props.user.phone,
      firstName: this.props.user.firstName,
      lastName : this.props.user.lastName,
      tariff   : 'full',
      ref      : ref,
    };
    passengers.push(t);
    this.setState({passengers});
    setTimeout(() => {
      ref.current.scrollIntoView({
                                   behavior: 'smooth',
                                   block   : 'start',
                                 })
    }, 100)
  }

  passengerUpdate =
    index => (key,
              value) => {
      let passengers = [...this.state.passengers];

      passengers[index][key] = value;
      this.setState({passengers});
    }

  passengerRemove =
    index => () => {
      let passengers = this.state.passengers.filter((el,
                                                     i) => i !== index);
      this.setState({passengers});
    }

  handleSet = (key,
               value) => {
    this.setState({[key]: value});
  }

  validatePassenger =
    passenger => {
      const {email, phone, lastName, firstName, documentType, nationality, gender, documentNumber, birthdate} = passenger;

      if (email === undefined || email.length === 0) return false;

      if (phone === undefined || phone === null || phone.length === 0) return false;

      if (lastName === undefined || lastName.length === 0) return false;

      if (firstName === undefined || firstName.length === 0) return false;

      if (documentType === undefined || documentType.length === 0) return false;

      if (nationality === undefined || nationality.length === 0) return false;

      if (gender === undefined || gender.length === 0) return false;

      if (documentNumber === undefined || documentNumber.length === 0) return false;

      if (birthdate === undefined || birthdate.length === 0) return false;

      return true;
    }

  validation = () => {
    const {passengers, destinationFrom, destinationTo, serviceClass, date} = this.state;

    if (destinationTo === destinationFrom) {
      this.setState({error: 'Город отправления и город назначения должны быть разными.'});
      return false;
    }

    if (destinationFrom === null) {
      this.setState({error: 'Выберите станцию отправления.'});
      return false;
    }

    if (destinationTo === null) {
      this.setState({error: 'Выберите станцию назначения.'});
      return false;
    }

    if (serviceClass === null) {
      this.setState({error: 'Выберите класс обслуживания.'});
      return false;
    }

    if (date === null) {
      this.setState({error: 'Выберите дату отправления.'});
      return false;
    }

    if (passengers.length === 0) {
      this.setState({error: 'Добавьте пассажиров.'});
      return false;
    }

    for (let i in
      passengers) {
      if (!this.validatePassenger(passengers[i])) {
        this.setState({error: 'Все поля обязательны для заполнения.'});
        return false;
      }
    }

    if (this.props.user.paymentMethod === null) {
      this.setState({error: 'ошибка карты'});
      return false;
    }
    return true;
  }

  handleSubmit = () => {
    if (!this.validation()) return false;
    let passengersorder = this.state.passengers.map(el => {
        delete el['ref'];
        return el;
      });
    const params = {
      email          : this.props.user.email,
      phone          : this.props.user.phone,
      together       : this.state.together,
      nearby         : this.state.nearby,
      passengers     : passengersorder,
      trainNumber    : this.state.trainNumber,
      destinationFrom: this.state.destinationFrom,
      destinationTo  : this.state.destinationTo,
      serviceClass   : this.state.serviceClass,
      date           : getDate(this.state.date, {}),
      timeFrom       : this.state.timeFrom,
      timeTo         : this.state.timeTo,
    }

    this.props.createNew(params)
        .then(data => {
            this.setState({passengers: [{}]})
            this.props.history.push('/request-success')
          })
  }

  renderTimeToSelect(departureTimeFrom) {
    for (let i = 0;
      i < departureTimeOptions.length;
      i++
    ) {
      if (departureTimeFrom === departureTimeOptions[i].value) {
        return (<Select
            options={departureTimeOptions.concat()
                                         .splice(i + 1, 24)}
            placeholder="Время отправления до"
            onChange={
              option => this.handleSet('departureTimeTo', option.value)}
          />
        )
      }
    }
  }

  handleReadMore = () => {
    this.setState({
                    textReadMore: !this.state.textReadMore,
                  });
  }

  render() {
    const {passengers, destinationFrom, destinationTo, date, error, together, nearby} = this.state;
    const errorBlock = error !== null ? (<p className="error-block">{error}</p>) : null;
    const {user, cities, modalOpen, jwt} = this.props;
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 90);
    const dateNextYear = new Date(dateNow.getFullYear() + 1, dateNow.getMonth(), dateNow.getDate());

    return (<Fragment>
        <Helmet>
          <title>Будильник</title>
          <meta name="description" content="alarm"/>
        </Helmet>
        <Navigation title="Будильник"/>

        <main className="main">
          <div className="page-text-description">
            {/*<!--noindex-->*/}
            <p>Хотите сэкономить, оформив билеты заранее при открытии продажи на поезда "Сапсан"? Тогда
              сервис "Будильник" - именно то, что нужно.</p>
            <p>Сервис самостоятельно "разбудит" сайт и оформит билеты в момент открытия продажи, пока вы
              спите.</p>
            {this.state.textReadMore ? (<React.Fragment>
                <p>Вам достаточно заранее спланировать свою поездку на "Сапсане": выбрать дату (или
                  даты), продажа на которые ещё не открыта, временной интервал отправления,
                  интересующие классы обслуживания и пассажиров, для кого мы будем оформлять
                  билеты.</p>
                <p>Оформление и списание денег происходит автоматически. Билеты направляются на
                  электронную почту, указанную в заявке.</p>
                <p>Перед использованием сервиса вам необходимо будет привязать банковскую карту в личном
                  кабинете или выбрать одну из ранее привязанных карт.</p>
                <p>Просим вас перед формированием заявки убедиться в достаточности средств на счёте.</p>
                <p>Направляя нам заявку, вы подтверждаете согласие с автоматическим списанием денежных
                  средств с выбранной банковской карты в случае успешного оформления билетов.</p>
                <p>Полные условия оказания услуги "Будильник" в Оферте пользователю ресурса.</p>
              </React.Fragment>
            ) : null}
            <button className="page-text__read-more"
                    onClick={this.handleReadMore}>{this.state.textReadMore ? 'Скрыть' : 'Показать полностью'}</button>

          </div>

          <div className="inner-page apply-page tasks-page wrapperForm">
            <div className="form">
              {jwt !== null ? null : (<div className="head d-flex j-content-end">
                  <Link to={'#'} className="link" onClick={() => modalOpen('signIn')}>
                    Авторизоваться
                  </Link>
                </div>
              )}

              <div className="desc-info d-flex align-items-center f-wrap form__first">
                <div className="desc-info__item">
                  <div className="title-info">Станция отправления <span
                    className="required-item">*</span></div>
                  <div className="input-icon">
                    <Select
                      className="header__select"
                      classNamePrefix="header__select"
                      options={cities} placeholder="Откуда"
                      onChange={
                        option => this.handleSet('destinationFrom', option.value)}
                      value={cities.find(el => el.value === destinationFrom)}
                    />
                    <span className="icon-item">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
                <div className="desc-info__item">
                  <div className="title-info">Станция назначения <span
                    className="required-item">*</span></div>
                  <Select options={cities} placeholder="Куда"
                          className="header__select"
                          classNamePrefix="header__select"
                          onChange={
                            option => this.handleSet('destinationTo', option.value)}
                          value={cities.find(el => el.value === destinationTo)}
                  />
                </div>
                <div className="desc-info__item">
                  <div className="title-info">Дата отправления <span
                    className="required-item">*</span></div>
                  <div className="input-icon date_wrapper">
                    <DatePicker className={date ? 'form__datepicker opened' : 'form__datepicker'}
                                onChange={
                                  date => this.handleSet('date', date)} value={date}
                                minDate={dateNow} maxDate={dateNextYear} format="d-MM-y"/>
                  </div>
                </div>
                <div className="desc-info__item">
                  <span className="desc-info__item-required desc-info__item-required--select">*</span>
                  <Select options={serviceClassOptions} placeholder="Класс обслуживания"
                          onChange={
                            option => this.handleSet('serviceClass', option.value)}
                  />
                </div>
                <div className="desc-info__item">
                  <Select options={timeOptions} placeholder="Время отправления от"
                          onChange={
                            option => this.handleSet('timeFrom', option.value)}
                  />
                </div>
                <div className="desc-info__item">

                  {
                    this.state.timeFrom ? this.renderTimeToSelect(this.state.timeFrom)
                      : (<Select
                          options={timeOptions}
                          placeholder="Время отправления до"
                          onChange={
                            option => this.handleSet('timeTo', option.value)}
                        />
                      )
                  }


                </div>

                <div className="desc-info__item">
                  <Select options={trainsOptions} placeholder="Номер поезда"
                          onChange={
                            option => this.handleSet('trainNumber', option.value)}
                  />
                </div>
                <div className="desc-info__item rate-type d-flex justify-content-center">
                  <label className="checkbox">
                    <input onChange={() => {
                      this.setState({together: !together})
                    }}
                           checked={together ? 'checked' : ''}
                           type="radio" value="together" name="together"/>
                    <div className="checkbox__text">
                      Выкуп билетов вместе
                    </div>
                  </label>
                </div>

                <div
                  className={!together ? 'd-none' : 'desc-info__item rate-type d-flex justify-content-center'}>
                  <label className="checkbox">
                    <input onChange={() => {
                      this.setState({nearby: !nearby})
                    }}
                           checked={nearby ? 'checked' : ''}
                           type="radio" value="nearby" name="nearby"/>
                    <div className="checkbox__text">
                      Билеты на соседних местах
                    </div>
                  </label>
                </div>

              </div>

              <div className="box-info">
                {passengers.map((el,
                                 i) => (<div ref={el.ref} id={el.ref}>
                    <Passenger
                      passenger={el}
                      selectSavedOption={true}
                      fieldUpdate={this.passengerUpdate(i)}
                      title={`Пассажир № ${i + 1}:`}
                      key={i}
                      handleRemove={i !== 0 ? this.passengerRemove(i) : null}
                      tariff={true}
                      booking={this.props.booking}
                    />
                  </div>
                ))}
              </div>
              {passengers.length < 4 ?
                <div className="add-passengers">
                                <span className="add-passengers__link pointer" onClick={this.passengerAdd}>
                                  <span className="plus">+</span>
                                  <span className="title">Добавить пассажира</span>
                                </span>
                </div> : null}

              {user.paymentMethod !== null ? null : (<Fragment>
                  <div className="message">
                    <p>К Вашему аккаунту не привязана платежная карта, поэтому мы не сможем
                      осуществить автоматический выкуп билета,
                      когда он появится в продаже.</p>
                    <span className="elem">i</span>
                  </div>

                  <div className="save-data">
                    <label className="checkbox">
                      <span className="icon-item">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                      <input type="checkbox"/>
                      <div className="checkbox__text" onClick={this.props.authorizePayment}>
                        Привязать карту
                      </div>
                    </label>
                  </div>
                </Fragment>
              )}

              {errorBlock}

              <div className="btn-wrap text-center">
                <button className="btn btn__main" onClick={this.handleSubmit}>Подать заявку</button>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default Alarm;
