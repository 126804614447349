import React from 'react';

export default props => {
    const {handleEndHover, handleHover, handleClick, availableSeats, selectedSeats} = props;
    return (
        <svg pointerEvents="bounding-box" version="1.1" id="Layer_1" xmlnssvg="http://www.w3.org/2000/svg"
             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 1140 306.8" style={{enableBackground: 'new 0 0 1140 306.8'}} xmlSpace="preserve">

            <polyline className="fill-darkgray" points="1107.8,92 1123.4,76.4 1139,92 "/>
            <polyline className="fill-darkgray" points="1107.8,223 1123.4,238.6 1139,223 "/>
            <polyline className="fill-darkgray" points="1123.4,173.1 1139,157.5 1123.4,141.9 "/>
            <polyline className="fill-darkgray" points="16.2,142.3 1,157.5 16.2,172.8 "/>
            <g>
                <path className="fill-darkgray" d="M1001.5,76.9c3.3,0,5.9,2.6,5.9,5.9v45.5c0,3.3-2.6,5.9-5.9,5.9H956c-3.3,0-5.9-2.6-5.9-5.9V82.8
		c0-3.3,2.6-5.9,5.9-5.9H1001.5 M1001.5,75.9H956c-3.8,0-6.9,3.1-6.9,6.9v45.5c0,3.8,3.1,6.9,6.9,6.9h45.5c3.8,0,6.9-3.1,6.9-6.9
		V82.8C1008.4,79,1005.3,75.9,1001.5,75.9L1001.5,75.9z"/>
                <text transform="matrix(1 0 0 1 964.0383 113.0625)" className="fill-darkgray st5 st2">WC</text>
            </g>
            <g>
                <path className="fill-darkgray" d="M108.5,76.9c3.3,0,5.9,2.6,5.9,5.9v45.5c0,3.3-2.6,5.9-5.9,5.9H63c-3.3,0-5.9-2.6-5.9-5.9V82.8
		c0-3.3,2.6-5.9,5.9-5.9H108.5 M108.5,75.9H63c-3.8,0-6.9,3.1-6.9,6.9v45.5c0,3.8,3.1,6.9,6.9,6.9h45.5c3.8,0,6.9-3.1,6.9-6.9V82.8
		C115.4,79,112.3,75.9,108.5,75.9L108.5,75.9z"/>
                <g id="_x31_fj75T_4_">
                    <g>
                        <path className="fill-darkgray" d="M96.5,113c-0.1,0.2-0.1,0.5-0.3,0.7c-0.2,0.3-0.4,0.5-0.7,0.7c-0.6,0.3-1.2,0.5-1.9,0.5
				c-0.3,0-0.5,0.1-0.8,0.1c0-5.4,0-10.8,0-16.2c0.2,0,0.4,0,0.6,0.1c0.3,0,0.5,0.1,0.8,0.1c0.7,0.1,1.4,0.4,1.9,1
				c0.2,0.2,0.3,0.5,0.4,0.9C96.5,104.9,96.5,109,96.5,113z"/>
                        <path className="fill-darkgray" d="M75,100.8c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.6-0.7,1-0.9c0.5-0.3,1.1-0.4,1.7-0.4c0.2,0,0.4,0,0.6-0.1
				c0,5.4,0,10.8,0,16.2c-0.2,0-0.4,0-0.6-0.1c-0.5-0.1-1-0.1-1.5-0.3c-0.5-0.3-1-0.6-1.3-1.2c0-0.1-0.1-0.2-0.1-0.3
				C75,109,75,104.9,75,100.8z"/>
                        <path className="fill-darkgray" d="M91.6,115.1c-4,0.5-8,0.5-11.9,0c0-5.5,0-10.9,0-16.4c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.5,0
				s0.2-0.1,0.2-0.2c0-0.4,0.1-0.8,0.1-1.2c0.1-0.8,0.8-1.5,1.6-1.5c0.2,0,0.4,0,0.7,0c1.5,0,3.1,0,4.6,0c0.6,0,1.1,0.2,1.5,0.7
				c0.2,0.2,0.3,0.5,0.3,0.8c0,0.4,0.1,0.9,0.1,1.3c0,0.1,0,0.2,0.2,0.2c0.4,0,0.7,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2
				c0,1.3,0,2.6,0,3.9C91.6,106.9,91.6,111,91.6,115.1C91.6,115,91.6,115.1,91.6,115.1z M88.9,98.4c0-0.4-0.1-0.7-0.1-1.1
				c0-0.3-0.2-0.5-0.6-0.5c-1.7,0-3.4,0-5,0c-0.4,0-0.6,0.2-0.6,0.6c0,0.2,0,0.5-0.1,0.7c0,0.1,0,0.2,0,0.3
				C84.7,98.2,86.8,98.2,88.9,98.4z"/>
                    </g>
                </g>
            </g>
            <g id="G1dcqi_2_">
                <g>
                    <path className="fill-darkgray" d="M115.4,190.8c0,2.6,0,5.3,0,7.9c0,1.7-1.3,3-3,3c-5.3,0-10.6,0-15.8,0c-1.7,0-3-1.3-3-3c0-3.7,0-7.4,0-11
			c0-1.6,0-3.1,0-4.7c0-1.8,1.3-3.1,3.1-3.1c1.8,0,3.7,0,5.5,0c3.4,0,6.8,0,10.2,0c1.4,0,2.6,0.9,2.9,2.3c0,0.2,0.1,0.4,0.1,0.6
			c0,1.6,0,3.2,0,4.7C115.4,188.6,115.4,189.7,115.4,190.8L115.4,190.8z M94.5,190.7L94.5,190.7c0,2.6,0,5.2,0,7.8
			c0,1.2,0.9,2.2,2.2,2.2c5.2,0,10.4,0,15.6,0c1.3,0,2.2-0.9,2.2-2.2c0-3.5,0-7,0-10.5c0-1.7,0-3.5,0-5.2c0-1.3-0.9-2.1-2.2-2.1
			c-4.2,0-8.3,0-12.5,0c-1.1,0-2.1,0-3.2,0c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0,2.4,0,3.6C94.5,187.9,94.5,189.3,94.5,190.7z"/>
                    <path className="fill-darkgray" d="M111.2,190.8c0,3.7-3,6.7-6.6,6.7c-3.7,0.1-6.8-2.9-6.8-6.6c-0.1-3.8,2.9-6.8,6.6-6.9
			C107.9,183.9,111.2,186.8,111.2,190.8z M104,185c-2.5,0.1-5.2,2.2-5.3,5.6c-0.1,3.4,2.6,5.8,5.3,5.9v-0.1c0-0.3,0-0.7,0-1
			s0.2-0.4,0.4-0.5c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.2,0,0.4c0,0.3,0,0.5,0,0.8c1.3,0,3.5-0.8,4.6-2.9c1.1-2.1,1-4.2-0.3-6.1
			c-1-1.5-2.5-2.3-4.3-2.5c0,0.4,0,0.8,0,1.1s-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5C104,185.7,104,185.4,104,185z"/>
                    <path className="fill-darkgray"
                          d="M107.4,189.7c0.6,0,1.1,0.5,1,1c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1.1C106.4,190.2,106.8,189.7,107.4,189.7z"/>
                    <path className="fill-darkgray"
                          d="M100.5,190.8c0-0.6,0.5-1,1-1c0.6,0,1,0.4,1.1,1c0,0.6-0.4,1-1,1C100.9,191.8,100.5,191.3,100.5,190.8z"/>
                </g>
            </g>
            <g id="zwquE5_1_">
                <g>
                    <path className="fill-darkgray" d="M619.7,118.9c-6.5,0-12.9,0-19.4,0c-3.8,0-6.3-3-5.2-6.6c0.4-1.2,1.5-2.4,2.6-3.1c6.1-4,12.4-7.9,18.6-11.8
			c0.8-0.5,1.4-1,1.2-2.1c-0.2-1.9,0.5-3.3,2.2-4.1c0.2-0.1,0.3-0.2,0.5-0.4c0.9-0.5,1.8-1.3,1.2-2.2c-0.4-0.6-1.5-1-2.3-1.1
			c-1.1-0.1-1.8,0.6-2,1.8c-0.1,0.9,0.1,1.9-1.3,2.1c-2.4,0.3-3.1-0.4-2.7-2.7c0.6-3.2,3.1-5.4,6.4-5.3c3.2,0.1,5.9,2.3,6.2,5.3
			c0.2,1.9-0.5,3.5-2,4.7c-0.5,0.4-1,0.6-1.4,1c-1.2,1-1.1,2.1,0.2,2.9c6.4,4,12.8,8,19.2,12c2,1.2,2.9,3,2.7,5.3
			c-0.3,2.7-2.4,4.4-5.4,4.5C632.7,118.9,626.2,118.9,619.7,118.9z M619.8,114.6c6.3,0,12.5,0,18.8,0c0.5,0,1.1-0.4,1.6-0.7
			c-0.3-0.5-0.6-1.1-1-1.4c-6.1-3.9-12.2-7.6-18.2-11.5c-0.9-0.5-1.4-0.5-2.2,0.1c-1.9,1.3-3.9,2.5-5.9,3.7
			c-4.1,2.6-8.3,5.2-12.4,7.8c-0.4,0.3-0.6,0.9-0.8,1.3c0.4,0.2,0.8,0.5,1.3,0.6c0.4,0.1,0.8,0,1.3,0
			C608,114.6,613.9,114.6,619.8,114.6z"/>
                </g>
            </g>
            <g>
                <path className="fill-darkgray" d="M1069.5,76.9c3.3,0,5.9,2.6,5.9,5.9v45.5c0,3.3-2.6,5.9-5.9,5.9H1024c-3.3,0-5.9-2.6-5.9-5.9V82.8
		c0-3.3,2.6-5.9,5.9-5.9H1069.5 M1069.5,75.9H1024c-3.8,0-6.9,3.1-6.9,6.9v45.5c0,3.8,3.1,6.9,6.9,6.9h45.5c3.8,0,6.9-3.1,6.9-6.9
		V82.8C1076.4,79,1073.3,75.9,1069.5,75.9L1069.5,75.9z"/>
                <text transform="matrix(1 0 0 1 1032.0383 113.0625)" className="fill-darkgray st5 st2">WC</text>
            </g>
            <path className="fill-darkgray" d="M838.8,177.7h-63.1v1.4h63.1c6.5,0,11.8,4.7,11.8,10.3v37.7c0,5.7-5.3,10.3-11.8,10.3H679.6
	c-6.5,0-11.8-4.7-11.8-10.3v-37.7c0-5.7,5.3-10.3,11.8-10.3h63v-1.4h-63c-7.5,0-13.6,5.2-13.6,11.9v37.7c0,6.5,6,11.9,13.6,11.9
	h159.3c7.5,0,13.6-5.2,13.6-11.9v-37.7C852.3,183.1,846.3,177.7,838.8,177.7z"/>
            <path className="fill-darkgray" d="M1062.8,177.7h-59.9h-3.2H870.8c-7.5,0-13.6,5.2-13.6,11.9v37.7c0,6.5,6,11.9,13.6,11.9h192
	c7.5,0,13.6-5.2,13.6-11.9v-37.7C1076.2,183.1,1070.2,177.7,1062.8,177.7z M1074.6,227.2c0,5.7-5.3,10.3-11.8,10.3h-192
	c-6.5,0-11.8-4.7-11.8-10.3v-37.7c0-5.7,5.3-10.3,11.8-10.3h128.8h3.2h59.9c6.5,0,11.8,4.7,11.8,10.3V227.2z"/>
            <path className="fill-darkgray" d="M838.8,75.9H679.6c-7.5,0-13.6,5.2-13.6,11.9v37.7c0,6.5,6,11.9,13.6,11.9h63v-1.7h-63
	c-6.5,0-11.8-4.7-11.8-10.3V87.6c0-5.7,5.3-10.3,11.8-10.3h159.3c6.5,0,11.8,4.7,11.8,10.3v37.7c0,5.7-5.3,10.3-11.8,10.3h-63.1v1.7
	h63.1c7.5,0,13.6-5.2,13.6-11.9V87.8C852.3,81.3,846.3,75.9,838.8,75.9z"/>
            <path className="fill-darkgray" d="M931.1,75.9h-60.3c-7.5,0-13.6,5.2-13.6,11.9v37.7c0,5.7,4.5,10.5,10.8,11.6v0.3h2.9h60.3h2.8v-0.2
	c6.2-1.1,10.9-5.8,10.9-11.6V87.8C944.6,81.3,938.6,75.9,931.1,75.9z M942.9,125.4c0,5.7-5.3,10.3-11.8,10.3h-60.3
	c-6.5,0-11.8-4.7-11.8-10.3V87.6c0-5.7,5.3-10.3,11.8-10.3h60.3c6.5,0,11.8,4.7,11.8,10.3V125.4z"/>
            <g className={(availableSeats.hasOwnProperty('41') ?
                (selectedSeats.includes('41') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('41')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('41')}>
                <g>
                    <path className="train-seat__dash" d="M585.5,210.4h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M585.5,210c-3.1,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H585.5L585.5,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M609,237.7v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C609.6,239.1,608.9,238.4,609,237.7z"/>
                </g>
                <text transform="matrix(1 0 0 1 584.399 230.6826)" className="train-seat__text">41</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('39') ?
                (selectedSeats.includes('39') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('39')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('39')}>
                <g>
                    <path className="train-seat__dash" d="M545.1,210.4h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M545.1,210c-3.2,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H545.1L545.1,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M568.5,237.7v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C569.1,239.1,568.5,238.4,568.5,237.7z"/>
                </g>
                <text transform="matrix(1 0 0 1 543.999 230.6826)" className="train-seat__text">39</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('35') ?
                (selectedSeats.includes('35') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('35')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('35')}>
                <g>
                    <path className="train-seat__dash" d="M504.6,210.4h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M504.6,210c-3.2,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H504.6L504.6,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M528,237.7v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C528.6,239.1,528,238.4,528,237.7z"/>
                </g>
                <text transform="matrix(1 0 0 1 503.514 230.6826)" className="train-seat__text">35</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('42') ?
                (selectedSeats.includes('42') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('42')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('42')}>
                <g>
                    <path className="train-seat__dash" d="M585.5,177.5h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M585.5,177.1c-3.1,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H585.5L585.5,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M609,204.8v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C609.6,206.2,608.9,205.5,609,204.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 584.3992 197.7891)" className="train-seat__text">42</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('43') ?
                (selectedSeats.includes('43') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('43')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('43')}>
                <g>
                    <path className="train-seat__dash" d="M626,210.4h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6H626c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M626,210c-3.1,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H626L626,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M649.5,237.7v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C650.1,239.1,649.4,238.4,649.5,237.7z"/>
                </g>
                <text transform="matrix(1 0 0 1 624.899 230.6826)" className="train-seat__text">43</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('51') ?
                (selectedSeats.includes('51') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('51')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('51')}>
                <g>
                    <path className="train-seat__dash" d="M804.1,200.4h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M804.1,200c-3.1,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H804.1L804.1,200z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M827.6,227.7v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C828.2,229.1,827.5,228.4,827.6,227.7z"/>
                </g>
                <text transform="matrix(1 0 0 1 802.9564 220.6826)" className="train-seat__text">51</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('44') ?
                (selectedSeats.includes('44') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('44')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('44')}>
                <g>
                    <path className="train-seat__dash" d="M626,177.5h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6H626c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M626,177.1c-3.1,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H626L626,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M649.5,204.8v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C650.1,206.2,649.4,205.5,649.5,204.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 624.8992 197.7891)" className="train-seat__text">44</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('40') ?
                (selectedSeats.includes('40') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('40')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('40')}>
                <g>
                    <path className="train-seat__dash" d="M545.1,177.5h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M545.1,177.1c-3.2,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H545.1L545.1,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M568.5,204.8v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C569.1,206.2,568.5,205.5,568.5,204.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 543.9993 197.7891)" className="train-seat__text">40</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('36') ?
                (selectedSeats.includes('36') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('36')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('36')}>
                <g>
                    <path className="train-seat__dash" d="M504.6,177.5h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M504.6,177.1c-3.2,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H504.6L504.6,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M528,204.8v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C528.6,206.2,528,205.5,528,204.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 503.514 197.7891)" className="train-seat__text">36</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('38') ?
                (selectedSeats.includes('38') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('38')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('38')}>
                <g>
                    <path className="train-seat__dash" d="M545.1,109.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2v-17.5
			c0-2.8,2.3-5.2,5.2-5.2 M545.1,108.8c-3.2,0-5.7,2.6-5.8,5.7V132c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8
			c0-1.7-1.4-3.1-3.1-3.1H545.1L545.1,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M568.5,136.5v-26.2c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C569.1,137.9,568.5,137.2,568.5,136.5z"/>
                </g>
                <text transform="matrix(1 0 0 1 543.9993 129.4697)" className="train-seat__text">38</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('34') ?
                (selectedSeats.includes('34') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('34')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('34')}>
                <g>
                    <path className="train-seat__dash" d="M504.6,137.4c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
			c0,1.4-1.2,2.6-2.6,2.6h-20.2 M504.6,137.9h20.2c1.7,0,3.1-1.4,3.1-3.1V112c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
			v17.5C498.9,135.3,501.4,137.9,504.6,137.9L504.6,137.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M529.4,108.8L529.4,108.8c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C528,109.5,528.6,108.8,529.4,108.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 503.499 129.4697)" className="train-seat__text">34</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('37') ?
                (selectedSeats.includes('37') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('37')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('37')}>
                <g>
                    <path className="train-seat__dash" d="M545.1,76.4h20.2c1.4,0,2.6,1.2,2.6,2.6v22.7c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2V81.6
			C539.9,78.8,542.2,76.4,545.1,76.4 M545.1,75.9c-3.2,0-5.7,2.6-5.8,5.8v17.4c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1V79
			c0-1.7-1.4-3.1-3.1-3.1H545.1L545.1,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M568.5,103.6V77.4c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C569.1,105,568.5,104.3,568.5,103.6z"/>
                </g>
                <text transform="matrix(1 0 0 1 543.9991 96.5762)" className="train-seat__text">37</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('33') ?
                (selectedSeats.includes('33') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('33')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('33')}>
                <g>
                    <path className="train-seat__dash" d="M504.6,104.5c-2.9,0-5.2-2.4-5.2-5.2V81.8c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.7
			c0,1.4-1.2,2.6-2.6,2.6H504.6 M504.6,105h20.2c1.7,0,3.1-1.4,3.1-3.1V79.1c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
			v17.4C498.9,102.4,501.4,105,504.6,105L504.6,105z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M529.4,75.9L529.4,75.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V77.3
			C528,76.6,528.6,75.9,529.4,75.9z"/>
                </g>
                <text transform="matrix(1 0 0 1 503.499 96.5762)" className="train-seat__text">33</text>
            </g>
            <path className="fill-darkgray" d="M351.6,181.1c7.4,0,13.3,6,13.3,13.3v43.7h-26.7v-43.7C338.2,187,344.2,181.1,351.6,181.1 M351.6,180.1
	L351.6,180.1c-7.9,0-14.4,6.4-14.4,14.4v44.7h28.7v-44.7C365.9,186.5,359.5,180.1,351.6,180.1L351.6,180.1z"/>
            <path className="fill-darkgray" d="M364.9,76.9v43.7c0,7.4-6,13.3-13.3,13.3c-7.4,0-13.3-6-13.3-13.3V76.9H364.9 M365.9,75.9h-28.7v44.7
	c0,7.9,6.4,14.4,14.4,14.4l0,0c7.9,0,14.4-6.4,14.4-14.4L365.9,75.9L365.9,75.9z"/>
            <path className="fill-darkgray" d="M759.2,201.3c7.4,0,13.3,6,13.3,13.3v23.5h-26.7v-23.5C745.8,207.3,751.8,201.3,759.2,201.3 M759.2,200.3
	L759.2,200.3c-7.9,0-14.4,6.4-14.4,14.4v24.5h28.7v-24.5C773.5,206.7,767.1,200.3,759.2,200.3L759.2,200.3z"/>
            <path className="fill-darkgray" d="M759.1,113.8c-7.4,0-13.3-6-13.3-13.3V77h26.7v23.5C772.5,107.8,766.5,113.8,759.1,113.8 M759.1,114.8
	L759.1,114.8c7.9,0,14.4-6.4,14.4-14.4V75.9h-28.7v24.5C744.8,108.4,751.2,114.8,759.1,114.8L759.1,114.8z"/>
            <g className={(availableSeats.hasOwnProperty('21') ?
                (selectedSeats.includes('21') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('21')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('21')}>
                <g>
                    <path className="train-seat__dash" d="M319.8,210.5c2.9,0,5.2,2.4,5.2,5.2v17.4c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V213
			c0-1.4,1.2-2.6,2.6-2.6L319.8,210.5 M319.8,210h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.7c0,1.7,1.4,3.1,3.1,3.1h20.2
			c3.2,0,5.8-2.6,5.8-5.8v-17.4C325.5,212.6,323,210,319.8,210L319.8,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M295,239L295,239c-2,0-3.5-1.6-3.5-3.5v-21.9c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.1
			C296.4,238.3,295.8,239,295,239z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 301.3723 230.6826)" className="train-seat__text">21</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('49') ?
                (selectedSeats.includes('49') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('49')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('49')}>
                <g>
                    <path className="train-seat__dash" d="M714.4,200.5c2.9,0,5.2,2.4,5.2,5.2v17.4c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V203
			c0-1.4,1.2-2.6,2.6-2.6L714.4,200.5 M714.4,200h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.7c0,1.7,1.4,3.1,3.1,3.1h20.2
			c3.2,0,5.8-2.6,5.8-5.8v-17.4C720.1,202.6,717.6,200,714.4,200L714.4,200z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M689.6,229L689.6,229c-2,0-3.5-1.6-3.5-3.5v-21.9c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.1
			C691,228.3,690.4,229,689.6,229z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 695.9724 220.6826)" className="train-seat__text">49</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('47') ?
                (selectedSeats.includes('47') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('47')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('47')}>
                <g>
                    <path className="train-seat__dash" d="M804.1,86.3h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.8,0-5.2-2.3-5.2-5.2V91.6
			c0-2.8,2.3-5.2,5.2-5.2 M804.1,85.9c-3.1,0-5.7,2.6-5.8,5.7v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1V89.1
			c0-1.7-1.4-3.1-3.1-3.1H804.1L804.1,85.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M827.6,113.6V87.4c0-0.8,0.6-1.4,1.4-1.4l0,0c1.9,0,3.5,1.5,3.5,3.5v22c0,1.9-1.5,3.5-3.5,3.5l0,0
			C828.2,115.1,827.5,114.3,827.6,113.6z"/>
                </g>
                <text transform="matrix(1 0 0 1 802.9564 106.6326)" className="train-seat__text">47</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('45') ?
                (selectedSeats.includes('45') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('45')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('45')}>
                <g>
                    <path className="train-seat__dash" d="M714.4,86.4c2.9,0,5.2,2.4,5.2,5.2v17.4c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V88.9
			c0-1.4,1.2-2.6,2.6-2.6L714.4,86.4 M714.4,85.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.7c0,1.7,1.4,3.1,3.1,3.1h20.2
			c3.2,0,5.8-2.6,5.8-5.8V91.6C720.1,88.5,717.6,85.9,714.4,85.9L714.4,85.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M689.6,114.9L689.6,114.9c-2,0-3.5-1.6-3.5-3.5V89.5c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.1
			C691,114.3,690.4,114.9,689.6,114.9z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 695.9724 106.6326)" className="train-seat__text">45</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('15') ?
                (selectedSeats.includes('15') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('15')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('15')}>
                <g>
                    <path className="train-seat__dash" d="M279.3,210.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V213
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M279.3,210h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C285,212.6,282.5,210,279.3,210L279.3,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M254.5,239.1L254.5,239.1c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C255.9,238.4,255.3,239.1,254.5,239.1z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 260.887 230.6826)" className="train-seat__text">15</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('11') ?
                (selectedSeats.includes('11') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('11')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('11')}>
                <g>
                    <path className="train-seat__dash" d="M238.8,210.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V213
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M238.8,210h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C244.6,212.6,242,210,238.8,210L238.8,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M214,239.1L214,239.1c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C215.4,238.4,214.8,239.1,214,239.1z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 220.4016 230.6826)" className="train-seat__text">11</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('07') ?
                (selectedSeats.includes('07') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('07')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('07')}>
                <g>
                    <path className="train-seat__dash" d="M198.3,210.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V213
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M198.3,210h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C204.1,212.6,201.5,210,198.3,210L198.3,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M173.5,239.1L173.5,239.1c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C175,238.4,174.3,239.1,173.5,239.1z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 179.9163 230.6826)" className="train-seat__text">07</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('03') ?
                (selectedSeats.includes('03') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('03')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('03')}>
                <g>
                    <path className="train-seat__dash" d="M157.8,210.5c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V213
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M157.8,210h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C163.6,212.6,161,210,157.8,210L157.8,210z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M133,239.1L133,239.1c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C134.5,238.4,133.8,239.1,133,239.1z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 139.4309 230.6826)" className="train-seat__text">03</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('22') ?
                (selectedSeats.includes('22') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('22')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('22')}>
                <g>
                    <path className="train-seat__dash" d="M319.8,177.6c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M319.8,177.1h-20.2c-1.7,0-3.1,1.4-3.1,3.1V203c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C325.5,179.7,323,177.1,319.8,177.1L319.8,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M295,206.2L295,206.2c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C296.4,205.5,295.8,206.2,295,206.2z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 301.3723 197.7891)" className="train-seat__text">22</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('16') ?
                (selectedSeats.includes('16') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('16')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('16')}>
                <g>
                    <path className="train-seat__dash" d="M279.3,177.6c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M279.3,177.1h-20.2c-1.7,0-3.1,1.4-3.1,3.1V203c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C285,179.7,282.5,177.1,279.3,177.1L279.3,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M254.5,206.2L254.5,206.2c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C255.9,205.5,255.3,206.2,254.5,206.2z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 260.887 197.7891)" className="train-seat__text">16</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('12') ?
                (selectedSeats.includes('12') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('12')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('12')}>
                <g>
                    <path className="train-seat__dash" d="M238.8,177.6c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M238.8,177.1h-20.2c-1.7,0-3.1,1.4-3.1,3.1V203c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C244.6,179.7,242,177.1,238.8,177.1L238.8,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M214,206.2L214,206.2c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C215.4,205.5,214.8,206.2,214,206.2z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 220.4016 197.7891)" className="train-seat__text">12</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('08') ?
                (selectedSeats.includes('08') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('08')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('08')}>
                <g>
                    <path className="train-seat__dash" d="M198.3,177.6c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M198.3,177.1h-20.2c-1.7,0-3.1,1.4-3.1,3.1V203c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C204.1,179.7,201.5,177.1,198.3,177.1L198.3,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M173.5,206.2L173.5,206.2c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C175,205.5,174.3,206.2,173.5,206.2z"/>
                </g>
                <text transform="matrix(1 0 0 1 179.9163 197.7891)" className="train-seat__text">08</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('04') ?
                (selectedSeats.includes('04') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('04')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('04')}>
                <g>
                    <path className="train-seat__dash" d="M157.8,177.6c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M157.8,177.1h-20.2c-1.7,0-3.1,1.4-3.1,3.1V203c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C163.6,179.7,161,177.1,157.8,177.1L157.8,177.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M133,206.2L133,206.2c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C134.5,205.5,133.8,206.2,133,206.2z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 139.4309 197.7891)" className="train-seat__text">04</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('18') ?
                (selectedSeats.includes('18') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('18')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('18')}>
                <g>
                    <path className="train-seat__dash" d="M319.8,109.3c2.9,0,5.2,2.4,5.2,5.2V132c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M319.8,108.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C325.5,111.4,323,108.8,319.8,108.8L319.8,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M295,137.9L295,137.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C296.4,137.2,295.8,137.9,295,137.9z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 301.3723 129.4697)" className="train-seat__text">18</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('14') ?
                (selectedSeats.includes('14') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('14')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('14')}>
                <g>
                    <path className="train-seat__dash" d="M279.3,109.3c2.9,0,5.2,2.4,5.2,5.2V132c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M279.3,108.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C285,111.4,282.5,108.8,279.3,108.8L279.3,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M254.5,137.9L254.5,137.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C255.9,137.2,255.3,137.9,254.5,137.9z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 260.887 129.4697)" className="train-seat__text">14</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('10') ?
                (selectedSeats.includes('10') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('10')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('10')}>
                <g>
                    <path className="train-seat__dash" d="M238.8,109.3c2.9,0,5.2,2.4,5.2,5.2V132c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M238.8,108.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C244.6,111.4,242,108.8,238.8,108.8L238.8,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M214,137.9L214,137.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C215.4,137.2,214.8,137.9,214,137.9z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 220.4016 129.4697)" className="train-seat__text">10</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('06') ?
                (selectedSeats.includes('06') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('06')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('06')}>
                <g>
                    <path className="train-seat__dash" d="M198.3,109.3c2.9,0,5.2,2.4,5.2,5.2V132c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M198.3,108.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C204.1,111.4,201.5,108.8,198.3,108.8L198.3,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M173.5,137.9L173.5,137.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C175,137.2,174.3,137.9,173.5,137.9z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 179.9163 129.4697)" className="train-seat__text">06</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('02') ?
                (selectedSeats.includes('02') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('02')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('02')}>
                <g>
                    <path className="train-seat__dash" d="M157.8,109.3c2.9,0,5.2,2.4,5.2,5.2V132c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8
			c0-1.4,1.2-2.6,2.6-2.6h20.2 M157.8,108.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			v-17.5C163.6,111.4,161,108.8,157.8,108.8L157.8,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M133,137.9L133,137.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C134.5,137.2,133.8,137.9,133,137.9z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 139.4309 129.4697)" className="train-seat__text">02</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('17') ?
                (selectedSeats.includes('17') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('17')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('17')}>
                <g>
                    <path className="train-seat__dash" d="M319.8,76.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V79
			c0-1.4,1.2-2.6,2.6-2.6H319.8 M319.8,75.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			V81.7C325.5,78.5,323,75.9,319.8,75.9L319.8,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M295,105L295,105c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C296.4,104.3,295.8,105,295,105z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 301.3723 96.5762)" className="train-seat__text">17</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('13') ?
                (selectedSeats.includes('13') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('13')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('13')}>
                <g>
                    <path className="train-seat__dash" d="M279.3,76.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V79
			c0-1.4,1.2-2.6,2.6-2.6H279.3 M279.3,75.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			V81.7C285,78.5,282.5,75.9,279.3,75.9L279.3,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M254.5,105L254.5,105c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C255.9,104.3,255.3,105,254.5,105z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 260.887 96.5762)" className="train-seat__text">13</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('09') ?
                (selectedSeats.includes('09') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('09')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('09')}>
                <g>
                    <path className="train-seat__dash" d="M238.8,76.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V79
			c0-1.4,1.2-2.6,2.6-2.6H238.8 M238.8,75.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			V81.7C244.6,78.5,242,75.9,238.8,75.9L238.8,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M214,105L214,105c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C215.4,104.3,214.8,105,214,105z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 220.4016 96.5762)" className="train-seat__text">09</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('05') ?
                (selectedSeats.includes('05') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('05')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('05')}>
                <g>
                    <path className="train-seat__dash" d="M198.3,76.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V79
			c0-1.4,1.2-2.6,2.6-2.6H198.3 M198.3,75.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			V81.7C204.1,78.5,201.5,75.9,198.3,75.9L198.3,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M173.5,105L173.5,105c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C175,104.3,174.3,105,173.5,105z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 179.9163 96.5762)" className="train-seat__text">05</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('01') ?
                (selectedSeats.includes('01') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('01')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('01')}>
                <g>
                    <path className="train-seat__dash" d="M157.8,76.4c2.9,0,5.2,2.4,5.2,5.2v17.5c0,2.9-2.4,5.2-5.2,5.2h-20.2c-1.4,0-2.6-1.2-2.6-2.6V79
			c0-1.4,1.2-2.6,2.6-2.6H157.8 M157.8,75.9h-20.2c-1.7,0-3.1,1.4-3.1,3.1v22.8c0,1.7,1.4,3.1,3.1,3.1h20.2c3.2,0,5.8-2.6,5.8-5.8
			V81.7C163.6,78.5,161,75.9,157.8,75.9L157.8,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M133,105L133,105c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
			C134.5,104.3,133.8,105,133,105z"/>
                </g>
                <g>
                    <text transform="matrix(1 0 0 1 139.4309 96.5762)" className="train-seat__text">01</text>
                </g>
            </g>
            <g className={(availableSeats.hasOwnProperty('31') ?
                (selectedSeats.includes('31') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('31')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('31')}>
                <g>
                    <path className="train-seat__dash" d="M483,210.6c1.4,0,2.6,1.2,2.6,2.6V236c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
			c0-2.9,2.4-5.2,5.2-5.2H483 M483,210.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H483c1.7,0,3.1-1.4,3.1-3.1v-22.8
			C486.1,211.4,484.7,210.1,483,210.1L483,210.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M487.6,210L487.6,210c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C486.1,210.7,486.8,210,487.6,210z"/>
                </g>
                <text transform="matrix(1 0 0 1 461.6991 230.6826)" className="train-seat__text">31</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('27') ?
                (selectedSeats.includes('27') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('27')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('27')}>
                <g>
                    <path className="train-seat__dash" d="M442.5,210.6c1.4,0,2.6,1.2,2.6,2.6V236c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
			c0-2.9,2.4-5.2,5.2-5.2h20.2 M442.5,210.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
			v-22.8C445.6,211.4,444.2,210.1,442.5,210.1L442.5,210.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M447.1,210L447.1,210c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C445.6,210.7,446.3,210,447.1,210z"/>
                </g>
                <text transform="matrix(1 0 0 1 421.554 230.6826)" className="train-seat__text">27</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('23') ?
                (selectedSeats.includes('23') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('23')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('23')}>
                <g>
                    <path className="train-seat__dash" d="M402,210.6c1.4,0,2.6,1.2,2.6,2.6V236c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
			c0-2.9,2.4-5.2,5.2-5.2H402 M402,210.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H402c1.7,0,3.1-1.4,3.1-3.1v-22.8
			C405.1,211.4,403.7,210.1,402,210.1L402,210.1z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M406.6,210L406.6,210c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C405.2,210.7,405.8,210,406.6,210z"/>
                </g>
                <text transform="matrix(1 0 0 1 381.0696 230.6826)" className="train-seat__text">23</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('32') ?
                (selectedSeats.includes('32') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('32')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('32')}>
                <g>
                    <path className="train-seat__dash" d="M483,177.7c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V183
			c0-2.9,2.4-5.2,5.2-5.2H483 M483,177.2h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H483c1.7,0,3.1-1.4,3.1-3.1v-22.8
			C486.1,178.6,484.7,177.2,483,177.2L483,177.2z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M487.6,177.1L487.6,177.1c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C486.1,177.8,486.8,177.1,487.6,177.1z"/>
                </g>
                <text transform="matrix(1 0 0 1 461.6816 197.7891)" className="train-seat__text">32</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('28') ?
                (selectedSeats.includes('28') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('28')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('28')}>
                <g>
                    <path className="train-seat__dash" d="M442.5,177.7c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V183
			c0-2.9,2.4-5.2,5.2-5.2h20.2 M442.5,177.2h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
			v-22.8C445.6,178.6,444.2,177.2,442.5,177.2L442.5,177.2z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M447.1,177.1L447.1,177.1c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C445.6,177.8,446.3,177.1,447.1,177.1z"/>
                </g>
                <text transform="matrix(1 0 0 1 421.554 197.7891)" className="train-seat__text">28</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('24') ?
                (selectedSeats.includes('24') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('24')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('24')}>
                <g>
                    <path className="train-seat__dash" d="M402,177.7c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V183
			c0-2.9,2.4-5.2,5.2-5.2H402 M402,177.2h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H402c1.7,0,3.1-1.4,3.1-3.1v-22.8
			C405.1,178.6,403.7,177.2,402,177.2L402,177.2z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M406.6,177.1L406.6,177.1c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C405.2,177.8,405.8,177.1,406.6,177.1z"/>
                </g>
                <text transform="matrix(1 0 0 1 381.0696 197.7891)" className="train-seat__text">24</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('30') ?
                (selectedSeats.includes('30') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('30')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('30')}>
                <g>
                    <path className="train-seat__dash" d="M483,109.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
			c0-2.9,2.4-5.2,5.2-5.2H483 M483,108.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H483c1.7,0,3.1-1.4,3.1-3.1V112
			C486.1,110.2,484.7,108.8,483,108.8L483,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M487.6,108.8L487.6,108.8c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C486.1,109.4,486.8,108.8,487.6,108.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 462.0393 129.4697)" className="train-seat__text">30</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('26') ?
                (selectedSeats.includes('26') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('26')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('26')}>
                <g>
                    <path className="train-seat__dash" d="M442.5,109.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
			c0-2.9,2.4-5.2,5.2-5.2h20.2 M442.5,108.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
			V112C445.6,110.2,444.2,108.8,442.5,108.8L442.5,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M447.1,108.8L447.1,108.8c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C445.6,109.4,446.3,108.8,447.1,108.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 421.554 129.4697)" className="train-seat__text">26</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('20') ?
                (selectedSeats.includes('20') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('20')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('20')}>
                <g>
                    <path className="train-seat__dash" d="M402,109.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
			c0-2.9,2.4-5.2,5.2-5.2H402 M402,108.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H402c1.7,0,3.1-1.4,3.1-3.1V112
			C405.1,110.2,403.7,108.8,402,108.8L402,108.8z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M406.6,108.8L406.6,108.8c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
			C405.2,109.4,405.8,108.8,406.6,108.8z"/>
                </g>
                <text transform="matrix(1 0 0 1 381.0696 129.4697)" className="train-seat__text">20</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('29') ?
                (selectedSeats.includes('29') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('29')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('29')}>
                <g>
                    <path className="train-seat__dash" d="M483,76.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V81.7
			c0-2.9,2.4-5.2,5.2-5.2H483 M483,75.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H483c1.7,0,3.1-1.4,3.1-3.1V79.1
			C486.1,77.3,484.7,75.9,483,75.9L483,75.9z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M487.6,75.9L487.6,75.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V77.3
			C486.1,76.5,486.8,75.9,487.6,75.9z"/>
                </g>
                <text transform="matrix(1 0 0 1 462.0393 96.5762)" className="train-seat__text">29</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('25') ?
                (selectedSeats.includes('25') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('25')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('25')}>
                <g>
                    <path className="train-seat__dash" d="M442.5,76.6c1.4,0,2.6,1.2,2.6,2.6V102c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V81.8
			c0-2.9,2.4-5.2,5.2-5.2H442.5 M442.5,76h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
			V79.2C445.6,77.4,444.2,76,442.5,76L442.5,76z"/>
                </g>
                <g>
                    <path className="train-seat__dash" d="M447.1,76L447.1,76c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V77.4
			C445.6,76.6,446.3,76,447.1,76z"/>
                </g>
                <text transform="matrix(1 0 0 1 421.554 96.5762)" className="train-seat__text">25</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('19') ?
                (selectedSeats.includes('19') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('19')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('19')}>
                    <path className="train-seat__dash" d="M402,76.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V81.7
			c0-2.9,2.4-5.2,5.2-5.2H402 M402,75.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8H402c1.7,0,3.1-1.4,3.1-3.1V79.1
			C405.1,77.3,403.7,75.9,402,75.9L402,75.9z"/>
                    <path className="train-seat__dash" d="M406.6,75.9L406.6,75.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V77.3
			C405.2,76.5,405.8,75.9,406.6,75.9z"/>
                <text transform="matrix(1 0 0 1 381.0696 96.5762)" className="train-seat__text">19</text>
            </g>
        </svg>
    );
}
