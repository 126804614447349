import React, {Fragment, PureComponent} from 'react';
import Navigation from './../../containers/Navigation/Cabinet';
import Timer from './../Timer';
import {dateFormatter, getTariffName} from '../Helpers';
import {Link} from 'react-router-dom';
import withRouter from "react-router-dom/es/withRouter";

class Bag extends PureComponent {
    constructor(props) {
        super(props);
        props.getBag()
    }

    render() {
        const {bag, msgError, cities, locale} = this.props;
        const key = Math.random();
        // console.log(bag.reservations[0]?.order_data?.order_id)
        return (<Fragment>
                <Navigation title="Корзина"/>

                <div className="cart-header">
                    <div className="container">
                        <h3>Список оформленных билетов</h3>
                    </div>
                </div>

                <main className="main bag-view-wrapper">
                    {bag.reservations.map(reservation => (

                        <div className="inner-page cart-page" key={reservation.order_data.id + '' + key}>
                            <Link to={'#'} className="checked-link"
                                  onClick={() => this.props.reservationRemoveNew(reservation.order_data.id)}>
                                <span className="plus">+</span>
                            </Link>
                            {reservation.tickets.map((ticket, i) => (
                                <div className="form">
                                    <div className="form__content d-flex">
                                        <div className="rais">
                                            <div className="title-top d-none d-sm-block">Рейс
                                                {reservation.tickets.length > 1 ?
                                                    i === 0 ? ' - ТУДА' : ' - ОБРАТНО'
                                                    : null}
                                            </div>
                                            <div
                                                className="title-train">Поезд {ticket.ticket_data.train_number} &laquo;Сапсан&raquo;</div>
                                        </div>
                                        <div className="count-ticket d-flex d-column text-center">
                                            <div className="title-top">
                                                Количество билетов
                                            </div>
                                            <div className="count-ticket__item">{ticket.pasangers.length}</div>
                                        </div>
                                        <div className="price">
                                            <div className="title-top">
                                                Стоимость
                                            </div>
                                            <div className="price__item">
                                                {parseInt(ticket.ticket_data.amount)} руб.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-tawn">
                                        <div>
                                            <div>
                                                {cities.find(el => el.value === ticket.ticket_data.destination_from).lable}
                                            </div>
                                            <div
                                                className="text">Отправление: {dateFormatter(ticket.ticket_data.time_from, true)}</div>
                                        </div>
                                        <span className="icon-item">
                                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                        </span>
                                        <div>
                                            <div>{cities.find(el => el.value === ticket.ticket_data.destination_to).lable}</div>
                                            <div
                                                className="text">Прибытие: {dateFormatter(ticket.ticket_data.time_to, true)}</div>
                                        </div>
                                    </div>
                                    <div className="more-info active">
                                        {ticket.pasangers.map((pasanger,
                                                               j) => (
                                            <Fragment>
                                                {ticket.pasangers.length === 1
                                                &&
                                                Object.keys(locale).length >= 1
                                                    ?
                                                    (
                                                        i === 0 ?
                                                            (
                                                                locale &&
                                                                locale[reservation.order_data.global_order_id] &&
                                                                locale[reservation.order_data.global_order_id].forwardData.selectedStart ===
                                                                locale[reservation.order_data.global_order_id].forwardData.selectedEnd ?
                                                                    (
                                                                        locale[reservation.order_data.global_order_id].forwardData.selectedStart ===
                                                                        parseInt(ticket.pasangers[j].places) ? null : (
                                                                            <p className='error-block m-2 p-2'>Из-за
                                                                                особого режима
                                                                                оформления, было изменено место и
                                                                                стоимость.</p>)
                                                                    ) : null
                                                            ) :
                                                            (
                                                                locale[reservation.order_data.global_order_id].returnData.selectedStart ===
                                                                locale[reservation.order_data.global_order_id].returnData.selectedEnd ?
                                                                    (
                                                                        locale[reservation.order_data.global_order_id].returnData.selectedStart ===
                                                                        parseInt(ticket.pasangers[j].places) ? null : (
                                                                            <p className='error-block m-2 p-2'>Из-за
                                                                                особого режима
                                                                                оформления, было изменено место и
                                                                                стоимость.</p>)
                                                                    ) : null
                                                            )
                                                    ) : null}
                                                <div className="passengers-box">
                                                    <div className="passengers-wrapper">
                                                        <div className="passengers-box__content">
                                                            <div className='d-flex passenger-info j-content-between'>
                                                                <div className='title'>Пассажир №{j + 1}</div>
                                                                <div className='name'>{pasanger.fio}</div>

                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex flex-column passenger-info'>
                                                                    <div>Документ {pasanger.document_number},</div>
                                                                    <div>пол {pasanger.sex === 'Male' ? 'мужской' : 'женский'},</div>
                                                                    <div>дата
                                                                        рождения {dateFormatter(pasanger.birth_date)},
                                                                    </div>
                                                                    <div>Сервисный&nbsp;сбор&nbsp;
                                                                        <strong>{parseInt(pasanger.amount) - parseInt(pasanger.base_price)}</strong>&nbsp;руб.
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex flex-column passenger-info'>
                                                                    <div
                                                                        data-tariff={pasanger.tariff_type}>тариф {getTariffName(pasanger.tariff_type)}</div>
                                                                    <div>вагон {ticket.ticket_data.car},</div>
                                                                    <div>место {pasanger.places},</div>
                                                                    <div>Стоимость&nbsp;билета&nbsp;
                                                                        <strong>{parseInt(pasanger.base_price)}</strong>&nbsp;руб.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Fragment>
                                        ))}

                                    </div>
                                </div>
                            ))}
                            <div className="btn-wrap d-flex j-content-between align-items-center flexibleAdaptive">
                                <div className="col-left">
                                    {locale[reservation.order_data.global_order_id] &&
                                    <div className="btn btn__white btn__icon"
                                         onClick={() => this.stepBack(reservation.order_data)}>
                                        <span className="icon">
                                                <img src="img/icons/back-arrow-svgrepo-com.svg" alt="img item"/>
                                              </span>
                                        Назад

                                    </div>}</div>
                                {reservation.order_data.confirm_till - parseInt(new Date().getTime() / 1000) > 0 ?
                                    <div className="col-right flexibleBron">
                                        <span className="text">Бронирование будет снято автоматически через:</span>
                                        <div className="btn btn__white btn__icon">
                                              <span className="icon">
                                                <img src="img/icons/time-icon.svg" alt="img item"/>
                                              </span>
                                            <Timer
                                                deadline={reservation.order_data.confirm_till}
                                                getBag={() => this.props.getBag()}
                                            />
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    ))}
                    {msgError !== '' ?
                        (<div className={msgError !== '' ? 'fadeIn container-error' : 'fadeOut container-error'}>
                            <div className='error-block'>
                                {msgError}
                            </div>
                        </div>) : null
                    }
                    <div className="summ-price d-flex j-content-between align-items-center flexibleButtonPay">
                        <div className="text textflexible">Всего к оплате:</div>
                        <div className="price">
                            {parseInt(bag.amount)} руб.
                        </div>
                        <div className='wrapperButtonPay'>
                            <button
                                onClick={()=>this.props.orderPaymentCredit(bag.orderId)}
                                className="btn btn__main px-3">Оплатить в кредит</button>

                            <button className="btn btn__main px-3"
                                    onClick={() => this.props.orderPaymentNew(bag.orderId)}>Оплатить</button>
                        </div>
                    </div>
                </main>
            </Fragment>
        );
    }

    stepBack = (order_data) => {
        const {global_order_id, id} = order_data;
        this.props.getStorage(global_order_id)
        this.props.reservationRemoveNew(id)
        this.props.history.goBack();
        return false;
    }
}

export default withRouter(Bag);