import * as types from './../constants/ActionTypesBag';
import axiosDispatch from './../axios/dispatch';
import {appError} from './App';
import {getDate} from './../utility/time';
import {fetchList as bookingFetch} from './Booking';
import {getReservations, getTasks} from './User';

export function getList() {
    return (dispatch,
            getState) => {
        axiosDispatch(dispatch, '/bag')
            .then(data => {
                dispatch(fetchList(data));
                if (data.isArchive || data.state === 'success') localStorage.removeItem('orderId');
            })
            .catch(error => {
                dispatch(appError(error))
            });
    }
}

export function createNew(params) {
    return (dispatch) => {
        return new Promise((resolve,
                            reject) => {

            axiosDispatch(dispatch, '/bag/reservationCreateNew', params)
                .then(data => {
                    if (data.Message === undefined) {
                        dispatch(getList());
                        resolve(data.response.OrderId);
                    }
                    else {
                        reject(data.Message);
                    }
                })
                .catch(error => {
                    reject(error)
                });
        });
    }
}

export function create(params) {
    return (dispatch,
            getState) => {
        return new Promise((resolve,
                            reject) => {
            const state = getState();

            params.date = getDate(params.date, {});
            params.orderId = state.bag.orderId;

            axiosDispatch(dispatch, '/bag/reservationCreate', params)
                .then(data => {
                    // dispatch(setOrderId(data.orderId));
                    //
                    if (data.error === undefined) {
                        if (Object.keys(data.reservationsFailed).length === 0) {
                            dispatch(getList());
                            resolve();
                        }
                        else {
                            dispatch(bookingFetch(data.reservationsFailed));
                        }
                    }
                    else {
                        reject(data.error);
                    }
                })
                .catch(error => dispatch(appError(error)));
        });
    }
}

export function reservationRemove(reservationId) {
    return dispatch => {
        axiosDispatch(dispatch, '/bag/reservationRemove', {reservationId})
            .then(data => dispatch(getList()))
            .catch(error => dispatch(appError(error)));
    }
}

export function reservationPayment(orderId,
                                   reservationId) {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/reservationPayment', {
            orderId,
            reservationId,
        })
            .catch(error => dispatch(appError(error)));
    }
}

export function orderPayment(orderId) {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/orderPayment', {orderId})
            .catch(error => dispatch(appError(error)));
    }
}

export function reservationRemoveNew(reservationId) {
    return dispatch => {
        axiosDispatch(dispatch, '/bag/reservationRemoveNew', {reservationId})
            .then(data => dispatch(getList()))
            .catch(error => dispatch(appError(error)));
    }
}

export function reservationPaymentNew(orderId,
                                      reservationId) {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/reservationPaymentNew', {
            orderId,
            reservationId,
        })
            .catch(error => dispatch(appError(error)));
    }
}

export function orderPaymentNew(orderId) {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/orderPaymentNew', {orderId})
            .catch(error => {
                dispatch(timeExpired(error))
                dispatch(getList())
            });
    }
}

export function orderPaymentCredit(orderId) {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/orderPaymentCredit', {orderId})
            .catch(error => {
                dispatch(timeExpired(error))
                dispatch(getList())
            });
    }
}

export function fetchList(data) {
    return {
        type: types.BAG_FETCH,
        payload: data,
    }
}

export function setOrderId(id) {
    // localStorage.setItem('orderId', id);
    return {
        type: types.BAG_ORDER_ID_SET,
        payload: {id},
    }
}

export function timeExpired(msg) {
    return {
        type: types.BAG_TIME_EXPIRED,
        payload: {msg},
    }
}

export function refund(reservationId) {
    return dispatch => {
        return new Promise((resolve,
                            reject) => {
            axiosDispatch(dispatch, '/bag/refund', {reservationId})
                .then(data => {
                    if (data.errorMessage === undefined && data.Message === undefined) {
                        dispatch(getReservations());
                        resolve();
                    }
                    else {
                        reject(data.Message);
                    }
                })
                .catch(error => {
                    dispatch(appError(error))
                });
        })
    }
}

export function refundOneAction(globalOrderId,
                                globalOrderItem,
                                orderItemBlankId) {
    const param = {
        global_order_id: globalOrderId,
        global_order_item: globalOrderItem,
        order_item_blank_id: orderItemBlankId,
    }
    return dispatch => {
        return new Promise((resolve,
                            reject) => {
            axiosDispatch(dispatch, '/bag/refundOne', {
                globalOrderId,
                globalOrderItem,
                orderItemBlankId,
            })
                .then(data => {
                    if (data.errorMessage === undefined && data.Message === undefined) {
                        dispatch(getReservations());
                        resolve();
                    }
                    else {
                        reject(data.Message);
                    }
                })
                .catch(error => dispatch(appError(error)));
        })
    }
}

export function refundAmount(global_order_item,
                             orderItemBlankId = 0,
                             type) {

    const param = {
        global_order_item: global_order_item,
        order_item_blank_id: orderItemBlankId,
        type: type,
    }

    return dispatch => {
        return new Promise((resolve,
                            reject) => {
            axiosDispatch(dispatch, '/bag/refundAmount', param)
                .then(data => {
                    if (data.errorMessage === undefined && data.Message === undefined) {
                        resolve(data);
                    } else {
                        if (data.MessageParams.length > 0) {
                            reject(data.MessageParams[0])
                        } else {
                            reject(data.Message);
                        }
                    }
                })
                .catch(error => {
                    dispatch(appError(error))
                });
        })
    }
}

export function cancelTask(reservationId) {
    return dispatch => {
        return new Promise((resolve,
                            reject) => {
            axiosDispatch(dispatch, '/bag/cancelTask', {reservationId})
                .then(data => {
                    if (data.errorMessage === undefined && data.Message === undefined) {
                        dispatch(getTasks());
                        resolve();
                    }
                    else {
                        reject(data.Message);
                    }
                })
                .catch(error => {
                    dispatch(appError(error))
                });
        })
    }
}
