import React, {Component, Fragment} from 'react';
//
// class Timer extends PureComponent {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             seconds: props.seconds,
//         };
//     }
//
//     render() {
//         const {seconds} = this.props
//         return (<span key={seconds} className="time">{timeZero(Math.floor(seconds / 60))}:{timeZero(seconds % 60)}</span>);
//     }
// }
//
//
// export default withRouter(Timer);
//
// const timeZero = time => (time < 10) ? '0' + time : time;

// import cn from 'classnames/bind'

//const cx = cn.bind(styles)

class Timer extends Component {
    constructor(props) {
        super(props)
        this.count = this.count.bind(this)
        this.state = {
            days: false,
            minutes: false,
            hours: false,
            seconds: false,
            time_up: ""
        }
        this.x = null

    }

    count() {
        var now = new Date().getTime();
        var t = (this.props.deadline * 1000) - now;
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((t % (1000 * 60)) / 1000);
        this.setState({days, minutes, hours, seconds})
        if (t < 0) {
            clearInterval(this.x);
            this.setState({days: false, minutes: false, hours: false, seconds: false, time_up: "TIME IS UP"})
            this.props.getBag();
        }
    }

    timeZero = (time) => {
        return (time < 10) ? '0' + time : time;
    }

    componentDidMount() {
        this.x = setInterval(this.count, 1000);
    }

    render() {
        const {seconds, minutes} = this.state
        return (
            <Fragment>
                {
                    minutes &&
                    <span  style={{lineHeight: '21.75px'}} className="time">{this.timeZero(minutes)}:{this.timeZero(seconds)}</span>
                }
            </Fragment>
        )
    }
}

export default Timer