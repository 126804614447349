const genderOptions = [
  {value : 'Male',
    label: 'Мужской',
  },
  {value : 'Female',
    label: 'Женский',
  },
];

export default genderOptions;
