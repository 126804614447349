import * as bens from '../constants/ActionTypesBens';
import axiosDispatch from './../axios/dispatch';
import {appError} from './App';

export function action() {
  return dispatch => {
      axiosDispatch(dispatch, '/ben', {})
        .then(data => dispatch(goodAction(data)))
        .catch(error => dispatch(appError(error)))
    }
}

export function goodAction() {
  return {
    type: bens.GOOD_ACTION,
  }
}
