import React, {Component, Fragment} from 'react';
import {dateFormatter, getFile, getTariffName} from '../Helpers';

class OrderItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            flagShow: false,
            dData: true,
            confirm: false,
            globalOrderitem: null,
            errorMessage: null,
            message: null,
            classs: null,
            amount: null,
            passenger: null,
            type: null,
            ticket: props.ticket,
            retusnAll: true,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ticket: nextProps.ticket})
    }


    showDetails = (order_item_id) => {
        // this.setState({dData: false, flagShow: true, retusnAll: false})
        this.setState({expand: !this.state.expand})
        if (this.props.reservation.order_data.status === "errorCode") {
            this.setState({dData: false, flagShow: true, retusnAll: false})
            return false;
        }

        if (this.props.type === "archive") {
            this.props.getDataArchive(order_item_id)
                .then((resp) => {
                    this.setState({dData: false, flagShow: true})
                })
        } else {
            this.props.updateBlank(order_item_id)
                .then((resp) => {
                    if (resp.errorMessage === null || resp.errorMessage === "") {
                        this.setState({dData: false, flagShow: true})
                    } else {
                        this.setState({expand: !this.state.expand})
                        this.setState({
                            message: true,
                            classs: 'bg-danger',
                            errorMessage: resp.errorMessage,
                        })

                        setTimeout(() => {
                            this.setState({message: false});
                        }, 10000)
                    }
                })
        }

    }

    handleConfirmModal = (toggler, globalOrderitem = 0, passenger = 0, type = 'full') => {
        const {refundAmount} = this.props;
        if (toggler) {
            const refundPass = type === 'full' ? passenger : passenger.order_item_blank_id;
            refundAmount(globalOrderitem, refundPass, type)
                .then((data) => {
                    this.setState({
                        confirm: toggler,
                        globalOrderitem: globalOrderitem,
                        errorMessage: null,
                        amount: data,
                        passenger: passenger,
                        type: type,
                    })
                })
                .catch((error) => {

                    this.setState({
                        message: true,
                        classs: 'bg-danger',
                        errorMessage: error,
                    })

                    setTimeout(() => {
                        this.setState({message: false});
                    }, 10000)

                })
        } else {
            this.setState({
                confirm: toggler,
                globalOrderitem: null,
                errorMessage: null,
                amount: 0,
                passenger: 0,
                type: type,
            });
        }
    }


    handleTicketBackSwitch = () => {
        if (this.state.type === 'full') {
            this.handleTicketBack();
        } else {
            this.refundOneActionHandler();
        }

        const {ticket, passenger} = this.state
        const netTicketData = ticket.pasangers.map((item) => {
            const newItem = item;
            if (this.state.type === 'full' || newItem.id === passenger.id) {
                newItem.blank_status = 'process'
            }
            return item
        });
        ticket.pasangers = netTicketData
        this.setState({retusnAll: false, ticket: ticket});
    }

    handleTicketBack = () => {
        const {globalOrderitem} = this.state;
        if (globalOrderitem === null) {
            return;
        } else {
            let t = globalOrderitem;
            this.props.refund(globalOrderitem)
                .then((data) => {
                    this.handleConfirmModal(false);
                    this.setState({
                        message: true,
                        classs: 'bg-success text-white',
                        errorMessage: 'Заказ ' + t + ' на возврат - оформлен.',
                    });
                    this.showDetails(globalOrderitem);
                    setTimeout(() => {
                        this.setState({message: false});
                    }, 5000)
                })
                .catch((error) => {
                    this.handleConfirmModal(false);
                    this.setState({
                        message: true,
                        classs: 'bg-danger',
                        errorMessage: error,
                    });
                    setTimeout(() => {
                        this.setState({message: false});
                    }, 5000)
                })
        }
    }

    refundOneActionHandler = () => {

        if (this.state.globalOrderitem === null) {
            return;
        } else {
            let t = this.state.globalOrderitem;
            const {passenger} = this.state;
            const globalOrderId = passenger.global_order_id;
            const globalOrderItem = passenger.global_order_item;
            const orderItemBlankId = passenger.order_item_blank_id;
            this.props.refundOneAction(globalOrderId, globalOrderItem, orderItemBlankId)
                .then((data) => {
                    this.handleConfirmModal(false);
                    this.setState({
                        message: true,
                        classs: 'bg-success text-white',
                        errorMessage: 'Заказ ' + t + ' на возврат - оформлен.',
                    });
                    setTimeout(() => {
                        this.setState({message: false});
                    }, 5000)
                    this.showDetails(passenger.global_order_item);
                })
                .catch((error) => {
                    this.handleConfirmModal(false);
                    this.setState({
                        message: true,
                        classs: 'bg-danger',
                        errorMessage: error,
                    });
                    setTimeout(() => {
                        this.setState({message: false});
                    }, 5000)
                })
        }
    }
    timeToTravel = (ticket_data) => {
        let result = false;

        let d = (ticket_data.time_from).split(' ')
        let t = new Date(d[1] + ' ' + d[0]).getTime()

        if (t > new Date().getTime()) {
            result = true;
        }
        return result;
    }

    buttonRegistration = (blank_status,
                          order_item_blank_id,
                          global_order_item,
                          expiration_registration,
                          ticket_data) => {
        if (!this.timeToTravel(ticket_data)) {
            return false;
        }

        let button = blank_status;
        if (expiration_registration !== null && this.commpairDate(expiration_registration)) {
            return false;
        }
        switch (blank_status) {
            case 'ElectronicRegistrationPresent':
                button = (<span key={blank_status + order_item_blank_id} className='btn btn__order'
                                onClick={() => this.handleChangeER(global_order_item, order_item_blank_id, 'false')}>Отменить ЭР</span>)
                break
            case 'ElectronicRegistrationAbsent':
                button = (<span key={blank_status + order_item_blank_id} className='btn btn__order'
                                onClick={() => this.handleChangeER(global_order_item, order_item_blank_id, 'true')}>Пройти ЭР</span>)
                break
            case 'NotConfirmed':
                button = 'Не подтвержден';
                break
            case 'Voided':
                button = 'Аннулирован';
                break
            case 'Returned':
                button = 'Возвращен';
                break
            case 'PlacesReturned':
                button = 'Возвращены места';
                break
            case 'VoucherIssued':
                button = 'Выдан посадочный купон';
                break
            case 'TripWasInterrupted':
                button = 'Выполнено прерывание поездки';
                break
            case 'TripWasInterruptedAndResumedAfter':
                button = 'Выполнено прерывание с возобновлением поездки';
                break
            case 'Unknown':
                button = 'Статус неизвестен';
                break
            case 'error':
                button = '';
                break
            default:
                button = 'Статус неизвестен';
                break
        }


        return button
    }


    commpairDate = (expiration_registration) => {
        return new Date() > new Date(expiration_registration)
    }


    handleChangeER = (global_order_item, order_item_blank_id, type) => {
        this.setState({
            message: true,
            classs: 'bg-warning',
            errorMessage: 'Обработка, ожидайте пожалуйста.',
        });
        this.props.changeER(global_order_item, order_item_blank_id, type).then(() => {
            this.setState({message: false});
        }).catch(() => {
            this.props.waitTicket(false)
            this.setState({
                classs: 'bg-danger',
                expand: false,
                errorMessage: 'Во время изменения электронной регистрации произошла ошибка, попробуйте позже',
            });
            setTimeout(() => {
                this.setState({message: false});
            }, 5000)
        })
    }

    handlerGetFile(global_order_id) {
        getFile(global_order_id)
            .then((response) => {
                this.setState({
                    message: true,
                    classs: 'bg-danger',
                    errorMessage: response,
                });
                setTimeout(() => {
                    this.setState({message: false});
                }, 5000)
            })
    }

    returnReservation = (pasangers) => {
        let countAvalible = 0;
        for (let i = 0; i < pasangers.length; i++) {
            if (pasangers[i].blank_status === 'ElectronicRegistrationPresent' ||
                pasangers[i].blank_status === 'ElectronicRegistrationAbsent') {
                countAvalible++;
            }
        }
        return pasangers.length === countAvalible
    }

    render() {
        const {reservation, i, cities, type} = this.props;
        const {expand, dData, ticket, retusnAll, flagShow} = this.state;
        const BabyWithoutPlace = ticket.pasangers.find(r => r?.tariff_type === 'BabyWithoutPlace')

        return (
            <Fragment>
                <div className="rais d-flex pb-3 justify-content-between">
                    <div>
                        <strong>Поезд {ticket.ticket_data.train_number} {ticket.ticket_data.global_order_item} </strong>
                    </div>
                    {/*{reservation.order_data.comment == '' ? null :*/}
                    {/*(<i>Билет куплен по заявке листа ожидания</i>)}*/}
                    {expand ?
                        <Fragment>
                            {(retusnAll && flagShow) ?

                                <Fragment>
                                    {type !== "archive"
                                    && this.returnReservation(ticket.pasangers)
                                    && this.timeToTravel(ticket.ticket_data) ?
                                        <div className='d-flex'>
                                            <button className="btn btn__order"
                                                    onClick={() => this.handleConfirmModal(true, ticket.ticket_data.global_order_item)}>Вернуть
                                            </button>
                                        </div> : ""}
                                    <div className="d-flex ">
                                        <a className="btn btn__order"
                                           onClick={() => this.handlerGetFile(ticket.ticket_data.global_order_id)}
                                        >Скачать</a>
                                    </div>
                                </Fragment> : ''
                            }
                        </Fragment>
                        :
                        <Fragment>
                            {reservation.order_data.status !== 'waiting_for_capture' &&
                            <div className='d-flex'>
                                <div className="pl-3 pointer"
                                     onClick={() => this.showDetails(ticket.ticket_data.global_order_item)}>
                                    Подробнее {reservation.order_data.status !== 'errorCode' ? "(скачать / вернуть)" : ""}
                                    <span className="icon-item">
                                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                </span>
                                </div>
                            </div>}
                        </Fragment>
                    }
                </div>
                <div className="d-flex justify-content-between mb-2  flexible_arhive">
                    <div>
                        <div>Отправление: {dateFormatter(ticket.ticket_data.time_from, true)}</div>
                        <div>{cities.find(el => el.value === ticket.ticket_data.destination_from).lable}</div>
                    </div>
                    <span className="icon-item">
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                    <div>
                        <div>Прибытие: {dateFormatter(ticket.ticket_data.time_to, true)}</div>
                        <div>{cities.find(el => el.value === ticket.ticket_data.destination_to).lable}</div>
                    </div>
                </div>
                {expand &&
                <div className="passengers-wrapper table-responsive">
                    <table className='table table-sm'>
                        <thead>
                        <tr className='text-center'>
                            <th className='text-left'>Пассажир</th>
                            <th>Документ</th>
                            <th>Пол</th>
                            <th className='text-nowrap'>Дата рождения</th>
                            <th>Вагон</th>
                            <th>Место</th>
                            <th>Тариф</th>
                            <th>Стоимость</th>
                            <th title='электроанная регистриация'>ЭР</th>
                            <th title='Возврат'>Вернуть</th>
                        </tr>
                        </thead>
                        <tbody className={dData ? 'gradient' : ''}>
                        {ticket.pasangers.map((pasanger, j) => {
                            return (
                                <Fragment key={pasanger.id + pasanger.blank_status}>
                                    <tr className={(pasanger.blank_status === 'Returned' ? 'bg-grey ' : '') + 'text-center'}>
                                        <td className='text-left'>{j + 1} {pasanger.fio} </td>
                                        <td>{pasanger.document_number}</td>
                                        <td>{pasanger.sex === 'Male' ? 'мужской' : 'женский'}</td>
                                        <td>{dateFormatter(pasanger.birth_date)}</td>
                                        <td>{ticket.ticket_data.car}</td>
                                        <td>{pasanger.places}</td>
                                        <td>{getTariffName(pasanger.tariff_type)}</td>
                                        <td>{parseInt(pasanger.amount)}</td>
                                        <td>{this.buttonRegistration(pasanger.blank_status,
                                            parseInt(pasanger.order_item_blank_id),
                                            pasanger.global_order_item,
                                            pasanger.expiration_registration,
                                            ticket.ticket_data
                                        )}
                                        </td>
                                        {
                                            (pasanger.blank_status === 'ElectronicRegistrationPresent' ||
                                                pasanger.blank_status === 'ElectronicRegistrationAbsent')
                                            && this.timeToTravel(ticket.ticket_data)
                                                ?
                                                <td onClick={() => this.handleConfirmModal(true, pasanger.global_order_id, pasanger, 'single')}>
                                                    <span className='btn btn__order'>Вернуть</span>
                                                </td>
                                                : <td></td>
                                        }
                                    </tr>
                                </Fragment>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                }
                {this.state.confirm ? (
                    <Fragment>
                        <div className="modal modal--confirm show refund">
                            <div className="modal-dialog modal-dialog--confirm">
                                <div className="modal-content">
                                    <h4>
                                        {BabyWithoutPlace && ticket?.pasangers?.length > 1 ? <span>
                                            Возврат заказа возможен только полностью.<br/>
                                            <span>Возврат будет оформлен на электронный билет взрослого пассажира и электронный билет ребенка до 5 лет."</span>
                                        </span> : ticket?.pasangers?.length > 1 && <span>Возврат заказа возможен только полностью.</span>}
                                        <br/>
                                        Вы уверены, что хотите вернуть билет?
                                    </h4>
                                    <h5>Сумма возврата составит {this.state.amount} р</h5>
                                    <div className="confirm-modal__btn-wrapper">
                                        <button className="btn btn__white"
                                                onClick={() => this.handleTicketBackSwitch()}>Да
                                        </button>
                                        <button className="btn btn__white"
                                                onClick={() => this.handleConfirmModal(false)}>Отменить
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay overlay--confirm show"
                                 onClick={() => this.handleConfirmModal(false)}></div>
                        </div>
                    </Fragment>
                ) : null}
                {this.state.message &&
                <div className={this.state.classs + ' toaster active z-999'}>
                    {this.state.errorMessage}
                </div>
                }
            </Fragment>
        )
    }
}

export default OrderItem;
