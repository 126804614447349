import React from 'react';
import {Helmet} from 'react-helmet';
import Navigation from '../../containers/Navigation/Cabinet';

function Rules() {

    return (<React.Fragment>
            <Helmet>
                <title>Электронный билет и электронная регистрация</title>
            </Helmet>
            <Navigation/>
            <div className="main">
                <div className="inner-page conditions-page">
                    <h1 className="conditions__title text-center">
                        Электронный билет и электронная регистрация
                    </h1>
                    <div className="row">
                        <div className="col">
                            <h3 className="conditions__title">
                                Электронный билет
                            </h3>
                            <details>
                                <summary>
                                    Что такое электронный билет?
                                </summary>
                                Электронный билет – это современный и быстрый способ оформить проездной документ прямо
                                из дома,
                                не тратя время на поездку на вокзал.
                                При покупке электронного ж/д билета вы можете выбрать поезд и конкретное место, которое
                                выкупается сразу же в момент оплаты.
                                После оплаты электронного билета на электронную почту будет направлен контрольный купон
                                электронного билета. Он является документом строгой отчетности, но если вы не прошли
                                электронную
                                регистрацию, для посадки в поезд он недействителен.
                                Для проезда в поезде вам нужно:
                                <ul>
                                    <li>либо пройти электронную регистрацию, получить на электронную почту посадочный
                                        купон
                                        (для поездов с услугой электронной регистрации) и предъявить его проводнику
                                        перед
                                        посадкой вместе с документом, удостоверяющим личность, указанным при оформлении
                                        заказа;
                                    </li>
                                    <li>получить посадочный купон в билетной кассе на вокзале или в терминале
                                        самообслуживания
                                        на территории РФ.
                                    </li>
                                </ul>
                                Распечатать посадочный купон в кассе вы можете в любое время до отправления поезда с
                                начальной
                                станции следования, для этого вам понадобится код заказа (14-значный, он будет указан в
                                вашем
                                контрольном купоне) и документ, удостоверяющий личность, указанный при покупке билета.
                            </details>
                            <details>
                                <summary>
                                    Как оформить электронный билет?
                                </summary>
                                Оформление электронных билетов производится не позднее, чем за 1 час до отправления
                                поезда со
                                станции посадки пассажира. Просим принять во внимание, что во время технологического
                                перерыва в
                                системе «Экспресс» (пн-вт 3:30-4:00 и ср-вс 3:30-3:50 по московскому времени) оформление
                                электронных билетов на сайте не производится.
                                При заполнении формы покупки билета будьте внимательны! За правильность указания
                                реквизитов
                                поездки и персональных данных каждого пассажира полную ответственность несет
                                пользователь,
                                оформляющий заказ.
                                На электронные билеты распространяются почти все скидки РЖД. Вы можете оформить билет по
                                полному
                                и детскому тарифам, а также оформить безденежный билет для проезда детей, не занимающих
                                отдельное место, а также с использованием спецтарифов РЖД.
                                Одним заказом может быть оформлен электронный билет не более чем на 4 пассажиров. При
                                этом места
                                в вагонах разного типа (купе, плацкарт и т.д.), а также в купе разного типа (мужское,
                                женское,
                                смешенное) могут быть оформлены только разными заказами. Если вы оформляете заказ в
                                мужское или
                                женское купе, внимательно проверьте, обязательно верно указывайте пол пассажира.
                                Цену на билет вы видите сразу на этапе получения справки о наличии мест. Единственное
                                исключение
                                составляют поезда отправлением из Украины и от станций Финляндии – для них стоимость
                                проезда
                                отображается только перед оплатой электронного билета. Это связано с технологическими
                                особенностями работы системы.
                                Помните, что на принятие решения об оплате заказа и ввод платежных реквизитов у вас есть
                                только
                                15 минут. Если за это время вы не приняли решение, заказ аннулируется, и для покупки
                                билета вам
                                придется оформить заказ заново.
                                Во избежание недоразумений и предотвращения опоздания на поезд рекомендуем при
                                оформлении
                                заказов в поезда по маршруту с пересадками выбирать интервал между прибытием и
                                отправлением не
                                менее 3-4 часов.
                            </details>
                            <details>
                                <summary>
                                    На какие поезда можно купить электронный билет?
                                </summary>
                                На поезда внутреннего сообщения по территории Российской Федерации (формирования ОАО
                                «РЖД», АО
                                «ФПК», ОАО «ПКС», ЗАО ТК «Гранд Сервис Экспресс», ООО «Тверской экспресс», ЗАО «ТКС» »,
                                а также
                                перевозчиков других государств), за исключением поездов следующих в (из) Калининградскую
                                область
                                транзитом через территорию Литовской Республики;
                                в пригородном сообщении с предоставлением мест (на поезда формирования ОАО «ЦППК») по
                                маршрутам
                                Москва - Владимир, Москва - Калуга, Москва – Дубна, Москва - Рязань, Москва - Тула,
                                Москва -
                                Александров, Москва - Орехово-Зуево;
                                международного сообщения со странами СНГ (Республика Азербайджан, Республика Казахстан,
                                Киргизская Республика, Республика Беларусь, Украина, Республика Таджикистан,
                                Туркменистан,
                                Республика Узбекистан, Республика Молдова), Латвийской Республикой, Литовской
                                Республикой,
                                Эстонской Республикой, Республикой Абхазия;
                            </details>
                            <details>
                                <summary>
                                    Какой доступный период для покупки электронных билетов и глубина продаж?
                                </summary>
                                При наличии свободных мест электронные билеты можно приобрести:
                                <ul>
                                    <li>на все поезда и прицепные вагоны, курсирующие во внутригосударственном
                                        сообщении,
                                        продажа билетов открыта за 90 суток (на ряд поездов за 120, 60 или 45) до даты
                                        отправления поезда;
                                    </li>
                                    <li>не ранее 60 суток на все поезда дальнего следования во внутрироссийском
                                        сообщении, в
                                        сообщении с Республикой Абхазия, а также на поезда в международном сообщении,
                                        указанные
                                        ниже;
                                    </li>
                                    <li>не ранее 45 суток на все поезда дальнего следования в сообщении со странами СНГ,
                                        Балтии,
                                        кроме указанных ниже, глубина продажи которых составляет 60 суток;
                                    </li>
                                    <li>не ранее 30 суток на поезда из Украины и Молдовы;</li>
                                    <li>не ранее 10 суток на поезда пригородного сообщения с предоставлением мест;</li>
                                    <li>не позднее, чем за 1 час до отправления поезда со станции посадки пассажира;
                                    </li>
                                    <li>не позднее, чем за 24 часа до отправления поезда со станции посадки пассажира,
                                        если
                                        станция расположена на территории иностранной железной дороги и услуга
                                        электронной
                                        регистрации не предоставляется;
                                    </li>
                                    <li>не позднее даты, следующей за текущей, если станция отправления пассажира
                                        расположена на
                                        территории Украины.
                                    </li>
                                </ul>
                                Продажи на 90-ю (60-ю, 45-ю) дату во внутрироссийском сообщении начинается в то же
                                время, что и
                                в кассах региона отправления поезда.
                                Продажа билетов на поезда, отправляющиеся из Казахстана и Киргизии, начинается в 5:00 по
                                московскому времени.
                                Продажа билетов на поезда из Абхазии, Белоруссии, Молдовы, Украины, Таджикистана,
                                Узбекистана,
                                Латвии, Литвы, Эстонии начинается в 8:00 по московскому времени.
                                В период перехода Европы на зимнее время продажа билетов из Украины, Молдовы, Литвы,
                                Латвии,
                                Эстонии открывается в 9:00 по московскому времени.
                                Оформление электронных билетов не производится в период технологического перерыва
                                системы
                                "Экспресс"
                                Поезда в международном сообщении со странами СНГ и Балтии глубиной продажи 60 суток
                                <ul>
                                    <li>№ 1/2 Москва – Минск - Москва;</li>
                                    <li>№ 3/4,№ 7/8 Москва – Брест - Москва;</li>
                                    <li>№ 29/30 (прицепная группа) Москва – Вильнюс - Москва;</li>
                                    <li>№ 33/34 Москва – Таллин - Москва;</li>
                                    <li>№ 39/40 Москва – Полоцк - Москва;</li>
                                    <li>№ 51/52 Минск – Санкт-Петербург - Минск;</li>
                                    <li>№ 55/56 Москва – Гомель - Москва;</li>
                                    <li>№ 2/1, № 38/37 Рига – Москва – Санкт-Петербург;</li>
                                    <li>№ 83/84 Гомель – Санкт-Петербург - Гомель;</li>
                                    <li>№ 26/25 Москва – Минск - Москва;</li>
                                    <li>№ 28/27 Москва – Брест - Москва;</li>
                                    <li>№ 50/49 Санкт-Петербург – Брест - Санкт-Петербург;</li>
                                    <li>№ 64/63 Санкт-Петербург – Брест - Санкт-Петербург;</li>
                                    <li>№ 66/65 Мурманск - Минск - Мурманск;</li>
                                    <li>№ 68/67 Саратов - Брест - Саратов;</li>
                                    <li>№ 78/77 Москва - Гродно - Москва;</li>
                                    <li>№ 96/95 Москва – Брест - Москва;</li>
                                    <li>№ 104/103 Новосибирск - Брест - Новосибирск;</li>
                                    <li>№ 132/131 Москва – Брест - Москва;</li>
                                    <li>№ 134/133 Архангельск - Минск - Архангельск.</li>
                                </ul>
                                Поезда в международном сообщении (дальнее зарубежье) глубиной продажи 60 суток
                                <ul>
                                    <li> № 6/5 Москва - Улан-Батор - Москва;</li>
                                    <li> № 9/10 Москва – Варшава – Москва, включая беспересадочные вагоны Москва –
                                        Будапешт -
                                        Москва, Москва – София – Москва, Москва – Варна – Москва, Москва – Бургас –
                                        Москва,
                                        Москва – Бар – Москва;
                                    </li>
                                    <li> № 13/14 Москва – Берлин – Москва;</li>
                                    <li> № 17/18 Москва – Ницца – Москва;</li>
                                    <li> № 21/22 Москва – Прага – Москва, включая беспересадочные вагоны: Москва – Вена
                                        –
                                        Москва, Москва – Карловы Вары – Хеб – Москва, Санкт-Петербург – Прага –
                                        Санкт-Петербург,
                                        Санкт-Петербург – Вена – Санкт-Петербург;
                                    </li>
                                    <li> № 23/24 Москва – Париж – Москва;</li>
                                    <li> № 306/305 Иркутск – Улан-Батор – Иркутск.</li>
                                </ul>

                                Поезда в международном сообщении (дальнее зарубежье) глубиной продажи 90 суток
                                <ul>
                                    <li>№ 32/31Москва – Хельсинки – Москва</li>
                                    <li>Все поезда «Аллегро» Санкт-Петербург – Хельсинки – Санкт-Петербург</li>
                                </ul>
                            </details>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3 className="conditions__title">
                                Электронная регистрация
                            </h3>
                            <details>
                                <summary>
                                    Что такое электронная регистрация?
                                </summary>
                                Электронная регистрация на поезд – это еще один сервис, делающий жизнь пассажира более
                                комфортной. С ее помощью вы регистрируетесь на посадку в режиме онлайн, и вам не
                                придется
                                получать билет в билетной кассе – для посадки на поезд будет достаточно показать
                                проводнику
                                документ, удостоверяющий личность, и посадочный купон.
                                Посадочный купон будет направлен на электронную почту вместе с контрольным купоном после
                                того,
                                как оплатите билет. Его можно распечатать или предъявить проводнику на экране любого
                                мобильного
                                устройства.
                                Электронная регистрация доступна для большинства, но не для всех поездов. В форме
                                покупки билета
                                поезда, на которые есть электронная регистрация, помечены специальным значком «ЭР».
                                При заполнении формы на покупку билета электронная регистрация выполняется автоматически
                                для
                                всех поездов, на которых она доступна.
                                Если электронная регистрация пройдена, не производится:
                                <ul>
                                    <li>возобновление срока действия электронного билета в случае опоздания на поезд или
                                        прерывания поездки в пути следования;
                                    </li>
                                    <li>оформление посадочных купонов (проездных документов) в билетных кассах и в
                                        терминалах
                                        самообслуживания на поезда, курсирующие в сообщении между РФ и Латвийской
                                        Республикой,
                                        Литовской Республикой, Эстонской Республикой, Республикой Беларусь, Республикой
                                        Казахстан и Кыргызской Республикой менее чем за 1 час до отправления поезда с
                                        начальной
                                        станции маршрута следования поезда.
                                    </li>
                                </ul>
                            </details>
                            <details>
                                <summary>
                                    Где доступна электронная регистрация?
                                </summary>
                                С 1 декабря 2013 на все поезда во внутрироссийском сообщении, за исключением поездов,
                                следующих
                                в Калининград, Калининградскую область и обратно;
                                на поезд №32 "Лев Толстой" Москва – Хельсинки;
                                на поезда №17/18 Москва - Ницца – Москва;
                                на поезда №23/24 Москва - Париж - Москва;
                                на поезд №21/22 Москва - Прага - Москва;
                                на поезда, курсирующие в сообщении между Российской Федерацией и Республикой Беларусь;
                                на поезда, курсирующие в сообщении между Российской Федерацией и Латвийской Республикой;
                                на группы вагонов формирования АО «Литовские железные дороги», курсирующие в сообщении
                                Москва -
                                Вильнюс в составе поезда №029/030 по маршруту Москва – Калининград формирования АО
                                «ФПК»;
                                на поезда, курсирующие между Республикой Казахстан и Российской Федерацией: № 71/72
                                Астана –
                                Москва – Астана, № 325/326 Новосибирск – Алма-Ата – Новосибирск;
                                на поезд № 033/034 Москва – Таллин – Москва, курсирующий между Эстонской Республикой и
                                Российской Федерацией;
                                на поезда «Аллегро» Санкт-Петербург – Хельсинки.
                                Электронная регистрация невозможна, если необходимо отправить багаж или оформить проезд
                                животному в багажном вагоне, так как его перевозка оформляется при предъявлении
                                проездного
                                документа. Также вам нужно принять во внимание, что при наличии услуги электронной
                                регистрации в
                                случае прерывания поездки в пути продолжение поездки не производится.

                                Дополнительно в международном сообщении:
                                При наличии услуги электронной регистрации на поезда, курсирующие в сообщении между РФ и
                                Латвийской Республикой, РФ и Республикой Беларусь, РФ и Эстонской Республикой, РФ и
                                Республикой
                                Казахстан оформление в билетных кассах и в терминалах самообслуживания посадочных
                                купонов
                                (проездных документов), а также возврат билетов не производится менее 1 часа до
                                отправления
                                поезда с начальной станции маршрута следования. Также для этих поездов не производится
                                возобновление срока действия электронного билета в случае опоздания на поезд.
                                Если вы прошли электронную регистрацию на поезда: №32 Москва-Хельсинки, №17/18
                                Москва-Ницца-Москва, №23/24 Москва-Париж-Москва, №21/22 Москва - Прага - Москва, включая
                                беспересадочные вагоны: Москва - Вена - Москва, Москва - Карловы Вары - Хеб - Москва,
                                Санкт-Петербург - Прага - Санкт-Петербург, и хотите вернуть свой электронный билет менее
                                чем за
                                1 час до отправления поезда с начальной станции маршрута следования и более чем за 6
                                часов до
                                отправления поезда со станции вашей посадки (по местному времени), возврат денежных
                                средств за
                                неиспользованный электронный билет осуществляется в претензионном порядке.
                            </details>
                            <details>
                                <summary>
                                    Как отказаться от электронной регистрации?
                                </summary>
                                Отказаться от электронной регистрации вы также можете в любой момент, не позднее, чем за
                                час до
                                отправления поезда с начальной станции маршрута. Сделать это можно следующими способами:
                                <ul>
                                    <li>в вашем Личном кабинете;</li>
                                    <li>получив посадочный купон в билетной кассе или терминале самообслуживания.</li>
                                </ul>
                                Отказаться от электронной регистрации позднее, чем за час до отправления поезда с
                                начальной
                                станции маршрута вы можете, получив посадочный купон в билетной кассе или терминале
                                самообслуживания на территории Российской Федерации.
                                Посмотреть актуальную информацию о своем заказе и управлять им вы также легко можете в
                                своем
                                Личном кабинете.

                                Внимание! Если вы отказались от услуги электронной регистрации, получив посадочный купон
                                в
                                билетной кассе или терминале самообслуживания, вы должны предъявить его при посадке в
                                поезд.
                            </details>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3 className="conditions__title">Оформление проезда детей</h3>
                            <details>
                                <summary>Как оформить проезд для детей?</summary>
                                Для детей действуют специальные тарифы, которые зависят от возраста ребенка и маршрута
                                поездки.
                                Для посадки в поезд с детьми до 14 лет вам нужно иметь при себе оригинал или нотариально
                                заверенную копию свидетельства о рождении ребенка. Если ребенок старше 14 лет, он должен
                                иметь
                                при себе паспорт. При оформлении билета также необходимо указывать данные паспорта. По
                                истечении
                                2-х месяцев с момента наступления 14-летия проводник имеет право отказать в посадке в
                                поезд
                                пассажиру, билет которого оформлен на основании сведений свидетельства о рождении.
                                Во внутрироссийском сообщении в вагоны с разделением по гендерному признаку
                                (женское/мужское/смешанное купе) ребенку в возрасте до 10-ти лет оформляется билет в
                                одно купе с
                                родителями, независимо от пола ребенка. Если вы путешествуете с детьми за границу,
                                рекомендуется
                                оформлять электронные билеты в смешанные купе.
                            </details>
                            <details>
                                <summary>Какие правила оформления детского тарифа?</summary>
                                Для детей соответствующего возраста оформляется электронный билет по детскому тарифу,
                                ребенок
                                при этом занимает отдельное место. Билет должен быть оформлен на каждого ребенка. Билет
                                по
                                детскому тарифу оформляется в одном заказе с электронным билетом по полному тарифу.
                            </details>
                            <details>
                                <summary>
                                    Какие правила оформления безденежного билета?
                                </summary>
                                Пассажир, оплативший поездку по полному тарифу, может провозить с собой одного ребенка
                                соответствующего возраста, не оплачивая его проезд, если ребенок не занимает отдельное
                                место.
                                При проезде в поездах дальнего следования в этом случае для ребенка оформляется
                                безденежный
                                электронный билет без занятия места. Билет по полному тарифу и безденежный билет для
                                ребенка
                                оформляются одним заказом. При проезде в пригородных поездах отдельный билет на ребенка,
                                не
                                занимающего отдельное место, не оформляется.
                                При оформлении электронных билетов в вагон Люкс по тарифу Single (МКЛ1/1) в поездах
                                дальнего
                                следования в сообщении с Финляндией и Европой, взрослый пассажир может бесплатно
                                провезти с
                                собой двоих детей возраста, соответствующего безденежному проезду, оформив два
                                безденежных
                                билета в одном заказе с билетом по полному тарифу.
                                Пассажир, выкупивший целое купе в вагонах «Люкс» (категории «мягкий», класс обслуживания
                                «1А»,
                                «1М», «1И»), имеет право провозить с собой одного ребенка соответствующего возраста без
                                оплаты
                                стоимости проезда, независимо от количества пассажиров в заказе.
                                Также получить безденежный проездной документ для ребенка можно в билетной кассе. Для
                                этого у
                                вас должен быть при себе электронный билет (посадочный купон на бланке проездного
                                документа),
                                оплаченный по полному тарифу.
                                Если вы хотите, чтобы ребенок занимал отдельное место, вы можете оформить на него
                                электронный
                                билет с местом по детскому тарифу. Этот билет также должен быть оформлен одним заказом с
                                электронным билетом по полному тарифу.
                            </details>
                            <details>
                                <summary>
                                    Какой тариф применяется в зависимости от возраста?
                                </summary>
                                Во внутрироссийском сообщении и сообщении со странами СНГ, Латвийской Республикой,
                                Литовской
                                Республикой, Эстонской Республикой, Республикой Абхазия:
                                <ul>
                                    <li>до 5 лет – безденежный проезд или детский тариф (до 10 лет в мягких вагонах во
                                        внутрироссийском сообщении);
                                    </li>
                                    <li>с 5 до 10 лет – детский тариф;</li>
                                    <li>с 10 лет – полный тариф.</li>
                                </ul>
                            </details>
                            <details>
                                <summary>
                                    Проезд детей без сопровождения взрослых?
                                </summary>
                                Не допускается проезд без сопровождения взрослых детей в возрасте до 10 лет – во
                                внутрироссийским сообщении. Возраст ребенка определяется на день начала поездки.
                            </details>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3 className="conditions__title">
                                Общедоступные и специальные тарифы РЖД
                            </h3>
                            <details>
                                <summary>
                                    Полный
                                </summary>
                                Общедоступный тарифный план
                            </details>
                            <details>
                                <summary>
                                    Детский
                                </summary>
                                Скидка 70% от полного тарифа. Для проезда пассажиров в возрасте до 10 лет на момент
                                отправления поезда. Распространяется на все классы обслуживания. При посадке в поезд
                                необходимо предъявить оригинал документа или нотариально заверенную копию,
                                удостоверяющую личность и возраст ребенка (для проверки соответствия оформленного
                                тарифного плана).
                            </details>
                            <details>
                                <summary>
                                    Туда-Обратно
                                </summary>
                                Для единовременного оформления проезда «туда» и «обратно» в случае проезда по одному
                                маршруту от/до одинаковых станций отправления/назначения. Снижает стоимость проездного
                                документа в направлении «обратно» на 20% (с 24.12.2019 возможно оформление по городам
                                отправления/прибытия (вне зависимости от станции)).
                            </details>
                            <details>
                                <summary>
                                    Юниор (Junior)
                                </summary>
                                Скидка 30%. Для пассажиров в возрасте от 10 до 21 года (включая день 21-летия) на момент
                                отправления. Не распространяет свое действие на классы обслуживания 1Р
                                (купе-переговорная) и 1В (первый класс).
                            </details>
                            <details>
                                <summary>
                                    Пенсионерам (Senior)
                                </summary>
                                Скидка 30%. Для проезда пассажиров, достигших на момент отправления поезда возраста 60
                                лет. Не распространяет свое действие на классы обслуживания 1Р (купе-переговорная) и 1В
                                (первый класс).
                            </details>
                            <details>
                                <summary>
                                    Больше пространства (Single)
                                </summary>
                                Для проезда одного пассажира на двух смежных креслах, без ограничения по возрасту
                                пассажира на момент отправления поезда. Снижает стоимость второго кресла на 50%
                                (начинает действовать с 01.07.2020 г.). Не распространяет свое действие на классы
                                обслуживания 1Р (купе-переговорная), 1В (первый класс) и 2Е (вагон-бистро).
                            </details>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">s
                            <h3 className="conditions__title">
                                Перевозка мелких домашних (комнатных) животных, собак-поводырей, птиц, диких животных
                            </h3>
                            <details>
                                <summary>
                                    Правила перевозки во внутреннем сообщении по территории России
                                </summary>
                                Пассажир может перевозить в поездах дальнего следования мелких домашних (комнатных)
                                животных,
                                собак и птиц сверх установленной нормы провоза ручной клади. Разрешено провозить не
                                более одного
                                места на один билет и не более двух животных или двух птиц на это место. Будьте
                                внимательны, за
                                перевозку животных и птиц может взиматься отдельная плата.
                                Мелкие домашние животные, собаки (кроме крупных пород и собак-поводырей) и птицы
                                перевозятся в
                                ящиках, корзинах, клетках, контейнерах, которые размещаются на местах для ручной клади.
                                Размер
                                такого места по сумме трех измерений не должен превышать 180 см.
                                Перевозка мелких домашних животных разрешается не во всех типах вагонов. У каждого
                                перевозчика
                                свои правила – полный перечень вы найдете на этой странице ниже.
                                Перед покупкой билета убедитесь, что в выбранном вагоне можно перевозить животных.
                                Если вы купили билет, в стоимость которого не входит провоз животного, то для посадки на
                                поезд
                                вам понадобится перевозочный документ, его можно оформить в кассе вокзала. Оформлять
                                перевозочный документ на свое животное не обязательно в тот момент, когда вы покупаете
                                свой
                                билет – это можно сделать с момента начала продажи билетов на поезд и вплоть до его
                                отправления.
                                А вернуть его в случае необходимости вы можете в специализированных кассах до момента
                                отправления поезда. После отправления поезда возврат не осуществляется.
                                Однако имейте в виду, что есть вагоны, где ЗАПРЕЩЕНО перевозить животных. В этом случае
                                уже
                                купленный билет придется сдать и купить новый в тот вагон, где можно ехать с питомцем.
                            </details>
                            <details>
                                <summary>
                                    Какие животные относятся к мелким домашним животным (животные-компаньоны)?
                                </summary>
                                <ul>
                                    <li>собаки;</li>
                                    <li>кошки;</li>
                                    <li>птицы (кряква, фазановые, голубеобразные, попугаеобразные, вьюрковые, ткачиковые
                                        и
                                        овсянковые, ястреб-перепелятник и ястреб-тетервятник),
                                    </li>
                                    <li>мелкие грызуны (белка, серая крыса, морская свинка, домовая мышь, джунгарский и
                                        сирийский хомяки, песчанки, шиншилла, декоративный кролик);
                                    </li>
                                    <li>мелкие неядовитые земноводные (квакша, шпорцевая лягушка, аксолотль);</li>
                                    <li>аквариумные рыбы и моллюски;</li>
                                    <li>мелкие неядовитые пресмыкающиеся (хамелеон, полоз, красноухая черепаха,
                                        ящерица);
                                    </li>
                                    <li>членистоногие животные (за исключением животных, относящихся к видам (породам),
                                        включенным в Красную книгу государств).
                                    </li>
                                </ul>
                                При посадке на поезд вам нужно иметь при себе соответствующие ветеринарные документы для
                                крупных
                                животных (в зависимости от вида животного: ветеринарные свидетельства № 1, 2, 3;
                                ветеринарные
                                справки формы № 4; ветеринарные сертификаты форм № 6.1, 6.2, 6.3. Срок действия данных
                                документов – 5 дней с момента выдачи и до начала перевозки (для России и СНГ).
                                Перевозить по
                                территории Российской Федерации мелких домашних животных, собак и птиц можно без
                                предъявления
                                ветеринарных документов. За провоз мелких домашних животных, собак и птиц без оплаты
                                владелец
                                несет имущественную ответственность в соответствии с законодательством РФ. Владелец
                                должен
                                следить за своим питомцем и обеспечить соблюдение санитарно-гигиенических норм в вагоне.
                                В поездах можно провозить крупных собак в намордниках, на поводке и под наблюдением их
                                владельцев или сопровождающих. При этом оплачивается полная стоимость всех мест в купе
                                без
                                дополнительной оплаты за провоз собаки.
                                Перевозка служебных собак производится в намордниках и с поводком в отдельном купе
                                купейного
                                вагона под наблюдением их владельцев или сопровождающих с оплатой полной стоимости всех
                                мест в
                                купе без дополнительной платы за их провоз, при этом количество проезжающих в купе собак
                                и их
                                владельцев или сопровождающих не должно превышать количество мест в купе.
                            </details>
                            <details>
                                <summary>
                                    Как перевезти собаку-поводыря?
                                </summary>
                                Собак-поводырей слепые пассажиры провозят с собой бесплатно в вагонах всех категорий.
                                При этом
                                собака-поводырь должна быть в ошейнике и наморднике и находиться у ног пассажира,
                                которого она
                                сопровождает.
                            </details>
                            <details>
                                <summary>
                                    Как перевезти диких животных?
                                </summary>
                                Перевозка в ручной клади диких животных в поездах и вагонах всех категорий не
                                разрешается.
                            </details>
                            <details>
                                <summary>
                                    Как перевезти животных в вагонах формирования ДОСС (поезда «Сапсан», «Ласточка»)?
                                </summary>
                                В «Сапсанах» и «Ласточках» вы можете провозить мелких домашних животных
                                (животных-компаньонов),
                                собак и птиц в перевозках (ящиках, корзинах, клетках, контейнерах), которые по сумме 3
                                измерений
                                не превышают 180 см и свободно размещаются на местах, предназначенных для ручной клади
                                (для
                                этого есть специально отведенные места в поездах). Вес животного вместе с перевозкой не
                                должен
                                превышать 10 кг.
                                Провоз крупных собак в поезде «Сапсан» запрещен.
                                Собак-поводырей в поездах «Сапсан» и «Ласточка» можно перевозить бесплатно. Перевозка
                                осуществляется в намордниках и с поводком.
                                Оформить билет для проезда с мелким домашним животным можно в вагоны, где есть
                                специально
                                выделенные для этого места.
                                В поездах «Сапсан» это места № 65-66 в вагоне № 8 и18, а также и №3 и 13.
                                В поездах «Ласточка» – места № 29-30 в вагонах № 5, 10, в поездах «Ласточка -Премиум» -
                                места
                                №27-34, 43-44 в вагонах №5, 10.
                            </details>
                            <details>
                                <summary>
                                    Как перевезти мелких домашних животных пассажиров вагонов первого и бизнес классов
                                    (1В и 1С)
                                    поездов «Сапсан»?
                                </summary>
                                Для перевозки мелких домашних животных в вагонах первого и бизнес классов в ВСП «Сапсан»
                                отводится специализированное пространство для ручной клади, напротив мест №№ 65-66 в
                                вагонах №
                                3(13).
                                Мелкие домашние (комнатные) животные, собаки и птицы перевозятся в переноске (клетке),
                                размер
                                которого в сумме трех измерений не должен превышать 120 см. При этом их вес вместе с
                                переноской
                                не должен превышать 10 кг. Разрешается проводить не более 1 животного на одно место и не
                                более 2
                                животных в одном поезде.
                                Для осуществления перевозки пассажиру необходимо не позднее, чем за двое суток до
                                отправления
                                поезда, подать заявку на резервирование мест. При подаче заявки у пассажира уже должен
                                быть
                                оформлен билет.
                                Животное перевозится отдельно от владельца, в вагоне № 3(13), если заявка была подана и
                                соблюдены размеры переноски. Оплата обязательного сбора.
                                При оформлении проездного документа для перевозки пассажира с мелким домашним животным
                                допускается применение действующих специальных тарифных планов.
                            </details>
                            <details>
                                <summary>Как перевезти животных в купе-переговорной (1Р) поездов «Сапсан»?</summary>
                                Мелких домашних (комнатных) животных, вес которых вместе с переноской (клеткой) не
                                превышает 10
                                кг, а переноска по сумме трех измерений не превышает 120 см, можно провозить сверх
                                установленной
                                нормы ручной клади в изолированном купе-переговорной (класс обслуживания 1Р). При этом
                                разрешена
                                перевозка не более 1 животного на 1 место, не более 4 животных в купе. Переноска должна
                                быть
                                достаточно просторной, иметь вентиляционные отверстия и надежное запорное устройство.
                                Дно
                                переноски (клетки) должно быть плотным, водонепроницаемым и покрыто абсорбирующим
                                материалом,
                                который не должен просыпаться.
                                Дополнительная плата при перевозке животного в классе обслуживания 1Р не взимается.
                            </details>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Rules;