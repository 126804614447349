import * as types from './../constants/ActionTypesUser';
import axiosDispatch from './../axios/dispatch';
import {appError, waitTicketAction} from './App';
import dispatch from "./../axios/dispatch";

export function getTotals() {
    return (dispatch,
            getState) => {
        if (getState().auth.jwt === null) return;
        axiosDispatch(dispatch, '/users/totals')
            .then(data => {
                dispatch({
                    type: types.USER_TOTALS,
                    payload: data,
                })
            })
            .catch(error => {
                dispatch(appError(error))
            });
    }
}


export function getData() {
    return (dispatch,
            getState) => {
        if (getState().auth.jwt === null) return;
        axiosDispatch(dispatch, '/users/me')
            .then(data => dispatch(fetchData(data)))
            .catch(error => dispatch(appError(error)));
    }
}

export function updateBlank(params) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axiosDispatch(dispatch, '/users/updateBlank', {orderItemId: params})
                .then((data) => {
                    if (data.errorMessage === null || data.errorMessage === "") {
                        dispatch(fetchBlankUpdate(data))
                    }
                    resolve(data)
                })
                .catch(error => dispatch(userExist(error)));
        })
    }
}

export function getDataArchive(params) {
    return dispatch => {
        return new Promise(resolve => {
            axiosDispatch(dispatch, '/users/getDataArchive', {orderItemId: params})
                .then((data) => {
                    dispatch(fetchBlankUpdate(data))
                    resolve(data)
                })
                .catch(error => dispatch(userExist(error)));
        })
    }
}

export function update(params) {
    return dispatch => {
        axiosDispatch(dispatch, '/users/update', params)
            .then(data => dispatch(fetchDataUpdate(data)))
            .catch(error => dispatch(userExist(error)));
    }
}

export function changeER(orderItemId,
                         orderItemBlankIds,
                         set) {

    let params = {
        orderItemId: orderItemId,
        orderItemBlankIds: orderItemBlankIds,
        set: set,
    };
    return dispatch => {
        return new Promise((resolve, reject)=> {
            dispatch(waitTicket(true))

            axiosDispatch(dispatch, '/users/changeElectronicRegistration', params)
                .then(data => {
                    dispatch(waitTicket(false))
                    dispatch(changeRegestration(data, params))
                    resolve();
                })
                .catch(error => {
                    dispatch(userExist(error))
                    reject();
                });
        })
    }
}


export function waitTicket(params) {
    return dispatch => dispatch(waitTicketAction(params));
}

export function updatePass(params) {
    return dispatch => {
        axiosDispatch(dispatch, '/users/updatePass', params)
            .then(data => dispatch(fetchDataPassword(data)))
            .catch(error => dispatch(fetchDataPasswordWrong(error)));
    }
}

export function fetchData(data) {
    return {
        type: types.USER_FETCH,
        payload: data,
    };
}

export function fetchDataUpdate(data) {
    return {
        type: types.USER_FETCH_UPDATE,
        payload: data,
    };
}

export function fetchBlankUpdate(data) {
    return {
        type: types.USER_FETCH_BLANK_UPDATE,
        payload: data,
    };
}

export function fetchDataPassword(data) {
    return {
        type: types.USER_FETCH_PASSWORD,
        payload: data,
    };
}

export function changeRegestration(data, params) {
    return {
        type: types.USER_CHANGE_REGISTRATION,
        payload: {
            data: data,
            params: params,
        },
    };
}

export function fetchDataPasswordWrong(error) {
    return {
        type: types.USER_FETCH_PASSWORD_WRONG,
        payload: error,
    };
}

export function userExist(error) {
    return {
        type: types.USER_EXIST,
        payload: error,
    };
}

export function getReservations() {
    return (dispatch,
            getState) => {
        if (getState().auth.jwt === null) return;
        axiosDispatch(dispatch, '/users/reservations')
            .then(data => {
                dispatch({
                    type: types.USER_RESERVATIONS_FETCH,
                    payload: data,
                })
            })
            .catch(error => {
                dispatch(appError(error))
            });
    }
}

export function getReservationsArchive() {
    return (dispatch,
            getState) => {
        if (getState().auth.jwt === null) return;
        axiosDispatch(dispatch, '/users/archive')
            .then(data => {
                dispatch({
                    type: types.USER_RESERVATIONS_ARCHIVE_FETCH,
                    payload: data,
                })
            })
            .catch(error => {
                dispatch(appError(error))
            });
    }
}

export function getReservationsArchiveDelete(id) {
    return (dispatch,
            getState) => {
        if (getState().auth.jwt === null) return;
        axiosDispatch(dispatch, '/archive/remove',{orderId:id})
            .then(data => {
                console.log(data)
                dispatch({
                    type: types.USER_RESERVATIONS_ARCHIVE_DELETE,
                    payload: id,
                })
            })
            .catch(error => {
                dispatch(appError(error))
            });
    }
}

export function ArchiveDelete(id) {
    return {
        type: types.USER_RESERVATIONS_ARCHIVE_DELETE,
        payload: id,
    };
}

export function getTasks() {
    return (dispatch,
            getState) => {
        if (getState().auth.jwt === null) return;

        axiosDispatch(dispatch, '/users/tasks')
            .then(data => dispatch({
                type: types.USER_TASK_FETCH,
                payload: data,
            }))
            .catch(error => dispatch(appError(error)));
    }
}

export function authorizePayment() {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/authorize')
            .catch(error => dispatch(appError(error)));
    }
}

export function unAuthorizePayment() {
    return dispatch => {
        axiosDispatch(dispatch, '/yandex/unAuthorize')
            .then(data => dispatch(fetchData(data)))
            .catch(error => dispatch(appError(error)));
    }
}
