import {connect} from 'react-redux';
import Empty from '../../components/Search/Empty';
import {paramSet} from './../../actions/Booking';

const mapStateToProps =
  state => ({
    steps  : state.steps,
    booking: state.booking,
  });
export default connect(mapStateToProps, {paramSet})(Empty);
