const initialState = [
    {
        lable: 'Оплачено',
        value: 'succeeded'
    },
    {
        lable: 'Ожидает подтверждения',
        value: 'waiting_for_capture'
    },
    {
        lable: 'Возвращен',
        value: 'refunded'
    },
    {
        lable: 'Покупка билетов завершилась неудачей',
        value: 'errorCode'
    },
];

export default (state = initialState,
                action) => {

    switch (action.type) {

        default:
            return state;
    }
};
