import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Widget from '../containers/Bag/Widget';

class Footer extends PureComponent {

  render() {
    let cookie = getCookie('cookie') ? false : true;

    function setCookie() {
      var element = document.getElementById('cookie');
      element.classList.add('d-none');
      let name = 'cookie';
      let value = 'true';
      let days = '90';
      var expires = '';
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (
            days * 24 * 60 * 60 * 1000
        ));
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (
          value || ''
      ) + expires + '; path=/';
    }

    function getCookie(name) {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0;
           i < ca.length;
           i++
      ) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    return (
        <footer className="footer">
          <div className="container">
            <div className="footer__content">
              <div className="row align-items-center">
                <div className="col-md-4 col-first">
                  <div className="footer__text">
                    Вопросы, жалобы, пожелания!
                  </div>
                  <div className="footer__link footer__link-first">
                    <Link to="/contacts">Напишите нам</Link>
                  </div>
                  {/*<div className="footer__text">*/}
                  {/*  Справочная РЖД:*/}
                  {/*</div>*/}
                  {/*<div className="footer__link footer__link-phone">*/}
                  {/*  <a href="tel:88007750000">8 800 775 00 00</a>*/}
                  {/*  <br/>*/}
                  {/*  <small style={{fontSize: '12px'}}*/}
                  {/*         className={'text-white'}> (звонок бесплатный для всех*/}
                  {/*    регионов РФ)*/}
                  {/*  </small>*/}
                  {/*</div>*/}
                </div>
                <div className="col-md-4">
                  <a href="#" className="footer__logo">
                    <img src="img/logo.png" alt="" className="img-fluid"/>
                  </a>
                </div>
                <div className="col-md-4 col-last">
                  <div className="row">
                    <div className="col-md-8 ">
                      <div className="footer__text"  style={{fontSize: '14px'}}>
                        С использованием <br/>ВЕБ-системы «ИМ»
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <a href="#" className="footer__logo footer__logo--small">
                        <img src="img/im_logo.png" alt="официальный партнер ИМ"
                             className="img-fluid"/>
                      </a>
                    </div>
                  </div>
                    <div className="row" style={{paddingTop: '10px'}}>
                      <div className="col-md-8">
                        <div className="footer__text"  style={{fontSize: '14px'}}>
                          Официальный Партнер РЖД
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <a href="#" className="footer__logo footer__logo--small">
                          <img src="img/logo_rzd.jpg" alt="официальный партнер РЖД"
                               className="img-fluid"/>
                        </a>
                      </div>
                    </div>
                  <div className="row">
                    <div className="col footer__link footer__link-first">
                      <Link className="text-white" to="/rules">Правила
                        оформления электронного билета</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row copyright justify-content-around">
              <div className="col">
                <div className="container">
                  <div className="row copyright align-content-center">
                    <div className="col flexible">
                      <div className="text-left text">&#9400; Copyright</div>
                    </div>
                    <div className="col flexible">
                      <div className="text-left text">
                        <Link className="text-white" to="/conditions">Политика
                          конфиденциальности</Link>
                      </div>
                    </div>
                    <div className="col flexible">
                      <div className="text-left text">
                        <Link className="text-white" to="/public-offer">Публичная
                          оферта</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            getCookie('cookie') ? null : (
                <div className='cookie' id='cookie'>
                  <p>
                    Используя наш сайт, Вы даете согласие на обработку файлов
                    cookie и пользовательских
                    данных. Если Вы не хотите, чтобы Ваши данные обрабатывались,
                    пожалуйста покиньте сайт.
                  </p>
                  <div onClick={() => setCookie()}>Закрыть</div>
                </div>
            )
          }
          <Widget/>
        </footer>
    );
  }
}

export default withRouter(Footer);
