import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Bag from './../../containers/Bag/Nav';
import Auth from './../../containers/Auth/Nav';
import DatePicker from 'react-date-picker';
import Select from 'react-select';

class Navigation extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            menu: false,
        }

        this.fromRef = React.createRef();
        this.toRef = React.createRef();
    }

    handleSet = (key,
                 value) => {
        this.props.paramSet(key, value);
    }

    handleSearch = () => {
        if (this.props.refScroll) {
            this.props.refScroll.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
        const {destinationFrom, destinationTo, dateFrom} = this.props.booking;
        if (destinationFrom === null || destinationTo === null) {
            return false;
        }
        if (dateFrom === null) {
            this.props.modalOpen('SearchErrorDate');
            return false;
        }
        if (destinationFrom === destinationTo) {
            this.props.modalOpen('SearchErrorTown');
            return false;
        }

        let flag = false;
        this.props.booking.dateTo !== null ? flag = true : flag = false;
        this.props.paramSet('isSearchBack', flag);
        this.props.getList({
            destinationFrom,
            destinationTo,
            date: dateFrom,
        });
        this.props.history.push('/search');
        this.props.paramSet('step', 0);
    }

    toggleMenuHandler = () => {
        this.setState({
            menu: !this.state.menu,
        });
    }

    handleDestinationSwap =
        event => {
            event.preventDefault();
            let fromValue = this.fromRef.current.props.value;
            let toValue = this.toRef.current.props.value;
            if (toValue !== undefined && fromValue !== undefined) {
                this.handleSet('destinationFrom', toValue.value);
                this.handleSet('destinationTo', fromValue.value);
            }
        }

    render() {
        const cities = this.props.cities;
        const addClass = this.props.addClass;
        const {destinationFrom, destinationTo, dateFrom, dateTo} = this.props.booking,
            url = new URL(window.location.href);
        const dateNow = new Date();
        const dateNextYear = new Date(dateNow.getFullYear() + 1, dateNow.getMonth(), dateNow.getDate());

        // Mobile menu
        const menuCls = ['header__nav-menu'];
        const burgerCls = ['nav-burger'];
        if (this.state.menu) {
            menuCls.push('open-mobile');
        }
        if (this.state.menu) {
            burgerCls.push('open');
        }

        return (<header className={addClass ? addClass + ' header' : 'header'}>
                <div className="container">
                    <div className="row header__top">
                        <div className="col-lg-2 col-6">
                            <Link to="/" className="logo">
                                <img src="img/logo.png" alt="" className="img-fluid"/>
                            </Link>
                        </div>
                        <div className="col-lg-10 col-6">
                            <nav className="header__nav">
                                <div className={burgerCls.join(' ')} onClick={this.toggleMenuHandler}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className={menuCls.join(' ')}>
                                    <div className="header__nav-list">
                                        {/*<Link*/}
                                            {/*className={(url.pathname === '/tasks' ? 'active' : '') + ' nav-item nav-link'}*/}
                                            {/*to="/tasks">Лист ожидания</Link>*/}
                                        {/*<Link*/}
                                            {/*className={(url.pathname === '/alarm' ? 'active' : '') + ' nav-item nav-link'}*/}
                                            {/*to="/alarm">Будильник</Link>*/}
                                        {/*<Link*/}
                                            {/*className={(url.pathname === '/package' ? 'active' : '') + ' nav-item nav-link d-none'}*/}
                                            {/*to="/package">Пакетное оформление</Link>*/}
                                        <Link
                                            className={(url.pathname === '/promotions' ? 'active' : '') + ' nav-item nav-link'}
                                            to="/promotions">Акции</Link>
                                        <Link
                                            className={(url.pathname === '/contacts' ? 'active' : '') + ' nav-item nav-link'}
                                            to="/contacts">Обратная связь</Link>
                                        {/*<div className=' nav-item nav-link' onClick={() => this.dia()}>Тестовый пуск</div>*/}
                                        <Auth/>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="row header__search">
                        <div className="col-xl-3 col-lg-6 col-sm-6 col-parent">
                            <Select
                                options={cities}
                                placeholder="Откуда"
                                className="header__select"
                                classNamePrefix="header__select"
                                onChange={
                                    option => this.handleSet('destinationFrom', option.value)}
                                value={cities.find(el => el.value === destinationFrom)}
                                ref={this.fromRef}
                                noOptionsMessage={() => 'Ничего не найдено'}
                            />
                            <div className="towns ">
                                <Link to={'#'}
                                      onClick={() => this.handleSet('destinationFrom', '2000000')}>Москва</Link>
                                <Link to={'#'} onClick={() => this.handleSet('destinationFrom', '2004600')}>Тверь</Link>
                                <Link to={'#'}
                                      onClick={() => this.handleSet('destinationFrom', '2004000')}>Санкт-Петербург</Link>
                            </div>
                            <div className="swap">
                                <a href="#"
                                   onClick={event => this.handleDestinationSwap(event)}
                                   className="swap-btn">
                                    <img src="img/icons/search-arrows.png" alt=""/>
                                </a>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-sm-6 col-last">
                            <Select
                                options={cities}
                                placeholder="Куда"
                                className="header__select"
                                classNamePrefix="header__select"
                                onChange={
                                    option => this.handleSet('destinationTo', option.value)}
                                value={cities.find(el => el.value === destinationTo)}
                                ref={this.toRef}
                                noOptionsMessage={() => 'Ничего не найдено'}
                            />
                            <div className="towns">
                                {destinationFrom != '2000000' &&
                                <Link to={'#'} onClick={() => this.handleSet('destinationTo', '2000000')}>Москва</Link>}
                                {destinationFrom != '2004600' &&
                                <Link to={'#'} onClick={() => this.handleSet('destinationTo', '2004600')}>Тверь</Link>}
                                {destinationFrom != '2004000' && <a href="#"
                                                                    onClick={() => this.handleSet('destinationTo', '2004000')}>Санкт-Петербург</a>}
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-sm-6 col-6 col-penultimate col_mobile">
                            <div className="header__datepicker-wrapper zindex2">
                                <DatePicker className={dateFrom ? 'header__datepicker opened' : 'header__datepicker'}
                                            onChange={
                                                date => this.handleSet('dateFrom', date)} value={dateFrom}
                                            minDate={dateNow} maxDate={dateNextYear} format="d-MM-y"/>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-sm-6 col-6 col-last col-penultimate col_mobile2">
                            <div className="header__datepicker-wrapper zindex2">
                                <DatePicker className={dateTo ? 'header__datepicker opened' : 'header__datepicker'}
                                            onChange={
                                                date => this.handleSet('dateTo', date)} value={dateTo}
                                            minDate={dateFrom ? dateFrom : dateNow} maxDate={dateNextYear}
                                            format="d-MM-y"/>
                            </div>
                        </div>
                        <div className="col">
                            <button className="btn btn__main" type="button" onClick={this.handleSearch}>Найти билеты
                            </button>
                        </div>
                    </div>
                </div>
                {/* <div className="lang d-flex">
         <a href="#" className="lang__item">En</a>
         <a href="#" className="lang__item">Ru</a>
         </div> */}
            </header>
        );
    }

    dia() {
        this.props.action();
        return undefined;
    }
}

export default withRouter(Navigation);
