import {connect} from 'react-redux';
import {authorizePayment, update, updatePass} from './../../actions/User';
import Account from './../../components/Cabinet/Account';
import {unAuthorizePayment} from '../../actions/User';

const mapStateToProps =
          state => (
              {
                error: state.user.error,
                ...state.user.data,
                message: state.user.message,
                main: state.user.main,
              }
          );

export default connect(mapStateToProps, {
  updatePass,
  update,
  authorizePayment,
  unAuthorizePayment,
})(Account);
