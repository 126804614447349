import {connect} from 'react-redux';
import {authorizePayment} from './../actions/User';
import {modalOpen} from './../actions/Auth';
import Checkout from '../components/Checkout';
import {paramSet, clearNew} from './../actions/Booking';
import {createNew} from '../actions/Bag';

const mapStateToProps =
    state => {
        return ({
            user: state.user.data,
            cities: state.cities,
            jwt: state.auth.jwt,
            booking: state.booking,
        });
    }

export default connect(mapStateToProps, {
    createNew,
    clearNew,
    paramSet,
    authorizePayment,
    modalOpen,
})(Checkout);
