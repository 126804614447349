import {connect} from 'react-redux';
import {remove} from './../../actions/Tasks';
import Tasks from './../../components/Cabinet/Tasks';
import {getTasks} from './../../actions/User';
import {cancelTask} from '../../actions/Bag';

const mapStateToProps =
  state => {
    return {
      list: state.user.tasks,
      cities: state.cities,
    };
  }

export default connect(mapStateToProps, {
  remove,
  getTasks,
  cancelTask,
})(Tasks);
