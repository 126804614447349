import React, {PureComponent, Fragment, useState} from 'react';
import Tabs from './Tabs';
import Helmet from 'react-helmet';
import serviceClassOptions from '../Package/_serviceClassOptions';
import {dateFormatter, getTariffName} from '../Helpers';

class Tasks extends PureComponent {

  constructor(props) {
    super(props);
    this.props.getTasks();
    this.state = {
      accordionTarget: null,
      confirm        : false,
      refundId       : 0,
      errorMessage   : null,
      message        : null,
      classs         : null,
    };
  }

  getStatus = (el) => {
    let status = '';
    var datastatus = el.data.status.replace(/\s+/g, '');
    switch (datastatus) {
      case 'success':
        status = 'Выкуплен';
        break;
      case 'new':
        status = 'В работе';
        break;
    }
    return status;
  }

  getStatusName = (status) => {
    let result = status;
    switch (status) {
      case 'new': {
        result = 'Ожидает выкупа'
        break;
      }
      case 'success': {
        result = 'Выкуплен'
        break;
      }
    }
    return result;
  }
  initials = (str) => {
    return str.split(/\s+/)
              .map((w,
                    i) => i ? w.substring(0, 1)
                               .toUpperCase() + '.' : w)
              .join(' ');
  }
  handleConfirmModal = (toggler,
                        id) => {
    this.setState({confirm  : toggler,
                    refundId: id,
                  });
  }
  handleTicketBack = () => {
    if (this.state.refundId === null) {
      return;
    }
    else {
      this.props.cancelTask(this.state.refundId)
          .then(() => {
            this.handleConfirmModal(false);
            this.setState({
                            message     : true,
                            classs      : 'bg-success text-white',
                            errorMessage: 'Задание успешно отменено.',
                          });
            setTimeout(() => {
              this.setState({message: true});
            }, 5000)
          })
          .catch((error) => {
            this.handleConfirmModal(false);
            this.setState({message      : true,
                            classs      : 'bg-danger',
                            errorMessage: error,
                          });
            setTimeout(() => {
              this.setState({message: true});
            }, 5000)
          })
    }
  }

  render() {
    const cities = this.props.cities;
    return (<Fragment>
        <Helmet>
          <title>Личный кабинет</title>
        </Helmet>
        <Tabs tab="tasks"/>
        <div className="cabinet-page__content tasks">
          <div className="flight flight__last-step">
            {this.props.list && this.props.list.map(el => (<div className="form" key={el.data.id}>
                  <div className="d-flex  text justify-content-between flex-column flex-sm-row px-1">
                    <div>Отправление: {dateFormatter(el.data.date.replace(' 00:00:00', ''))}</div>
                    <div className="h5 d-flex flex-column flex-sm-row ">
                      <div>
                        {cities.find(ele => ele.value === el.data.destinationfrom.replace(/\s+/g, '')).label}
                      </div>
                      <span className="icon-item px-4 ">
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                        </span>
                      <div>
                        {cities.find(ele => ele.value === el.data.destinationto.replace(/\s+/g, '')).label}
                      </div>
                    </div>
                    <div className='text-left text-sm-right'>
                      Класс {serviceClassOptions.find(ele => ele.value === el.data.serviceclass.replace(/\s+/g, '')).label}
                    </div>
                    <div
                      className={this.getStatus(el) === 'Выкуплен' ? 'text-success' : null}>{this.getStatus(el)}</div>
                  </div>
                  <div className="passengers-wrapper table-responsive">
                    <table className='table table-sm'>
                      <thead>
                      <tr className='text-center'>
                        <th className='text-left'>Пассажир</th>
                        <th>Документ</th>
                        <th>Пол</th>
                        <th className="text-nowrap">Дата рождения</th>
                        <th>Тариф</th>
                        {!el.data.together &&
                        <th>Статус</th>
                        }
                      </tr>
                      </thead>
                      <tbody>
                      {el.pasangers.map((pasanger,
                                         j) => (<Fragment key={pasanger.id}>
                          <tr className='text-center'>
                            <td
                              className='text-left'>{j + 1} {this.initials(pasanger.last_name + ' ' + pasanger.first_name + ' ' + pasanger.middle_name)} </td>
                            <td>{pasanger.document_number.replace(/.(?=.{4,}$)/g, '*')}</td>
                            <td>{pasanger.gender === 'Male' ? 'мужской' : 'женский'}</td>
                            <td>{dateFormatter(pasanger.birthdate)}</td>
                            <td>{getTariffName(pasanger.tariff)}</td>
                            {!el.data.together &&
                            <td>{this.getStatusName(pasanger.status)}</td>
                            }
                          </tr>
                        </Fragment>
                      ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex flex-column flex-sm-row mt-3">
                    <div className="text-secondary additional_info px-1 h6 pb-4 d-block w-100">
                      {el.data.timefrom || el.data.timeto ?
                        <div className="d-flex flex-column flex-sm-row">
                          Дополнительные параметры времени
                          <div className="d-flex">
                            {el.data.timefrom &&
                            <div>с {el.data.timefrom}</div>}
                            {el.data.timeto &&
                            <div>до {el.data.timeto}</div>}
                          </div>
                        </div> : null}
                      {el.data.trainnumber ?
                        <div>Поиск билетов на поезд {el.data.trainnumber} </div> : null}
                      <div>Покупать
                        билеты {el.data.together ? 'только вместе' : 'по отдельности'}</div>
                      <div className="d-flex task-data">
                        <div>Дата создания {dateFormatter(el.data.create_date)}</div>
                      </div>
                    </div>
                    <div>
                      <button className="btn btn__white mb-3"
                              onClick={() => this.handleConfirmModal(true, el.data.id)}>Отменить
                      </button>
                    </div>
                  </div>
                </div>

              ))}
          </div>
        </div>
        {this.state.confirm ? (<Fragment>
            <div className="modal modal--confirm show">
              <div className="modal-dialog modal-dialog--confirm">
                <div className="modal-content">
                  <h4>Вы уверены, что хотите отменить поиск билета?</h4>
                  <div className="confirm-modal__btn-wrapper">
                    <button className="btn btn__white" onClick={() => this.handleTicketBack()}>Да
                    </button>
                    <button className="btn btn__white"
                            onClick={() => this.handleConfirmModal(false)}>Отменить
                    </button>
                  </div>
                </div>
              </div>
              <div className="overlay overlay--confirm show"
                   onClick={() => this.handleConfirmModal(false)}></div>
            </div>
          </Fragment>
        ) : null}
        {this.state.message &&
        <div className={this.state.classs + ' toaster active'}>
          {this.state.errorMessage}
        </div>
        }
      </Fragment>
    );

  }
}

export default Tasks;
