import React, {Fragment, PureComponent} from 'react';
import {Helmet} from 'react-helmet';
import Navigation from './../containers/Navigation/Cabinet';
import Progress from './../containers/Search/Progress';
import * as time from '../utility/time';
import Passenger from './Passengers/Form';
import Car from './Cars';
import withRouter from 'react-router-dom/es/withRouter';

class Checkout extends PureComponent {

  constructor(props) {
    super(props);
    this.props.paramSet('step', 3);
    const {forwardData, returnData} = props.booking;
    const t = {
      availableSeats: '',
      selectedSeats: '',
      selectedStart: '',
      selectedEnd: '',
    };
    const trainForwardConst = forwardData !== undefined ? forwardData : t;
    const trainReturnConst = returnData !== undefined ? returnData : t;

    this.state = {
      passengers: [
        {
          email: props.user.email ? props.user.email : '',
          phone: props.user.phone ? props.user.phone : '',
          tariff: 'Adult',
          tariffReturn: 'Adult',
          load: true,
          isSaved: true,
          birthdate: '',
          documentNumber: '',
          documentType: '',
          firstName: '',
          gender: '',
          lastName: '',
          middleName: '',
          nationality: '',
          ref: React.createRef(),
        },
      ],
      trainForward: trainForwardConst,
      trainReturn: trainReturnConst,
      availableSeatsForward: trainForwardConst.availableSeats,
      selectedSeatsForward: trainForwardConst.selectedSeats,
      selectedStartSeatsForward: trainForwardConst.selectedStart,
      selectedEndSeatsForward: trainForwardConst.selectedEnd,
      additionalPlaceRequirementsForward: trainForwardConst.selectedEnd,

      availableSeatsReturn: trainReturnConst.availableSeats,
      selectedSeatsReturn: trainReturnConst.selectedSeats,
      selectedStartSeatsReturn: trainReturnConst.selectedStart,
      selectedEndSeatsReturn: trainReturnConst.selectedEnd,
      additionalPlaceRequirementsReturn: trainReturnConst.selectedEnd,
      error: null,
      process: true,
      load: true,
      visible: false,
    };
  };

  updateStateTrains = () => {
    const {
            trainForward,
            trainReturn,
            selectedStartSeatsForward,
            selectedEndSeatsForward,
            selectedStartSeatsReturn,
            selectedEndSeatsReturn,
          } = this.state;

    trainForward.selectedStart = selectedStartSeatsForward;
    trainForward.selectedEnd = selectedEndSeatsForward;

    trainReturn.selectedStart = selectedStartSeatsReturn;
    trainReturn.selectedEnd = selectedEndSeatsReturn;

    this.setState({
                    trainForward,
                    trainReturn,
                  });
    this.props.paramSet('forwardData', trainForward);
    this.props.paramSet('returnData', trainReturn);

  };

  passengerRemove =
      index => () => {
        let passengers = this.state.passengers.filter((
                                                          el,
                                                          i) => i !== index);
        this.setState({passengers});
      };

  passengerUpdate =
      i => (
          key,
          value) => {
        let passengers = [...this.state.passengers];
        passengers[i][key] = value;
        this.setState({passengers});
      };

  passengerAdd = () => {
    const countAdult = this.countAdult();
    if (countAdult >= 4) {
      this.setError('Достигнуто максимальное количество мест в заказе (4)');
      return false;
    }
    let passengers = [...this.state.passengers];
    const {user} = this.props;
    const ref = React.createRef();
    let t = {
      email: user.email ? user.email : '',
      phone: user.phone ? user.phone : '',
      tariff: 'Adult',
      tariffReturn: 'Adult',
      isSaved: true,
      birthdate: '',
      documentNumber: '',
      documentType: '',
      firstName: '',
      gender: '',
      lastName: '',
      middleName: '',
      nationality: '',
      ref: ref,
    };
    passengers.push(t);
    this.setState({passengers});
    setTimeout(() => {
      ref.current.scrollIntoView({
                                   behavior: 'smooth',
                                   block: 'start',
                                 });
    }, 100);
  };

  seatSelectForvard = (
      seatSelect,
      price,
      fee,
      additionalPlaceRequirements) => {
    const seat                                                 = parseInt(
        seatSelect),
          {selectedStartSeatsForward, selectedEndSeatsForward} = this.state,
          {forwardData}                                        = this.props.booking;
    if (forwardData.carType.replace('С', 'C') === '91C' &&
        this.state.selectedStartSeatsReturn !== 27) {
      this.setState({
                      selectedStartSeatsReturn: 27,
                      selectedEndSeatsReturn: 30,
                      selectedSeatsReturn: [
                        '27',
                        '28',
                        '29',
                        '30',
                      ],
                    });
    } else if (forwardData.classesName === 'Купе-сьют') {
      if (forwardData.selectedStart === 45) {
        this.setState({
                        selectedStartSeatsReturn: 45,
                        selectedEndSeatsReturn: 47,
                        selectedSeatsReturn: [
                          '45',
                          '47',
                        ],
                      });
      } else if (forwardData.selectedStart === 49) {
        this.setState({
                        selectedStartSeatsReturn: 49,
                        selectedEndSeatsReturn: 51,
                        selectedSeatsReturn: [
                          '49',
                          '51',
                        ],
                      });
      }
    } else {
      const childSeatsAll = [
        45,
        46,
        47,
        48,
        49,
        51,
        52,
      ];
      if ((
              this.state.trainForward.selectedCarNumber === '10' ||
              this.state.trainForward.selectedCarNumber === '20'
          ) &&
          childSeatsAll.includes(seat)
      ) {
        const childSeats = [
          45,
          46,
          47,
          48,
          51,
          52,
        ];

        if (seat == 49) {
          this.setState({
                          selectedStartSeatsForward: 49,
                          selectedEndSeatsForward: 49,
                          selectedSeatsForward: ['49'],
                        });
        } else if (childSeats.includes(seat)) {
          if (selectedStartSeatsForward !== selectedEndSeatsForward ||
              !childSeats.includes(selectedStartSeatsForward) ||
              !childSeats.includes(selectedEndSeatsForward)
          ) {
            this.setState({
                            selectedStartSeatsForward: seat,
                            selectedEndSeatsForward: seat,
                            selectedSeatsForward: [seatSelect],
                          });
          } else {
            let select = [];
            if (selectedStartSeatsForward < seatSelect) {
              select = childSeats.filter(function(x) {
                return x >= selectedStartSeatsForward && x <= seatSelect;
              });
              this.setState({
                              selectedStartSeatsForward: selectedStartSeatsForward,
                              selectedEndSeatsForward: seat,
                              selectedSeatsForward: select.map(String),
                            });
            } else {
              select = childSeats.filter(function(x) {
                return x >= seatSelect && x <= selectedStartSeatsForward;
              });
              this.setState({
                              selectedStartSeatsForward: seat,
                              selectedEndSeatsForward: selectedEndSeatsForward,
                              selectedSeatsForward: select.map(String),
                            });

            }
          }
        }

      } else {
        if (additionalPlaceRequirements === 'WithPets' ||
            this.state.additionalPlaceRequirementsForward === 'WithPets') {
          if (additionalPlaceRequirements !== 'WithPets' ||
              this.state.additionalPlaceRequirementsForward !== 'WithPets') {
            this.setState({
                            selectedStartSeatsForward: seat,
                            selectedEndSeatForward: seat,
                            selectedSeatsForward: [seatSelect],
                          });
          } else if (additionalPlaceRequirements === 'WithPets' &&
              this.state.additionalPlaceRequirementsForward === 'WithPets') {
            let select = [];
            if (seat < selectedStartSeatsForward) {
              for (let i = seat;
                   i <= selectedStartSeatsForward;
                   i++
              ) {
                select.push(i.toString());
              }
              this.setState({
                              selectedStartSeatsForward: seat,
                              selectedEndSeatForward: selectedStartSeatsForward,
                              selectedSeatsForward: select,
                            });
            } else {
              for (let i = selectedStartSeatsForward;
                   i <= seat;
                   i++
              ) {
                select.push(i.toString());
              }
              this.setState({
                              selectedStartSeatsForward: selectedStartSeatsForward,
                              selectedEndSeatForward: seat,
                              selectedSeatsForward: select,
                            });
            }
          }
        } else {
          if (this.state.trainForward.selectedCarNumber === '10' ||
              this.state.trainForward.selectedCarNumber === '20'
          ) {
            if (selectedStartSeatsForward !== selectedEndSeatsForward) {
              this.setState({
                              selectedStartSeatsForward: seat,
                              selectedEndSeatsForward: seat,
                              selectedSeatsForward: [seatSelect],
                            });
            } else {
              let select = [];
              if (selectedStartSeatsForward && (
                  (
                      (
                          seat >= 1 && seat <= 24
                      ) && (
                          selectedStartSeatsForward >= 33 &&
                          selectedStartSeatsForward <= 44
                      )
                  ) ||
                  (
                      (
                          seat >= 33 && seat <= 44
                      ) && (
                          selectedStartSeatsForward >= 1 &&
                          selectedStartSeatsForward <= 24
                      )
                  )
              )) {
                this.setState({
                                selectedStartSeatsForward: seat,
                                selectedEndSeatsForward: seat,
                                selectedSeatsForward: [seatSelect],
                              });
              } else if ((
                      (
                          seat >= 1 && seat <= 24
                      ) && (
                          selectedStartSeatsForward >= 1 &&
                          selectedStartSeatsForward <=
                          24
                      )
                  ) ||
                  (
                      (
                          seat >= 33 && seat <= 44
                      ) && (
                          selectedStartSeatsForward >= 33 &&
                          selectedStartSeatsForward <= 44
                      )
                  )
              ) {
                if (seat < selectedStartSeatsForward) {
                  for (let i = seat;
                       i <= selectedStartSeatsForward;
                       i++
                  ) {
                    select.push(i.toString());
                  }
                  this.setState({
                                  selectedStartSeatsForward: seat,
                                  selectedEndSeatsForward: selectedStartSeatsForward,
                                  selectedSeatsForward: select,
                                });
                } else {
                  for (let i = selectedStartSeatsForward;
                       i <= seat;
                       i++
                  ) {
                    select.push(i.toString());
                  }
                  this.setState({
                                  selectedStartSeatsForward: selectedStartSeatsForward,
                                  selectedEndSeatsForward: seat,
                                  selectedSeatsForward: select,
                                });
                }
              }
            }
          } else {
            if (selectedStartSeatsForward !== selectedEndSeatsForward) {
              this.setState({
                              selectedStartSeatsForward: seat,
                              selectedEndSeatsForward: seat,
                              selectedSeatsForward: [seatSelect],
                            });
            } else {
              let select = [];
              if (seat < selectedStartSeatsForward) {
                for (let i = seat;
                     i <= selectedStartSeatsForward;
                     i++
                ) {
                  select.push(i.toString());
                }
                this.setState({
                                selectedStartSeatsForward: seat,
                                selectedEndSeatsForward: selectedStartSeatsForward,
                                selectedSeatsForward: select,
                              });
              } else {
                for (let i = selectedStartSeatsForward;
                     i <= seat;
                     i++
                ) {
                  select.push(i.toString());
                }
                this.setState({
                                selectedStartSeatsForward: selectedStartSeatsForward,
                                selectedEndSeatsForward: seat,
                                selectedSeatsForward: select,
                              });
              }
            }
          }
        }
      }
    }
    this.setState({
                    fee,
                    additionalPlaceRequirementsForward: additionalPlaceRequirements,
                  });
    this.updateStateTrains();
  };

  seatSelectReturn = (
      seatSelect,
      price,
      fee,
      additionalPlaceRequirements) => {
    const seat = parseInt(seatSelect);
    const {selectedStartSeatsReturn, selectedEndSeatsReturn} = this.state;
    const {returnData} = this.props.booking;
    if (returnData.carType.replace('С', 'C') === '91C') {
      this.setState({
                      selectedStartSeatsReturn: 27,
                      selectedEndSeatsReturn: 30,
                      selectedSeatsReturn: [
                        '27',
                        '28',
                        '29',
                        '30',
                      ],
                    });
    } else if (returnData.classesName === 'Купе-сьют') {
      if (returnData.selectedStart === 45) {
        this.setState({
                        selectedStartSeatsReturn: 45,
                        selectedEndSeatsReturn: 47,
                        selectedSeatsReturn: [
                          '45',
                          '47',
                        ],
                      });
      } else if (returnData.selectedStart === 49) {
        this.setState({
                        selectedStartSeatsReturn: 49,
                        selectedEndSeatsReturn: 51,
                        selectedSeatsReturn: [
                          '49',
                          '51',
                        ],
                      });
      }
    } else {
      const childSeatsAll = [
        45,
        46,
        47,
        48,
        49,
        51,
        52,
      ];
      if ((
              this.state.trainReturn.selectedCarNumber === '10' ||
              this.state.trainReturn.selectedCarNumber === '20'
          ) &&
          childSeatsAll.includes(seat)
      ) {
        const childSeats = [
          45,
          46,
          47,
          48,
          51,
          52,
        ];

        if (seat == 49) {
          this.setState({
                          selectedStartSeatsReturn: 49,
                          selectedEndSeatsReturn: 49,
                          selectedSeatsReturn: ['49'],
                        });
        } else if (childSeats.includes(seat)) {
          if (selectedStartSeatsReturn !== selectedEndSeatsReturn ||
              !childSeats.includes(selectedStartSeatsReturn) ||
              !childSeats.includes(selectedEndSeatsReturn)
          ) {
            this.setState({
                            selectedStartSeatsReturn: seat,
                            selectedEndSeatsReturn: seat,
                            selectedSeatsReturn: [seatSelect],
                          });
          } else {
            let select = [];
            if (selectedStartSeatsReturn < seatSelect) {
              select = childSeats.filter(function(x) {
                return x >= selectedStartSeatsReturn && x <= seatSelect;
              });
              this.setState({
                              selectedStartSeatsReturn: selectedStartSeatsReturn,
                              selectedEndSeatsReturn: seat,
                              selectedSeatsReturn: select.map(String),
                            });
            } else {
              select = childSeats.filter(function(x) {
                return x >= seatSelect && x <= selectedStartSeatsReturn;
              });
              this.setState({
                              selectedStartSeatsReturn: seat,
                              selectedEndSeatsReturn: selectedEndSeatsReturn,
                              selectedSeatsReturn: select.map(String),
                            });

            }
          }
        }

      } else {
        if (additionalPlaceRequirements === 'WithPets' ||
            this.state.additionalPlaceRequirementsReturn === 'WithPets') {
          if (additionalPlaceRequirements !== 'WithPets' ||
              this.state.additionalPlaceRequirementsReturn !== 'WithPets') {
            this.setState({
                            selectedStartSeatsReturn: seat,
                            selectedEndSeatsReturn: seat,
                            selectedSeatsReturn: [seatSelect],
                          });
          } else if (additionalPlaceRequirements === 'WithPets' &&
              this.state.additionalPlaceRequirementsReturn === 'WithPets') {
            let select = [];
            if (seat < selectedStartSeatsReturn) {
              for (let i = seat;
                   i <= selectedStartSeatsReturn;
                   i++
              ) {
                select.push(i.toString());
              }
              this.setState({
                              selectedStartSeatsReturn: seat,
                              selectedEndSeatsReturn: selectedStartSeatsReturn,
                              selectedSeatsReturn: select,
                            });
            } else {
              for (let i = selectedStartSeatsReturn;
                   i <= seat;
                   i++
              ) {
                select.push(i.toString());
              }
              this.setState({
                              selectedStartSeatsReturn: selectedStartSeatsReturn,
                              selectedEndSeatsReturn: seat,
                              selectedSeatsReturn: select,
                            });
            }
          }
        } else {
          if (this.state.trainReturn.selectedCarNumber === '10' ||
              this.state.trainReturn.selectedCarNumber === '20'
          ) {
            if (selectedStartSeatsReturn !== selectedEndSeatsReturn) {
              this.setState({
                              selectedStartSeatsReturn: seat,
                              selectedEndSeatsReturn: seat,
                              selectedSeatsReturn: [seatSelect],
                            });
            } else {
              let select = [];
              if (selectedStartSeatsReturn && (
                  (
                      (
                          seat >= 1 && seat <= 24
                      ) && (
                          selectedStartSeatsReturn >= 33 &&
                          selectedStartSeatsReturn <= 44
                      )
                  ) ||
                  (
                      (
                          seat >= 33 && seat <= 44
                      ) && (
                          selectedStartSeatsReturn >= 1 &&
                          selectedStartSeatsReturn <= 24
                      )
                  )
              )) {
                this.setState({
                                selectedStartSeatsReturn: seat,
                                selectedEndSeatsReturn: seat,
                                selectedSeatsReturn: [seatSelect],
                              });
              } else if ((
                      (
                          seat >= 1 && seat <= 24
                      ) && (
                          selectedStartSeatsReturn >= 1 &&
                          selectedStartSeatsReturn <=
                          24
                      )
                  ) ||
                  (
                      (
                          seat >= 33 && seat <= 44
                      ) && (
                          selectedStartSeatsReturn >= 33 &&
                          selectedStartSeatsReturn <= 44
                      )
                  )
              ) {
                if (seat < selectedStartSeatsReturn) {
                  for (let i = seat;
                       i <= selectedStartSeatsReturn;
                       i++
                  ) {
                    select.push(i.toString());
                  }
                  this.setState({
                                  selectedStartSeatsReturn: seat,
                                  selectedEndSeatsReturn: selectedStartSeatsReturn,
                                  selectedSeatsReturn: select,
                                });
                } else {
                  for (let i = selectedStartSeatsReturn;
                       i <= seat;
                       i++
                  ) {
                    select.push(i.toString());
                  }
                  this.setState({
                                  selectedStartSeatsReturn: selectedStartSeatsReturn,
                                  selectedEndSeatsReturn: seat,
                                  selectedSeatsReturn: select,
                                });
                }
              }
            }
          } else {
            if (selectedStartSeatsReturn !== selectedEndSeatsReturn) {
              this.setState({
                              selectedStartSeatsReturn: seat,
                              selectedEndSeatsReturn: seat,
                              selectedSeatsReturn: [seatSelect],
                            });
            } else {
              let select = [];
              if (seat < selectedStartSeatsReturn) {
                for (let i = seat;
                     i <= selectedStartSeatsReturn;
                     i++
                ) {
                  select.push(i.toString());
                }
                this.setState({
                                selectedStartSeatsReturn: seat,
                                selectedEndSeatsReturn: selectedStartSeatsReturn,
                                selectedSeatsReturn: select,
                              });
              } else {
                for (let i = selectedStartSeatsReturn;
                     i <= seat;
                     i++
                ) {
                  select.push(i.toString());
                }
                this.setState({
                                selectedStartSeatsReturn: selectedStartSeatsReturn,
                                selectedEndSeatsReturn: seat,
                                selectedSeatsReturn: select,
                              });
              }
            }
          }
        }
      }
    }
    this.setState({
                    fee,
                    additionalPlaceRequirementsReturn: additionalPlaceRequirements,
                  });
    this.updateStateTrains();

  };
  handleBuy = () => {
    if (this.props.jwt === null) {
      this.props.modalOpen('signIn');
      return;
    }
    const countAdult = this.countAdult();
    if (countAdult > 4) {
      this.setError('Превышено максимальное количество мест в заказе (4)');
      return false;
    }
    if (this.youngMan()) {
      return false;
    }
    const {passengers, trainForward, selectedStartSeatsForward, selectedEndSeatsForward, trainReturn, selectedStartSeatsReturn, selectedEndSeatsReturn} = this.state;
    const only_russian = 'Ввод данных разрешен только на русском языке';

    let flag    = false,
        m_error = '';

    let passengersorder = passengers.map((el, index) => {
                                           delete el['ref'];
                                           if (el['birthdate'] === '') {
                                             flag = true;
                                             m_error = 'Введите дату рождения';
                                           } else {
                                             var d     = new Date(el['birthdate']),
                                                 month = '' + (
                                                     d.getMonth() + 1
                                                 ),
                                                 day   = '' + d.getDate(),
                                                 year  = d.getFullYear();
                                             el['birthdate'] = year >= 1900 ? el['birthdate'] : 1900 + '-' + month +
                                                 '-' + day;
                                           }

                                           if (el['firstName'] === '') {
                                             flag = true;
                                             m_error = 'Введите имя';
                                           }

                                           if (el['lastName'] === '') {
                                             flag = true;
                                             m_error = 'Введите фамилию';
                                           }
                                           let bd         = new Date(el['birthdate']),
                                               firstName  = el['firstName'],
                                               lastName   = el['lastName'],
                                               middleName = el['middleName'] ? el['middleName'] : '';

                                           if (el['documentType'] === '') {
                                             flag = true;
                                             m_error = 'Выберите тип дкумента';
                                           } else {

                                             switch (el['documentType']) {
                                               case 'RussianPassport':
                                               case 'UssrPassport':
                                               case 'BirthCertificate':
                                               case 'MilitaryCard':
                                               case 'MilitaryOfficerCard':
                                               case 'RussianTemporaryIdentityCard':
                                                 if (!isCyrillic(firstName) || !isCyrillic(lastName) ||
                                                     !isCyrillic(middleName)) {
                                                   flag = true;
                                                   m_error = only_russian;
                                                 }
                                                 break;
                                               case 'ForeignPassport':
                                                 if (el['nationality'] === 'RU') {
                                                   flag = true;
                                                   m_error = 'Выбранный тип документа недоступен для граждан РФ';
                                                 }
                                                 break;
                                               default:
                                                 break;
                                             }
                                           }
                                           if (el['documentNumber'] === '') {
                                             flag = true;
                                             m_error = 'Введите номер документа';
                                           } else {
                                             switch (el['documentType']) {
                                               case 'RussianPassport':
                                                 if (!isDate18orMoreYearsOld(bd.getDate(), (
                                                     bd.getMonth() + 1
                                                 ), bd.getFullYear(), 14)) {
                                                   flag = true;
                                                   m_error = 'Документ разрешен только для пассажиров старше 14 лет';
                                                 }
                                                 break;
                                               case 'BirthCertificate':
                                                 if (!isBirth(el['documentNumber'])) {
                                                   flag = true;
                                                   m_error = 'Некорректный формат свидетельства о рождении';
                                                 }
                                                 break;
                                               case 'MilitaryCard':
                                                 if (!isMilitary(el['documentNumber'])) {
                                                   flag = true;
                                                   m_error = 'Некорректный формат номера военного билета';
                                                 }
                                                 break;
                                               case 'SailorPassport':
                                                 if (!isSailor(el['documentNumber'])) {
                                                   flag = true;
                                                   m_error = 'Некорректный формат паспорта моряка';
                                                 }
                                                 break;
                                             }
                                             const docNumberTest = passengers.findIndex(
                                                 (item, f1i) => {
                                                   if (f1i === index) return false;
                                                   else return (
                                                       item['tariff'] !== 'Adult' && item['documentNumber'] ===
                                                       el['documentNumber'] && item['birthdate'] !== el['birthdate']
                                                   );
                                                 });
                                             if (docNumberTest !== -1) {
                                               flag = true;
                                               m_error = 'Запрещенно указание одного и того же номера докумена для двух и более разных пассажиров';
                                             }
                                           }
                                           if (!isDate18orMoreYearsOld(bd.getDate(), (
                                                   bd.getMonth() + 1
                                               ), bd.getFullYear(), 10)
                                               && el['documentType'] !== 'BirthCertificate'
                                               && el['documentType'] !== 'RussianForeignPassport'
                                               && el['documentType'] !== 'ForeignPassport'
                                               && el['documentType'] !== 'RussianTemporaryIdentityCard'
                                               && el['documentType'] !== 'ReturnToCisCertificate'
                                               && el['documentType'] !== 'ResidencePermit'
                                               && el['documentType'] !== 'StatelessPersonIdentityCard'
                                           ) {
                                             flag = true;
                                             m_error = 'Документ разрешен только для пассажиров старше 10 лет';
                                           }

                                           if (el['email'] === '') {
                                             flag = true;
                                             m_error = 'Введите email';
                                           }
                                           if (el['firstName'] === '') {
                                             flag = true;
                                             m_error = 'Введите имя';
                                           }
                                           if (el['lastName'] === '') {
                                             flag = true;
                                             m_error = 'Введите фамилию';
                                           }
                                           if (el['gender'] === '') {
                                             flag = true;
                                             m_error = 'Выберите пол';
                                           }
                                           if (el['nationality'] === '') {
                                             flag = true;
                                             m_error = 'Страна выдачи документа';
                                           } else {
                                             switch (el['documentType']) {
                                               case 'RussianPassport':
                                               case 'UssrPassport':
                                               case 'BirthCertificate':
                                               case 'MilitaryCard':
                                               case 'RussianForeignPassport':
                                               case 'MilitaryOfficerCard':
                                               case 'SailorPassport':
                                               case 'RussianTemporaryIdentityCard':
                                                 if (el['nationality'] !== 'RU') {
                                                   flag = true;
                                                   m_error = 'Выбранный тип документа доступен только для граждан РФ';
                                                 }
                                                 break;
                                               default:
                                                 break;
                                             }
                                           }

                                           if (el['phone'] === '') {
                                             flag = true;
                                             m_error = 'Введите телефон';
                                           }
                                           if (el['tariff'] === '') {
                                             flag = true;
                                             m_error = 'Выберите тариф';
                                           }
                                           if (el['tariffReturn'] === '') {
                                             flag = true;
                                             m_error = 'Выберите тариф';
                                           }
                                           if (el['rzhdBonus']) {
                                             const rzhdBonusTest = passengers.findIndex((
                                                                                            item,
                                                                                            f1i) => {
                                               if (f1i === index)
                                                 return false;
                                               return item['rzhdBonus'] === el['rzhdBonus'] &&
                                                   item['documentNumber'] !== el['documentNumber'];
                                             });
                                             if (rzhdBonusTest !== -1) {
                                               flag = true;
                                               m_error = 'Запрещенно указание одного и того же номера карты для двух и более разных пассажиров';
                                             }
                                           }
                                           if (el['universalRzhdCard']) {
                                             const rzhduniversalRzhdCardTest = passengers.findIndex((
                                                                                                        item,
                                                                                                        f1i) => {
                                               if (f1i === index)
                                                 return false;
                                               return item['universalRzhdCard'] === el['universalRzhdCard'] &&
                                                   item['documentNumber'] !== el['documentNumber'];
                                             });
                                             if (rzhduniversalRzhdCardTest !== -1) {
                                               flag = true;
                                               m_error = 'Запрещенно указание одного и того же номера карты для двух и более разных пассажиров';
                                             }
                                             if ((
                                                 trainForward.carType == '91С' || trainReturn.carType == '91С'
                                             )) {
                                               flag = true;
                                               m_error = 'Нет возможности оформить ЭБ с применением Универсальной карты';
                                             }
                                           }
                                           if (el['businessTravel']) {
                                             const rzhdbusinessTravelTest = passengers.findIndex((
                                                                                                     item,
                                                                                                     f1i) => {
                                               if (f1i === index)
                                                 return false;
                                               return item['businessTravel'] === el['businessTravel'] &&
                                                   item['documentNumber'] !== el['documentNumber'];
                                             });
                                             if (rzhdbusinessTravelTest !== -1) {
                                               flag = true;
                                               m_error = 'Запрещенно указание одного и того же номера карты для двух и более разных пассажиров';
                                             }
                                           }
                                           if (bd.getTime() > new Date().getTime()) {
                                             flag = true;
                                             m_error = 'Невозможно купить билет человеку из будующего.';
                                           }
                                           return el;
                                         },
    );

    if (flag) {
      this.setError(m_error);
      return false;
    }

    let ttrainForwardS = Object.assign({}, trainForward.train,
                                       {serviceClasses: undefined});
    let ttrainReturnS = Object.assign({}, trainReturn.train,
                                      {serviceClasses: undefined});

    let ttrainForward = Object.assign({}, trainForward, {
      availableSeats: undefined,
      selectedSeats: undefined,
      train: ttrainForwardS,
    });

    let ttrainReturn = Object.assign({}, trainReturn, {
      availableSeats: undefined,
      selectedSeats: undefined,
      train: ttrainReturnS,
    });

    const params = {
      email: this.props.user.email,
      phone: this.props.user.phone,
      passengers: passengersorder,
      trainForward: ttrainForward,
      selectedStartSeatsForward: selectedStartSeatsForward,
      selectedEndSeatsForward: selectedEndSeatsForward,
      trainReturn: ttrainReturn,
      selectedStartSeatsReturn: selectedStartSeatsReturn,
      selectedEndSeatsReturn: selectedEndSeatsReturn,
    };
    this.setState({
                    process: false,
                    load: false,
                  });

    this.props.createNew(params).then((resp) => {
      this.props.history.push('/bag');
      this.props.clearNew(resp);
    }).catch((error) => {
      let errorText = '';
      if (typeof error.needEval === 'boolean' && error.needEval) {
        let evalResult = '';
        const request = error.request;
        eval('evalResult = ' + error.eval + ';');
        errorText = error.Message + evalResult;
      } else {
        errorText = error;
        if (error.MessageParams !== undefined
            && error.MessageParams[0] !== undefined
            && !error.MessageParams[0].includes('request')
        ) {
          errorText = error.MessageParams[0];
        } else {
          if (error.Message !== undefined && error.Message[0] !== undefined) {
            errorText = error.Message;
          }
        }
      }
      this.setError(errorText);
      this.setState({
                      process: true,
                      load: true,
                    });
    });
  };

  setError = (text) => {
    this.setState({
                    visible: true,
                    error: text,
                  });
    setTimeout(function() {
      this.setState({visible: false});
    }.bind(this), 7000);
  };

  render() {
    const {forwardData, returnData} = this.props.booking;
    const {
            passengers, selectedSeatsForward, selectedSeatsReturn, selectedStartSeatsForward,
            selectedEndSeatsForward, selectedStartSeatsReturn, selectedEndSeatsReturn,
            error, process, load,
          } = this.state;
    const trainForward = forwardData !== undefined ? forwardData.train !==
                                                     undefined
                                                     ? forwardData.train
                                                     : null : null;
    const trainReturn = returnData !== undefined ? returnData.train !==
                                                   undefined
                                                   ? returnData.train
                                                   : null : null;
    const sForward = (
        selectedSeatsForward.length !== 0 ?
        (
            <div className='selected-text'>
              {selectedStartSeatsForward === selectedEndSeatsForward ? (
                  <div>Место: {selectedStartSeatsForward}</div>
              ) : (
                   <div>Места:
                     с {selectedStartSeatsForward} по {selectedEndSeatsForward}</div>
               )}
            </div>
        ) : null

    );
    const sReturn = (
        selectedSeatsReturn.length !== 0 ?
        (
            <div className='selected-text'>
              {selectedStartSeatsReturn === selectedEndSeatsReturn ? (
                  <div>Место: {selectedStartSeatsReturn}</div>
              ) : (
                   <div>Места:
                     с {selectedStartSeatsReturn} по {selectedEndSeatsReturn}</div>
               )}
            </div>
        ) : null

    );
    const carSchemeForvard = forwardData !== undefined ? (
        <div className="row m-0 d-block-md">
          <div className="col-12 text-center">Выбор мест
            <span> из <u>{trainForward.destinationFromName}</u></span>
            <span> в <u>{trainForward.destinationToName}</u></span>
          </div>
          <div
              className="col-12 col-md-3 d-flex justify-content-center flex-column">
            <div
                className="train-number font-weight-bold">Поезд {trainForward.number} &laquo;Сапсан&raquo;</div>
            <div className="text-capitalize date">{time.getDate(
                trainForward.localDepartureDateTime)}
              - {time.getTime(trainForward.localDepartureDateTime)}</div>
            <div>Вагон: {forwardData.selectedCarNumber}</div>
            {sForward}
          </div>
          <div className="col-12 col-md-9">
            <div className='checkout_scheme'>
              <Car
                  carType={forwardData.carType}
                  availableSeats={this.state.availableSeatsForward}
                  seatSelect={this.seatSelectForvard}
                  selectedSeats={this.state.selectedSeatsForward}
              />
            </div>
          </div>
        </div>
    ) : null;

    const carSchemeReturn = returnData !== undefined &&
                            returnData.selectedStart !== '' ? (
                                <div className="row m-0 pt-4 d-block-md">
                                  <div className="col-12 text-center">Выбор мест
                                    <span> из <u>{trainReturn.destinationFromName}</u></span>
                                    <span> в <u>{trainReturn.destinationToName}</u></span>
                                  </div>
                                  <div
                                      className="col-12 col-md-3 d-flex justify-content-center flex-column">
                                    <div
                                        className="train-number font-weight-bold">Поезд {trainReturn.number} &laquo;Сапсан&raquo;</div>
                                    <div
                                        className="text-capitalize date">{time.getDate(
                                        trainReturn.localDepartureDateTime)}
                                      - {time.getTime(
                                          trainReturn.localDepartureDateTime)}</div>
                                    <div>Вагон: {returnData.selectedCarNumber}</div>
                                    {sReturn}
                                  </div>
                                  <div className="col-12 col-md-9">
                                    <div className='checkout_scheme'>
                                      <Car
                                          carType={returnData.carType}
                                          availableSeats={this.state.availableSeatsReturn}
                                          seatSelect={this.seatSelectReturn}
                                          selectedSeats={this.state.selectedSeatsReturn}
                                      />
                                    </div>
                                  </div>
                                </div>
                            ) : null;
    const to = trainForward !== null ? (
        <div className="item trains-checkout">
          <div className="">
            <div className="direction text-bold ">Туда&nbsp;</div>
            <div
                className="d-flex flex-sm-row flex-column justify-content-between w-100">
              <div
                  className="train-number font-weight-bold">Поезд {trainForward.displayTrainNumber} &laquo;Сапсан&raquo;</div>
              <div
                  className='d-block d-sm-none'>{trainForward.destinationFromName}</div>
              <div
                  className="text-capitalize date">Отправление {time.getTime(
                  trainForward.localDepartureDateTime)} - {time.getDate(
                  trainForward.localDepartureDateTime)}</div>
            </div>
          </div>
          <div className="">
            <div
                className="d-flex flex-sm-row flex-column justify-content-between w-100">
              <div
                  className='d-none d-sm-block'>{trainForward.destinationFromName}</div>
              <div className="d-flex">
                <div className="icon-item">
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </div>
                <div className='d-block d-sm-none ml-2'> в
                  пути <u>{time.getTripDuration(
                      trainForward.tripDuration)}&nbsp;</u></div>
              </div>
              <div>{trainForward.destinationToName}</div>
            </div>
            <div className="d-flex flex-row  justify-content-between w-100">
              <div className='d-none d-sm-block'>время в
                пути <u>{time.getTripDuration(
                    trainForward.tripDuration)}&nbsp;</u></div>
              <div
                  className="text-capitalize date">Прибытие {time.getTime(
                  trainForward.localArrivalDateTime)} - {time.getDate(
                  trainForward.localArrivalDateTime)}</div>
            </div>
          </div>
        </div>
    ) : this.props.history.push('/');
    const back = trainReturn !== null ? (
        <div className="item trains-checkout">
          <div className="">
            <div className="direction text-bold ">Обратно&nbsp;</div>
            <div
                className="d-flex flex-sm-row flex-column justify-content-between w-100">
              <div
                  className="train-number font-weight-bold">Поезд {trainReturn.displayTrainNumber} &laquo;Сапсан&raquo;</div>
              <div
                  className='d-block d-sm-none'>{trainReturn.destinationFromName}</div>
              <div
                  className="text-capitalize date">Отправление {time.getTime(
                  trainReturn.localDepartureDateTime)} - {time.getDate(
                  trainReturn.localDepartureDateTime)}</div>
            </div>
          </div>
          <div className="">
            <div
                className="d-flex flex-sm-row flex-column justify-content-between w-100">
              <div
                  className='d-none d-sm-block'>{trainReturn.destinationFromName}</div>
              <div className="d-flex">
                <div className="icon-item">
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </div>
                <div className='d-block d-sm-none ml-2'> в
                  пути <u>{time.getTripDuration(
                      trainReturn.tripDuration)}&nbsp;</u></div>
              </div>
              <div>{trainReturn.destinationToName}</div>
            </div>
            <div className="d-flex flex-row  justify-content-between w-100">
              <div className='d-none d-sm-block'>время в
                пути <u>{time.getTripDuration(
                    trainReturn.tripDuration)}&nbsp;</u></div>
              <div
                  className="text-capitalize date">Прибытие {time.getTime(
                  trainReturn.localArrivalDateTime)} - {time.getDate(
                  trainReturn.localArrivalDateTime)}</div>
            </div>
          </div>
        </div>
    ) : null;
    const passengersItem = (
              passengers.map((el, i) => (
                                 <div ref={el.ref} key={i + 'pass'}>
                                   <Passenger
                                       passenger={el}
                                       fieldUpdate={this.passengerUpdate(i)}
                                       selectSavedOption={true}
                                       title={`Пассажир № ${i + 1}:`}
                                       key={i}
                                       handleRemove={this.passengerRemove(i)}
                                       booking={this.props.booking}
                                       tariff={true}/>
                                 </div>
                             ),
              )
          )
    ;
    const onlyChildVar = this.onlyChild();
    const oldMan = this.oldMan();
    const youngMan = this.youngMan();
    return (
        <Fragment>
          <Navigation/>
          <Progress/>
          <Helmet>
            <title></title>
          </Helmet>

          <main className="main checkout-page">
            <section className="container">
              <div
                  className="checkout-train-holder d-flex justify-content-between">
                {to}
                {back}
              </div>
              <div className="passenger checkout">
                {passengersItem}
              </div>

              <div className="checkout cars">
                {carSchemeForvard}
                {carSchemeReturn}
              </div>

            </section>
            <div className="control panel">
              <div className={this.state.visible
                              ? 'fadeIn container-error'
                              : 'fadeOut container-error'}>
                {this.state.visible &&
                <div className='error-block'>
                  {error}
                </div>}
              </div>
              <div className={onlyChildVar
                              ? 'fadeIn container-error'
                              : 'fadeOut container-error'
              }>
                <div className='error-block'>
                  Внимание! Проезд детей до 10 лет без сопровождения взрослого
                  запрещен.
                </div>
              </div>
              <div className={oldMan
                              ? 'fadeIn container-error'
                              : 'fadeOut container-error'
              }>
                <div className='error-block'>
                  Внимание! Для пассажиров с годом рождения ранее 1900, в
                  электронном билете будет указан
                  год рождения 1900.
                </div>
              </div>
              <div className={youngMan
                              ? 'fadeIn container-error'
                              : 'fadeOut container-error'
              }>
                <div className='error-block'>
                  Внимание! Нельзя оформить билеты человеку из будущего.
                </div>
              </div>
              {!load &&
              <div className="box">
                <div className="loader-05"></div>
              </div>
              }
              <div className={load ? 'container' : 'disabled container'}>

                <div>
                  <div className="btn-wrap text-center">
                    <button className="btn btn__main btnAdaptiveMobile" onClick={this.stepBack}>
                                          <span className="icon">
                                               <svg
                                                   xmlns="http://www.w3.org/2000/svg"
                                                   width="15" height="23"
                                                   viewBox="0 0 512 512">
                                                        <path d="M110.931,243.242c-0.226,0.302-0.461,0.598-0.671,0.913c-0.204,0.304-0.38,0.62-0.566,0.932
                                                                    c-0.17,0.285-0.349,0.564-0.506,0.857c-0.17,0.318-0.315,0.646-0.468,0.971c-0.145,0.306-0.297,0.607-0.428,0.921
                                                                    c-0.13,0.315-0.236,0.637-0.35,0.957c-0.121,0.337-0.25,0.669-0.354,1.013c-0.097,0.32-0.168,0.646-0.249,0.969
                                                                    c-0.089,0.351-0.187,0.698-0.258,1.055c-0.074,0.375-0.118,0.753-0.173,1.13c-0.044,0.311-0.104,0.617-0.135,0.933
                                                                    c-0.138,1.4-0.138,2.811,0,4.211c0.031,0.315,0.09,0.621,0.135,0.933c0.054,0.377,0.098,0.756,0.173,1.13
                                                                    c0.071,0.358,0.169,0.704,0.258,1.055c0.081,0.324,0.152,0.649,0.249,0.969c0.104,0.344,0.233,0.677,0.354,1.013
                                                                    c0.115,0.32,0.22,0.642,0.35,0.957c0.13,0.314,0.283,0.615,0.428,0.921c0.153,0.325,0.297,0.653,0.468,0.971
                                                                    c0.157,0.293,0.336,0.572,0.506,0.857c0.186,0.312,0.363,0.628,0.566,0.932c0.211,0.315,0.445,0.611,0.671,0.913
                                                                    c0.191,0.255,0.368,0.516,0.571,0.764c0.439,0.535,0.903,1.05,1.392,1.54c0.007,0.008,0.014,0.016,0.021,0.023l85.333,85.333
                                                                    c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17l-48.915-48.915H384c11.782,0,21.333-9.551,21.333-21.333
                                                                    s-9.551-21.333-21.333-21.333H179.503l48.915-48.915c8.331-8.331,8.331-21.839,0-30.17s-21.839-8.331-30.17,0l-85.333,85.333
                                                                    c-0.008,0.008-0.014,0.016-0.021,0.023c-0.488,0.49-0.952,1.004-1.392,1.54C111.299,242.726,111.122,242.987,110.931,243.242z"/>
                                               </svg>
                                              </span>
                      &nbsp;&nbsp;Назад&nbsp;&nbsp;
                    </button>
                  </div>
                </div>
                {trainForward !== null &&
                <div className="checkout add text-center">
                  <button className={this.countAdult() < 4
                                     ? 'btn btn__main btnAdaptiveMobile'
                                     : 'disabled btn btn__main btnAdaptiveMobile'}
                          onClick={this.passengerAdd}>
                    &nbsp;&nbsp;Добавить пассажира&nbsp;&nbsp;
                  </button>
                </div>
                }
                {trainForward !== null &&
                <div>
                  {passengers.length !== 0 &&
                  <div className="btn-wrap text-center">
                    <button className="btn btn__main btnAdaptiveMobile" onClick={this.handleBuy}>
                      &nbsp;&nbsp;Оформить&nbsp;&nbsp;
                    </button>
                  </div>}
                </div>}
              </div>
            </div>
          </main>
        </Fragment>
    );
  }

  countAdult() {
    let countAdult = 0;
    const t = this.state.passengers.map((item) => {
      if (item.tariff !== 'BabyWithoutPlace') {
        countAdult++;
      }
      return item;
    });
    return countAdult;
  }

  onlyChild() {
    let countPСhild = 0;
    let countAdult = 0;
    const t = this.state.passengers.map((item) => {
      if (item['birthdate'] === '') {
        return item;
      }
      let bd = new Date(item['birthdate']);
      if (!isDate18orMoreYearsOld(bd.getDate(), (
          bd.getMonth() + 1
      ), bd.getFullYear(), 10)) {
        countPСhild++;
      } else {
        countAdult++;
      }
      return item;
    });
    return (
        countPСhild > 0 && countAdult === 0
    );
  }

  oldMan() {
    let result = false;
    const t = this.state.passengers.map((item) => {
      if (item['birthdate'] === '') {
        return item;
      }
      let bd = new Date(item['birthdate']);
      if (bd.getFullYear() < 1900) {
        result = true;
      }
      return item;
    });
    return result;
  }

  youngMan() {
    let result = false;
    const t = this.state.passengers.map((item) => {
      if (item['birthdate'] === '') {
        return item;
      }
      let bd = new Date(item['birthdate']);
      var now = new Date();
      if (bd > now) {
        result = true;
      }
      return item;
    });
    return result;
  }

  stepBack = () => {
    this.props.history.goBack();
  };
}

export default withRouter(Checkout);

export const isCyrillic = (value) => (
    /^[а-яё\s\-]*$/i
).test(value);
export const isInnerSeriesCorrect = (
    series,
    currentDate) => (
    series >= 97 && series <= 99
) || (
    series >= 0 && series <= (
        currentDate.getFullYear() + 3
    ) % 100
)

export const isInner = (value) => value.replace(/[\s]/g, '').match(/^\d{10}$/);
export const isOuter = (value) => value.replace(/[\s]/g, '').match(/^\d{9}$/);
export const isSailor = (value) => value.replace(/[\s]/g, '').
                                         match(
                                             /^[А-Яа-я|0-9|A-Za-z]{2}\d{6}$/i);
export const isMilitary = (value) => value.replace(/[\s]/g, '').
                                           match(/^[а-я]{2}\d{7}$/i);
export const isBirth = (value) => value.replace(/[\s\-]/g, '').
                                        match(/^[IVXLCDM]+[А-Яа-я]{2}\d{6}$/i);
export const isForeign = (value) => value.replace(/[\s\-]/g, '').
                                          match(/^.{1,16}$/i);
export const isDate18orMoreYearsOld = (
    day,
    month,
    year,
    hm,
    mm = 0) => {
  return new Date(year + hm, (
      month - 1 + mm
  ), day) <= new Date();
};
