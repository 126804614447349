import * as types from './../constants/ActionTypesBens';

const initialState = [];

export default (state = initialState,
                action) => {
  switch (action.type) {

    case types.GOOD_ACTION:
      return action.payload;

    default:
      return state;
  }
};
