export const BOOKING_PARAM_SET = 'BOOKING_PARAM_SET';
export const BOOKING_CLEAR = 'BOOKING_CLEAR';
export const BOOKING_CLEAR_NEW = 'BOOKING_CLEAR_NEW';
export const BOOKING_GET_STORAGE = 'BOOKING_GET_STORAGE';
export const BOOKING_SEAT_DATA_UPDATE = 'BOOKING_SEAT_DATA_UPDATE';
export const BOOKING_SELECT_SEAT = 'BOOKING_SELECT_SEAT';
export const BOOKING_REMOVE_SEAT = 'BOOKING_REMOVE_SEAT';
export const BOOKING_FEATCH_SEATS = 'BOOKING_FEATCH_SEATS';
export const BOOKING_SEATS_CLEAR = 'BOOKING_SEATS_CLEAR';
export const BOOKING_SET_STEP = 'BOOKING_SET_STEP';
