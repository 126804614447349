import React, {Component, Fragment} from 'react';
import OrderItem from "../../containers/Cabinet/OrderItem";
import {dateFormatter} from "../Helpers";

class Reservation extends Component {
    render() {
        const {reservation, i, cities, type, status, flag} = this.props;
        const statusObj = status.find(el => el.value === reservation.order_data.status);
        return (
            <Fragment>
                <div className="form pt-4 wrapper-archive" key={reservation.order_data.id + '_' + i}>
                    {reservation.tickets.map((ticket, k) => {
                        return (
                            <OrderItem
                                cities={cities}
                                key={ticket.ticket_data.global_order_item + '_' + k}
                                reservation={reservation}
                                ticket={ticket}
                                type={type}
                                i={i}
                            />
                        )
                    })}
                    {reservation.order_data.id
                        ? (
                            <div className="d-flex justify-content-between pb-4">
                                <div className="font-weight-bold pl-1 d-flex align-items-center text-bold">
                                    Сумма заказа: {reservation.order_data.amount}
                                </div>
                                <div className="font-weight-bold pl-1 d-flex align-items-center text-bold">
                                    Дата покупки: {dateFormatter(reservation.order_data.date_created)}
                                </div>
                                {statusObj ?
                                <div key={reservation.order_data.id + reservation.order_data.status} className="font-weight-bold pl-1 d-flex align-items-center text-bold">
                                    Статус: {statusObj.lable}
                                </div>:
                                    reservation.order_data.status
                                }

                            </div>)
                        : (
                            <div className="">
                                Архив билетов пуст
                            </div>
                        )
                    }
                    {flag &&
                    <div onClick={()=>this.props.delete(reservation.order_data.id)} className='wrapper-archive-cross'>
                        <span className='line-one'></span>
                        <span className='line-second'></span>
                    </div>
                    }
                </div>
            </Fragment>
        )
    }

}

export default Reservation;
