export var USER_EXIST = 'USER_EXIST';
export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_UPDATE = 'USER_FETCH_UPDATE';
export const USER_FETCH_BLANK_UPDATE = 'USER_FETCH_BLANK_UPDATE';
export const USER_FETCH_PASSWORD = 'USER_FETCH_PASSWORD';
export const USER_CHANGE_REGISTRATION = 'USER_CHANGE_REGISTRATION';
export const USER_FETCH_PASSWORD_WRONG = 'USER_FETCH_PASSWORD_WRONG';
export const USER_RESERVATIONS_FETCH = 'USER_RESERVATIONS_FETCH';
export const USER_RESERVATIONS_ARCHIVE_FETCH = 'USER_RESERVATIONS_ARCHIVE_FETCH';
export const USER_TASK_FETCH = 'USER_TASK_FETCH';
export const USER_TOTALS = 'USER_TOTALS';
export const USER_RESERVATIONS_ARCHIVE_DELETE = 'USER_RESERVATIONS_ARCHIVE_DELETE';
