import React from 'react';
import {connect} from 'react-redux';
import {
    getList as getBag,
    reservationRemove, reservationPayment, orderPayment, reservationRemoveNew,
    orderPaymentNew, orderPaymentCredit, reservationPaymentNew,
} from './../../actions/Bag';
import {Redirect} from 'react-router-dom';
import Bag from './../../components/Bag';
import {getStorage} from "../../actions/Booking";

const mapStateToProps =
    state => {
        const bag = state.bag
        let locale = {}
        if (bag.reservations.length > 0) {
            for (const item in bag.reservations) {
                const goid = bag.reservations[item].order_data.global_order_id
                if(localStorage.getItem(goid) !== '')
                locale[goid] = JSON.parse(localStorage.getItem(goid))
            }
        }
        return {
            bag: bag,
            locale: locale,
            msgError: state.bagError.msg,
            cities: state.citiesglobal,
        }
    };

const mapDispatchToProps = {
    reservationRemove,
    reservationRemoveNew,
    reservationPayment,
    reservationPaymentNew,
    orderPayment,
    orderPaymentNew,
    orderPaymentCredit,
    getStorage,
    getBag
};

export default connect(mapStateToProps, mapDispatchToProps)(props => props.bag.reservations.length === null ?
    <Redirect to="/"/> :
    <Bag {...props} />);
