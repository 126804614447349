import {combineReducers} from 'redux';
import searchFilters from './searchFilters';
import passengers from './passengers';
import booking from './booking';
import steps from './steps';
import cities from './cities';
import trains from './trains';
import train from './train';
import user from './user';
import auth from './auth';
import bag from './bag';
import bagError from './bagError';
import app from './app';
import citiesglobal from './citiesglobal';
import bens from './bens';
import statusglobal from "./statusglobal";

export default combineReducers({
    searchFilters,
    passengers,
    booking,
    cities,
    steps,
    trains,
    train,
    user,
    auth,
    bag,
    bagError,
    bens,
    citiesglobal,
    statusglobal,
    app,
});
