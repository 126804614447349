import React, {Fragment, PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import Nav from './../../containers/Navigation/Large';
import Loader from 'react-loaders';
import Filters from './../../containers/Search/Filters';
import Train from './Train';
import Progress from './../../containers/Search/Progress';
import {Helmet} from 'react-helmet';
import Empty from '../../containers/Search/Empty';

class Search extends PureComponent {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.loader !== prevProps.loader) {
  //     setTimeout(() => {
  //       this.myRef.current.scrollIntoView({
  //                                           behavior: 'smooth',
  //                                           block: 'start',
  //                                         });
  //     }, 100);
  //   }
  // }

  handleClick =
      params => () => {
        this.props.paramSet('step', this.props.booking.step <= 1 ? 1 : 2);
        this.props.paramSet('trainNumber', params.trainNumber);
        this.props.paramSet('displayTrainNumber', params.displayTrainNumber);
        this.props.getItem(params);
        this.props.history.push('/train');
      };

  render() {
    const {abort, loader, first, trains, message} = this.props;
    const speedee = Math.min.apply(Math, trains.map(function(o) {
      return o.tripDuration;
    }));
    const cheepee = Math.min.apply(Math, trains.map(function(o) {
      return o.minPrice;
    }));
    return (
        <Fragment>
          <Nav addClass='search'
               refScroll={this.myRef}/>
          <Helmet>
            <title>Поиск поезда</title>
            <meta name="description" content="Поиск поезда"/>
          </Helmet>
          <Progress/>
          <main className="main" key={first ? 'd-none' : 'container'}>
            <div className={first ? 'd-none' : 'container'}>
              <div className={abort ? 'd-none' : ''}>
                <Filters/>
              </div>
              <div ref={this.myRef}>
                {(
                     !loader && trains.length === 0
                 ) ? (
                     abort ? (
                               message !== '' ? (
                                   <Empty message={message}
                                          step={this.props.booking.step}/>
                               ) : <div className='container'>
                                 Произошла потеря сигнала с поставщиком услуг.
                                 Попробуйте немного позже.</div>
                           )
                           : null
                 )
                   : null}
                <Loader type="line-scale-pulse-out" active={loader}/>
                <div className="search-result">
                  {trains.map((
                                  el,
                                  i) => (
                      <Train key={i} {...el} handleClick={this.handleClick({
                                                                             trainNumber: el.trainNumber,
                                                                             displayTrainNumber: el.displayTtrainNumber,
                                                                             destinationFrom: el.destinationCodeFrom,
                                                                             destinationTo: el.destinationCodeTo,
                                                                             date: el.date,
                                                                           })}
                             speedee={speedee} cheepee={cheepee}/>
                  ))}
                </div>
              </div>
            </div>
            <div className={!first ? 'd-none' : 'container'}>
              Начните поиск
            </div>
          </main>
        </Fragment>
    );
  }
}

export default withRouter(Search);
