import {createStore, applyMiddleware} from 'redux';
import reducers from './../reducers';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

function save(state) {
  try {
    const s = JSON.stringify(state)
    localStorage.setItem('state', s)
  } catch (e) {
    // console,log(e);
  }
}

function get() {
  try {
    const s = localStorage.getItem('state')
    if (s === null) return undefined;
    return JSON.parse(s)
  } catch (e) {
    console.log(e);
  }
}

const os = get()

// const store = (process.env.NODE_ENV !== 'production')
//   ? applyMiddleware(logger, thunk)(createStore)(reducers)
//   // ? applyMiddleware(logger, thunk)(createStore)(reducers, os)
//   : applyMiddleware(thunk)(createStore)(reducers);


const store = applyMiddleware(thunk)(createStore)(reducers);

store.subscribe(() => save(store.getState()))
export default store;
