import {connect} from 'react-redux';
import Tickets from './../../components/Cabinet/Tickets';
import {refund, refundAmount, refundOneAction} from '../../actions/Bag';
import {changeER, getReservations} from "../../actions/User";

const mapStateToProps =
    state => {
        return {
            list: state.user.reservations,
            cities: state.citiesglobal,
            status: state.statusglobal,
            wait: state.app.wait,
        };
    }
export default connect(mapStateToProps, {
    refundOneAction,
    refund,
    refundAmount,
    getReservations,
    changeER,
})(Tickets);
