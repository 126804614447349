export const options = [
  {value : '',
    label: 'Любой',
  },
  {value : '754А',
    label: '754А',
  },
  {value : '756А',
    label: '756А',
  },
  {value : '760А',
    label: '760А',
  },
  {value : '766А',
    label: '766А',
  },
  {value : '770А',
    label: '770А',
  },
  {value : '774А',
    label: '774А',
  },
  {value : '776А',
    label: '776А',
  },
  {value : '778А',
    label: '778А',
  },
  {value : '780А',
    label: '780А',
  },
];
