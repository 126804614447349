import * as types from './../constants/ActionTypesUser';

const initialState = {
    data: {},
    message: '',
    reservations: [],
    refunded: [],
    main: {
        amount: 0,
        count: 0,
    }
};

export default (state = initialState,
                action) => {
    let newState = {...state};

    switch (action.type) {

        case types.USER_FETCH_UPDATE:
            newState.data = action.payload;
            newState.message = 'Ваши данные успешно обновлены.';
            return newState;

        case types.USER_FETCH:
            newState.data = action.payload;
            return newState;


        case types.USER_FETCH_BLANK_UPDATE:
            const dataPayload = action.payload[0];
            return {
                ...state,
                reservations: state.reservations.map(item =>
                    item.order_data.global_order_id === dataPayload.order_data.global_order_id
                        ? dataPayload
                        : item
                )
            };

        case types.USER_FETCH_PASSWORD:
            newState.data = action.payload[0];
            newState.message = action.payload[1];
            return newState;

        case types.USER_FETCH_PASSWORD_WRONG:
            newState.message = action.payload;
            return newState;

        case types.USER_RESERVATIONS_FETCH:
            newState.reservations = action.payload;
            return newState;

        case types.USER_RESERVATIONS_ARCHIVE_FETCH:
            newState.refunded = action.payload;
            return newState;

        case types.USER_RESERVATIONS_ARCHIVE_DELETE:
            newState.refunded  = newState?.refunded?.filter(r => r.order_data.id != action.payload)
            return newState;

        case types.USER_CHANGE_REGISTRATION:
            const params = action.payload.params;
            const data = action.payload.data;

            let objresp = data.Blanks.find((o0, i0) => {

                let f1 = false;
                let f2 = false;
                let obj = newState.reservations.find((o1, i1) => {
                    if (f1) return true
                    o1.tickets.find((o2, i2) => {
                        if (f2) return true
                        o2.pasangers.find((o3, i3) => {
                            if (o3.order_item_blank_id === ('' + o0.OrderItemBlankId)) {
                                newState.reservations[i1].tickets[i2].pasangers[i3].blank_status = o0.BlankStatus;
                                f1 = f2 = true;
                                return true;
                            }
                        })
                    })
                })
            });
            return newState;

        case types.USER_TASK_FETCH:
            newState.tasks = action.payload;
            return newState;

        case types.USER_TOTALS:
            newState.main = action.payload;
            return newState;

        case types.USER_EXIST:
            newState.error = action.payload;
            return newState;

        default:
            return state;
    }
};
