import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

class Empty extends Component {

  handleClickChekout = () => {
    this.props.paramSet('isSearchBack', false);
    this.props.history.push('/checkout');
  };

  render() {

    return (<div className="search-empty">
        <Helmet>
          <title>Свободных мест нет</title>
        </Helmet>
        {this.props.step === 2 ? (<div>
            <h3>{this.props.message}</h3>
            <div onClick={this.handleClickChekout} className="btn btn__main go-check">Оплатить билеты "туда", билеты
              "обратно" пока не покупать
            </div>
            <p></p>
            <Link className="btn btn__main" to="/tasks">Прервать покупку билетов "туда", сначала оставить заявку на
              выкуп билетов обратно</Link></div>
        ) : (<div><h3>{this.props.message}</h3>
            <p>Оставьте заявку, и мы выкупим нужный Вам билет, как только он появится.</p>
            <Link className="btn btn__main" to="/tasks">Оставить заявку</Link></div>
        )}

      </div>
    );
  }
}

export default withRouter(Empty); // at the end of component
