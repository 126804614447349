import React from 'react';

export default props => {
    const {handleEndHover, handleHover, handleClick, availableSeats, selectedSeats} = props;
    return (
        <svg pointerEvents="bounding-box" version="1.1" id="Layer_1" xmlnssvg="http://www.w3.org/2000/svg"
             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 1140 306.8" style={{enableBackground: 'new 0 0 1140 306.8'}} xmlSpace="preserve">
            <rect xmlns="http://www.w3.org/2000/svg" x="286.7" y="75.2" fill="#ECF0F1" width="52.1" height="58"/>
            <polyline className="fill-darkgray" points="1107.8,92 1123.4,76.4 1139,92 "/>


            <polyline className="fill-darkgray" points="404.7,218.9 389.1,234.4 373.6,218.9 "/>
            <polyline className="fill-darkgray" points="404.7,87.8 389.1,72.2 373.6,87.8 "/>
            <polyline className="fill-darkgray" points="369.1,137.7 353.6,153.3 369.1,168.9 "/>
            <polyline className="fill-darkgray" points="1125,168.6 1140.2,153.3 1125,138.1 "/>
            <g>
                <path className="fill-darkgray" d="M1024.8,233.9c-3.3,0-5.9-2.6-5.9-5.9v-45.5c0-3.3,2.6-5.9,5.9-5.9h45.5c3.3,0,5.9,2.6,5.9,5.9V228
		c0,3.3-2.6,5.9-5.9,5.9H1024.8 M1024.8,234.9h45.5c3.8,0,6.9-3.1,6.9-6.9v-45.5c0-3.8-3.1-6.9-6.9-6.9h-45.5
		c-3.8,0-6.9,3.1-6.9,6.9V228C1017.9,231.8,1021,234.9,1024.8,234.9L1024.8,234.9z"/>
                <path className="fill-darkgray" d="M1058.2,212.8c-0.1,0.2-0.1,0.5-0.3,0.7c-0.2,0.3-0.4,0.5-0.7,0.7c-0.6,0.3-1.2,0.5-1.9,0.5
		c-0.3,0-0.5,0.1-0.8,0.1c0-5.4,0-10.8,0-16.2c0.2,0,0.4,0,0.6,0.1c0.3,0,0.5,0.1,0.8,0.1c0.7,0.1,1.4,0.4,1.9,1
		c0.2,0.2,0.3,0.5,0.4,0.9C1058.2,204.6,1058.2,208.7,1058.2,212.8z"/>
                <path className="fill-darkgray" d="M1036.8,200.5c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.6-0.7,1-0.9c0.5-0.3,1.1-0.4,1.7-0.4c0.2,0,0.4,0,0.6-0.1
		c0,5.4,0,10.8,0,16.2c-0.2,0-0.4,0-0.6-0.1c-0.5-0.1-1-0.1-1.5-0.3c-0.5-0.3-1-0.6-1.3-1.2c0-0.1-0.1-0.2-0.1-0.3
		C1036.8,208.7,1036.8,204.6,1036.8,200.5z"/>
                <path className="fill-darkgray" d="M1053.4,214.9c-4,0.5-8,0.5-11.9,0c0-5.5,0-10.9,0-16.4c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.5,0
		s0.2-0.1,0.2-0.2c0-0.4,0.1-0.8,0.1-1.2c0.1-0.8,0.8-1.5,1.6-1.5c0.2,0,0.4,0,0.7,0c1.5,0,3.1,0,4.6,0c0.6,0,1.1,0.2,1.5,0.7
		c0.2,0.2,0.3,0.5,0.3,0.8c0,0.4,0.1,0.9,0.1,1.3c0,0.1,0,0.2,0.2,0.2c0.4,0,0.7,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2
		c0,1.3,0,2.6,0,3.9C1053.4,206.7,1053.4,210.8,1053.4,214.9C1053.4,214.7,1053.4,214.8,1053.4,214.9z M1050.7,198.2
		c0-0.4-0.1-0.7-0.1-1.1c0-0.3-0.2-0.5-0.6-0.5c-1.7,0-3.4,0-5,0c-0.4,0-0.6,0.2-0.6,0.6c0,0.2,0,0.5-0.1,0.7c0,0.1,0,0.2,0,0.3
		C1046.5,198,1048.6,198,1050.7,198.2z"/>
            </g>
            <path className="fill-darkgray"
                  d="M1015.3,124.1c-0.6,0-1.1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1.1C1016.4,123.7,1015.9,124.1,1015.3,124.1z"
            />
            <g>
                <path className="fill-darkgray" d="M1007.4,123.1c0-2.6,0-5.3,0-7.9c0-1.7,1.3-3,3-3c5.3,0,10.6,0,15.8,0c1.7,0,3,1.3,3,3c0,3.7,0,7.4,0,11
		c0,1.6,0,3.1,0,4.7c0,1.8-1.3,3.1-3.1,3.1c-1.8,0-3.7,0-5.5,0c-3.4,0-6.8,0-10.2,0c-1.4,0-2.6-0.9-2.9-2.3c0-0.2-0.1-0.4-0.1-0.6
		c0-1.6,0-3.2,0-4.7C1007.4,125.2,1007.4,124.2,1007.4,123.1L1007.4,123.1z M1028.3,123.1C1028.2,123.1,1028.2,123.1,1028.3,123.1
		c0-2.6,0-5.2,0-7.8c0-1.2-0.9-2.2-2.2-2.2c-5.2,0-10.4,0-15.6,0c-1.3,0-2.2,0.9-2.2,2.2c0,3.5,0,7,0,10.5c0,1.7,0,3.5,0,5.2
		c0,1.3,0.9,2.1,2.2,2.1c4.2,0,8.3,0,12.5,0c1.1,0,2.1,0,3.2,0c1.2,0,2.1-0.9,2.1-2.1c0-1.2,0-2.4,0-3.6
		C1028.3,125.9,1028.3,124.5,1028.3,123.1z"/>
                <path className="fill-darkgray" d="M1011.5,123.1c0-3.7,3-6.7,6.6-6.7c3.7-0.1,6.8,2.9,6.8,6.6c0.1,3.8-2.9,6.8-6.6,6.9
		C1014.8,129.9,1011.6,127,1011.5,123.1z M1018.8,128.8c2.5-0.1,5.2-2.2,5.3-5.6s-2.6-5.8-5.3-5.9v0.1c0,0.3,0,0.7,0,1
		s-0.2,0.4-0.4,0.5c-0.3,0-0.5-0.2-0.5-0.4c0-0.1,0-0.2,0-0.4c0-0.3,0-0.5,0-0.8c-1.3,0-3.5,0.8-4.6,2.9s-1,4.2,0.3,6.1
		c1,1.5,2.5,2.3,4.3,2.5c0-0.4,0-0.8,0-1.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5C1018.8,128.1,1018.8,128.5,1018.8,128.8z"/>
                <path className="fill-darkgray"
                      d="M1022.3,123.1c0,0.6-0.5,1-1,1c-0.6,0-1-0.4-1.1-1c0-0.6,0.4-1,1-1C1021.8,122,1022.3,122.5,1022.3,123.1z"/>
            </g>
            <g id="zwquE5_1_">
                <g>
                    <path className="fill-darkgray" d="M594.8,222.2c-6.5,0-12.9,0-19.4,0c-3.8,0-6.3-3-5.2-6.6c0.4-1.2,1.5-2.4,2.6-3.1c6.1-4,12.4-7.9,18.6-11.8
			c0.8-0.5,1.4-1,1.2-2.1c-0.2-1.9,0.5-3.3,2.2-4.1c0.2-0.1,0.3-0.2,0.5-0.4c0.9-0.5,1.8-1.3,1.2-2.2c-0.4-0.6-1.5-1-2.3-1.1
			c-1.1-0.1-1.8,0.6-2,1.8c-0.1,0.9,0.1,1.9-1.3,2.1c-2.4,0.3-3.1-0.4-2.7-2.7c0.6-3.2,3.1-5.4,6.4-5.3c3.2,0.1,5.9,2.3,6.2,5.3
			c0.2,1.9-0.5,3.5-2,4.7c-0.5,0.4-1,0.6-1.4,1c-1.2,1-1.1,2.1,0.2,2.9c6.4,4,12.8,8,19.2,12c2,1.2,2.9,3,2.7,5.3
			c-0.3,2.7-2.4,4.4-5.4,4.5C607.7,222.2,601.2,222.2,594.8,222.2z M594.8,217.9c6.3,0,12.5,0,18.8,0c0.5,0,1.1-0.4,1.6-0.7
			c-0.3-0.5-0.6-1.1-1-1.4c-6.1-3.9-12.2-7.6-18.2-11.5c-0.9-0.5-1.4-0.5-2.2,0.1c-1.9,1.3-3.9,2.5-5.9,3.7
			c-4.1,2.6-8.3,5.2-12.4,7.8c-0.4,0.3-0.6,0.9-0.8,1.3c0.4,0.2,0.8,0.5,1.3,0.6c0.4,0.1,0.8,0,1.3,0
			C583,217.9,588.9,217.9,594.8,217.9z"/>
                </g>
            </g>
            <polyline className="fill-darkgray" points="409.1,168.9 424.7,153.3 409.1,137.7 "/>
            <path fill="transparent" stroke="#bdc3c7"
                  d="M170.3,227.2C75.4,223,1.8,192.6,1.8,155.7s73.6-67.2,168.5-71.5"/>
            <polyline stroke="#bdc3c7" fill="transparent" points="638.4,175.8 638.4,233.9 718.6,233.9 718.6,175.8 "/>
            <path id="Children" className="fill-darkgray" d="M491.3,143c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2s-2.2,1-2.2,2.2C489.1,142,490.1,143,491.3,143z
	 M502,154.5c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C500,153.6,500.9,154.5,502,154.5z M500.3,168.6
	c0.8,0,1.5-0.7,1.5-1.4v-3.7c0-0.1,0.1-0.1,0.1-0.1h0.2c0.1,0,0.1,0.1,0.1,0.1v3.7c0,0.7,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.3
	c0-0.1,0-8.7,0-8.7s0-0.2,0.1-0.2c0,0,2.6-4.7,2.7-4.8c0.2-0.4,0.2-0.8,0-1.2c-0.3-0.6-1-0.7-1.6-0.4c-0.2,0.1-0.4,0.3-0.5,0.5
	l-1.5,2.6c-0.1,0.1-0.1,0.2-0.3,0.2H500c-0.1,0-0.1,0-0.2-0.1c0,0-1.5-2.7-1.6-2.8c0,0,0,0,0,0c-0.3-1.1-1.8-5.8-1.8-5.8
	c-0.3-0.9-0.8-3-2.7-3h-4.8c-1.8,0-2.4,2.1-2.7,3c0,0-1.7,5.6-1.7,5.6c-0.2,0.6,0.2,1.2,0.8,1.4c0.6,0.2,1.2-0.2,1.4-0.8l1.7-5.6
	c0,0,0.1-0.1,0.2-0.1c0,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1l-3.4,11.2c0,0.1,0,0.2,0.1,0.2h2.4c0.1,0,0.1,0.1,0.1,0.1v8.2
	c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-8.2c0-0.1,0.1-0.1,0.1-0.1h0h0.2h0c0.1,0,0.1,0.1,0.1,0.1v8.2c0,0.8,0.7,1.5,1.5,1.5
	c0.8,0,1.5-0.7,1.5-1.5c0,0,0-8.2,0-8.2c0-0.1,0-0.1,0.1-0.1h2.4c0.1,0,0.1-0.1,0.1-0.2l-3.4-11.2c0-0.1,0-0.1,0.1-0.1
	c0,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.2,0.1c0,0,1.6,5.2,1.8,5.9c0,0.1,0.1,0.3,0.2,0.4l2.6,4.6c0,0.1,0.1,0.2,0.1,0.2v8.7
	C498.9,167.9,499.5,168.6,500.3,168.6z"/>
            <g>
                <path className="fill-darkgray" d="M671.6,201.2c0,0-0.5-1.7-2.7-1.7s-2.5,1.8-2.5,2.4s0.1,1.6,0.5,2.4c0.4,0.8,0.5,3.2-1.3,4.3
		c1.5,0.6,3.3,0.8,4.1-0.7C669.4,206.5,668.8,202.6,671.6,201.2z"/>
                <path className="fill-darkgray"
                      d="M675.7,201.7h5.6v2.7c0,0-0.1,1.7-2.8,1.7c-2.7,0-2.8-1.7-2.8-1.7V201.7z"/>
                <path className="fill-darkgray" d="M674.5,201.7c0,0-4.1-0.6-4.1,4.1c0,2.3,1.1,3.2,1.1,3.2s0.8,0.8-0.3,2.2s-2.2,3.7-2.2,3.7l1,0.5
		c0,0,1.6-4.1,3.8-4.7c1.6,0.2,3.9,0.1,3.9,0.1v-3.3c0,0-3.3-0.2-3.3-2.9C674.5,201.7,674.5,201.7,674.5,201.7z"/>
                <path className="fill-darkgray" d="M683.1,200.5c0,0,1.4,0.3,2-1.9c0.6-2.2,1.1-4.4,3.4-5.3c0.8-0.3,1.7,0.2,2.6-0.9c-1.2-0.5-3.7-0.6-5.5,1.9
		C683.6,196.7,683.1,200.5,683.1,200.5z"/>
                <g>
                    <path className="fill-darkgray" d="M680.7,220.9c-11.1,0-15.5-4.6-15.7-4.8c-0.4-0.5-0.4-1.2,0.1-1.6c0.5-0.4,1.2-0.4,1.6,0.1c0,0,1,1,3.2,2
			c2,0.9,5.6,2.1,10.8,2.1c5.2,0,8.7-1.1,10.7-2.1c2.1-1,3.1-2,3.1-2c0.4-0.5,1.1-0.5,1.6-0.1c0.5,0.4,0.5,1.1,0.1,1.6
			C696.1,216.3,691.8,220.9,680.7,220.9z"/>
                </g>
                <path className="fill-darkgray"
                      d="M693.3,201.2l2.5-2.5c0,0,0.6,1.7-0.2,2.5C694.7,202,693.3,201.2,693.3,201.2z"/>
                <g>
                    <path className="fill-darkgray" d="M682.5,201.7L682.5,201.7C682.5,201.7,682.4,201.7,682.5,201.7z"/>
                    <path className="fill-darkgray" d="M689.9,209c-0.7-0.6,0.2-2.5,0.3-3.4s0-3.2-0.3-4.5c-0.2-1.3,0.9-1.5,2.5-0.5c0.7-0.7,2.9-3,2.9-3l-1.9-3.2
			c0,0-0.5-0.5,0.1-1.1s0.6-1.1,0.6-1.1s-1.2,0-2.2,0.9s-1.3,1.2-3.2,1.4c-1.9,0.2-2.6,4.3-2.7,4.8c-0.1,0.6-1,2.4-2.4,2.4
			c-0.7,0-1,0-1.1,0l0,2.6c0,0-0.4,3-3.3,3c0,1.9,0,3.3,0,3.3s4-1.1,6.8-1.1c2.8,0,3,0.1,3.5,0.7c0.5,0.6,2.5,3.1,2.9,4.5
			c0.7-0.4,0.9-0.7,0.9-0.7S691.6,210.6,689.9,209z M692.6,196.1c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5
			c-0.3,0-0.5-0.2-0.5-0.5C692.1,196.3,692.4,196.1,692.6,196.1z"/>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path className="fill-darkgray"
                              d="M683.5,89.2c-0.5,0-0.8,0.4-0.8,0.8v8.1l6.7-6.7C687.8,90.1,685.8,89.2,683.5,89.2z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path className="fill-darkgray"
                              d="M690.6,92.6l-6.7,6.7h8.8v-0.8C692.7,96.3,691.9,94.2,690.6,92.6z"/>
                    </g>
                </g>
                <circle className="fill-darkgray" cx="686.8" cy="115.3" r="2.7"/>
                <g>
                    <path className="fill-darkgray" d="M679.2,114.3c0.1,0.3,0.1,0.6,0.1,1c0,0.2,0,0.5-0.1,0.7h3.5c0-0.2-0.1-0.5-0.1-0.7c0-0.3,0.1-0.7,0.1-1
			H679.2z"/>
                    <path className="fill-darkgray" d="M671.1,101v-0.8c0-1.4-1.1-2.5-2.5-2.5h-3.4c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h3.4
			c0.5,0,0.8,0.4,0.8,0.8c0,0.2,0,1.5,0,1.7c0,4,3.2,7.3,7.2,7.5l2.9,1.9l-1.6,1c0.4,0.4,0.8,0.8,1,1.3l2.1-1.4l2.1,1.3
			c0.2-0.5,0.6-1,1-1.3l-1.6-1l2.9-1.9c4-0.2,7.2-3.4,7.2-7.5V101H671.1z M681,110.1l-1.3-0.9h2.6L681,110.1z"/>
                </g>
                <circle className="fill-darkgray" cx="675.2" cy="115.3" r="2.7"/>
            </g>
            <g>
                <path className="fill-darkgray" d="M321.1,103.9c-0.3-1.1-1.2-1.8-2.2-2.1c-0.3-0.1-0.6,0.2-0.5,0.5l3.9,14.1c0.1,0.3,0.5,0.4,0.7,0.2
		c0.2-0.2,0.4-0.4,0.5-0.7c0.4-0.7,0.5-1.5,0.3-2.2L321.1,103.9z"/>
                <path className="fill-darkgray" d="M326.5,108.2l-0.3-1c-0.2-0.9-0.8-1.6-1.6-2c-0.5-0.3-1.2-0.5-1.8-0.4l0.4,1.5c0.7,0.1,1.3,0.6,1.5,1.3l0.3,1
		c0.1,0.5,0.1,1-0.2,1.4c-0.1,0.2-0.2,0.4-0.4,0.5l0.4,1.5c0.5-0.3,1-0.7,1.3-1.3C326.7,110,326.8,109.1,326.5,108.2z"/>
                <path className="fill-darkgray"
                      d="M302.8,116.2c0,0.3,0.3,0.6,0.6,0.6h13c0.3,0,0.6-0.3,0.6-0.6v-1.1h-14.1V116.2z"/>
                <path className="fill-darkgray" d="M302.8,103.6h3.5v9.8h-3.5V103.6z"/>
                <path className="fill-darkgray"
                      d="M316.4,100.3h-13c-0.3,0-0.6,0.3-0.6,0.6v1.2H317v-1.2C317,100.5,316.7,100.3,316.4,100.3L316.4,100.3z"/>
                <path className="fill-darkgray" d="M312.5,95.7h-1.2c1.5,0,2.7-1.2,2.7-2.7v-0.8c0-0.3-0.3-0.6-0.6-0.6h-0.8c-1.5,0-2.7,1.2-2.7,2.7
		c0-1.5-1.2-2.7-2.7-2.7h-0.8c-0.3,0-0.6,0.3-0.6,0.6V93c0,1.5,1.2,2.7,2.7,2.7h-1.2c-1.8,0-3.3,1.3-3.7,2.9h12.5
		C315.8,97,314.3,95.7,312.5,95.7L312.5,95.7z"/>
                <path className="fill-darkgray" d="M308,103.6h3.8v9.8H308V103.6z"/>
                <path className="fill-darkgray" d="M313.4,103.6h3.5v9.8h-3.5V103.6z"/>
            </g>
            <polygon fill="#ECF0F1"
                     points="634.7,71.7 438.2,71.7 438.2,150.6 438.2,153.3 438.2,235.3 553.7,235.3 553.7,153.3 634.7,153.3 "/>
            <path className="fill-darkgray" d="M-145.5,176.6"/>
            <path className="fill-darkgray" d="M781.7,129.8c-7.4,0-13.3-6-13.3-13.3V72.7H795v43.7C795,123.8,789,129.8,781.7,129.8 M781.7,130.8L781.7,130.8
	c7.9,0,14.4-6.4,14.4-14.4V71.7h-28.7v44.7C767.3,124.4,773.7,130.8,781.7,130.8L781.7,130.8z"/>
            <path className="fill-darkgray" d="M768.3,233.9v-43.7c0-7.4,6-13.3,13.3-13.3s13.3,6,13.3,13.3v43.7H768.3 M767.3,234.9H796v-44.7
	c0-7.9-6.4-14.4-14.4-14.4l0,0c-7.9,0-14.4,6.4-14.4,14.4v44.7H767.3z"/>
            <path className="fill-darkgray" d="M496.3,129.8c-7.4,0-13.3-6-13.3-13.3V72.7h26.7v43.7C509.6,123.8,503.6,129.8,496.3,129.8 M496.3,130.8
	L496.3,130.8c7.9,0,14.4-6.4,14.4-14.4V71.7H482v44.7C481.9,124.4,488.3,130.8,496.3,130.8L496.3,130.8z"/>
            <path className="fill-darkgray" d="M482.9,233.9v-43.7c0-7.4,6-13.3,13.3-13.3c7.4,0,13.3,6,13.3,13.3v43.7H482.9 M481.9,234.9h28.7v-44.7
	c0-7.9-6.4-14.4-14.4-14.4l0,0c-7.9,0-14.4,6.4-14.4,14.4L481.9,234.9L481.9,234.9z"/>
            <g className={(availableSeats.hasOwnProperty('01') ?
                (selectedSeats.includes('01') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('01')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('01')}>
                <path className="train-seat__border" d="M975.4,234.4c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M975.4,234.9h20.2c1.7,0,3.1-1.4,3.1-3.1V209c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C969.6,232.4,972.2,234.9,975.4,234.9L975.4,234.9z"/>
                <path className="train-seat__dash" d="M1000.2,205.9L1000.2,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C998.8,206.5,999.4,205.9,1000.2,205.9z"/>
                <text transform="matrix(1 0 0 1 975.079 225.9667)" className="train-seat__text">01</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('02') ?
                (selectedSeats.includes('02') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('02')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('02')}>
                <path className="train-seat__border" d="M975.4,201.5c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6V199
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M975.4,202h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C969.6,199.5,972.2,202,975.4,202L975.4,202z"/>
                <path className="train-seat__dash" d="M1000.2,173L1000.2,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C998.8,173.6,999.4,173,1000.2,173z"/>
                <text transform="matrix(1 0 0 1 975.079 193.0731)" className="train-seat__text">02</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('03') ?
                (selectedSeats.includes('03') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('03')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('03')}>
                <path className="train-seat__border" d="M975.4,100.3c-2.9,0-5.2-2.4-5.2-5.2V77.6c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6L975.4,100.3 M975.4,100.8h20.2c1.7,0,3.1-1.4,3.1-3.1V74.9c0-1.7-1.4-3.1-3.1-3.1h-20.2
		c-3.2,0-5.8,2.6-5.8,5.8v17.5C969.6,98.3,972.2,100.8,975.4,100.8L975.4,100.8z"/>
                <path className="train-seat__dash" d="M1000.2,71.7L1000.2,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
		C998.8,72.4,999.4,71.7,1000.2,71.7z"/>
                <text transform="matrix(1 0 0 1 975.0789 91.8618)" className="train-seat__text">03</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('04') ?
                (selectedSeats.includes('04') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('04')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('04')}>
                <path className="train-seat__border" d="M975.4,133.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M975.4,133.7h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		V128C969.6,131.1,972.2,133.7,975.4,133.7L975.4,133.7z"/>
                <path className="train-seat__dash" d="M1000.2,104.6L1000.2,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V106
		C998.8,105.3,999.4,104.6,1000.2,104.6z"/>
                <text transform="matrix(1 0 0 1 975.0789 124.7554)" className="train-seat__text">04</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('05') ?
                (selectedSeats.includes('05') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('05')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('05')}>
                <path className="train-seat__border" d="M934.9,234.4c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M934.9,234.9h20.2c1.7,0,3.1-1.4,3.1-3.1V209c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C929.1,232.4,931.7,234.9,934.9,234.9L934.9,234.9z"/>
                <path className="train-seat__dash" d="M959.7,205.9L959.7,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C958.3,206.5,958.9,205.9,959.7,205.9z"/>
                <text transform="matrix(1 0 0 1 934.5947 225.9668)" className="train-seat__text">05</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('06') ?
                (selectedSeats.includes('06') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('06')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('06')}>
                <path className="train-seat__border" d="M934.9,201.5c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6V199
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M934.9,202h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C929.1,199.5,931.7,202,934.9,202L934.9,202z"/>
                <path className="train-seat__dash" d="M959.7,173L959.7,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C958.3,173.6,958.9,173,959.7,173z"/>
                <text transform="matrix(1 0 0 1 934.5946 193.0732)" className="train-seat__text">06</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('07') ?
                (selectedSeats.includes('07') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('07')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('07')}>
                <path className="train-seat__border" d="M934.9,100.3c-2.9,0-5.2-2.4-5.2-5.2V77.6c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6L934.9,100.3 M934.9,100.8h20.2c1.7,0,3.1-1.4,3.1-3.1V74.9c0-1.7-1.4-3.1-3.1-3.1h-20.2
		c-3.2,0-5.8,2.6-5.8,5.8v17.5C929.1,98.3,931.7,100.8,934.9,100.8L934.9,100.8z"/>
                <path className="train-seat__dash" d="M959.7,71.7L959.7,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
		C958.3,72.4,958.9,71.7,959.7,71.7z"/>
                <text transform="matrix(1 0 0 1 934.5935 91.8619)" className="train-seat__text">07</text>
            </g>

            <g className={(availableSeats.hasOwnProperty('08') ?
                (selectedSeats.includes('08') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('08')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('08')}>
                <path className="train-seat__border" d="M934.9,133.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M934.9,133.7h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		V128C929.1,131.1,931.7,133.7,934.9,133.7L934.9,133.7z"/>
                <path className="train-seat__dash" d="M959.7,104.6L959.7,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V106
		C958.3,105.3,958.9,104.6,959.7,104.6z"/>
                <text transform="matrix(1 0 0 1 934.5935 124.7555)" className="train-seat__text">08</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('09') ?
                (selectedSeats.includes('09') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('09')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('09')}>
                <path className="train-seat__border" d="M894.4,234.4c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M894.4,234.9h20.2c1.7,0,3.1-1.4,3.1-3.1V209c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C888.7,232.4,891.2,234.9,894.4,234.9L894.4,234.9z"/>
                <path className="train-seat__dash" d="M919.2,205.9L919.2,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C917.8,206.5,918.4,205.9,919.2,205.9z"/>
                <text transform="matrix(1 0 0 1 894.1093 225.9668)" className="train-seat__text">09</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('10') ?
                (selectedSeats.includes('10') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('10')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('10')}>
                <path className="train-seat__border" d="M894.4,201.5c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6V199
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M894.4,202h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C888.7,199.5,891.2,202,894.4,202L894.4,202z"/>
                <path className="train-seat__dash" d="M919.2,173L919.2,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C917.8,173.6,918.4,173,919.2,173z"/>
                <text transform="matrix(1 0 0 1 894.1093 193.0732)" className="train-seat__text">10</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('11') ?
                (selectedSeats.includes('11') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('11')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('11')}>
                <path className="train-seat__border" d="M894.4,100.3c-2.9,0-5.2-2.4-5.2-5.2V77.6c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6L894.4,100.3 M894.4,100.8h20.2c1.7,0,3.1-1.4,3.1-3.1V74.9c0-1.7-1.4-3.1-3.1-3.1h-20.2
		c-3.2,0-5.8,2.6-5.8,5.8v17.5C888.7,98.3,891.2,100.8,894.4,100.8L894.4,100.8z"/>
                <path className="train-seat__dash" d="M919.2,71.7L919.2,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
		C917.8,72.4,918.4,71.7,919.2,71.7z"/>
                <text transform="matrix(1 0 0 1 895.4138 91.8619)" className="train-seat__text">11</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('12') ?
                (selectedSeats.includes('12') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('12')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('12')}>
                <path className="train-seat__border" d="M894.4,133.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M894.4,133.7h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		V128C888.7,131.1,891.2,133.7,894.4,133.7L894.4,133.7z"/>
                <path className="train-seat__dash" d="M919.2,104.6L919.2,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V106
		C917.8,105.3,918.4,104.6,919.2,104.6z"/>
                <text transform="matrix(1 0 0 1 894.1082 124.7555)" className="train-seat__text">12</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('13') ?
                (selectedSeats.includes('13') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('13')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('13')}>
                <path className="train-seat__border" d="M853.9,234.4c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M853.9,234.9h20.2c1.7,0,3.1-1.4,3.1-3.1V209c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C848.2,232.4,850.8,234.9,853.9,234.9L853.9,234.9z"/>
                <path className="train-seat__dash" d="M878.7,205.9L878.7,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C877.3,206.5,877.9,205.9,878.7,205.9z"/>
                <text transform="matrix(1 0 0 1 853.874 225.9669)" className="train-seat__text">13</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('14') ?
                (selectedSeats.includes('14') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('14')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('14')}>
                <path className="train-seat__border" d="M853.9,201.5c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6V199
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M853.9,202h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C848.2,199.5,850.8,202,853.9,202L853.9,202z"/>
                <path className="train-seat__dash" d="M878.7,173L878.7,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C877.3,173.6,877.9,173,878.7,173z"/>
                <text transform="matrix(1 0 0 1 853.8739 193.0734)" className="train-seat__text">14</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('15') ?
                (selectedSeats.includes('15') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('15')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('15')}>
                <path className="train-seat__border" d="M853.9,100.3c-2.9,0-5.2-2.4-5.2-5.2V77.6c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6L853.9,100.3 M853.9,100.8h20.2c1.7,0,3.1-1.4,3.1-3.1V74.9c0-1.7-1.4-3.1-3.1-3.1h-20.2
		c-3.2,0-5.8,2.6-5.8,5.8v17.5C848.2,98.3,850.8,100.8,853.9,100.8L853.9,100.8z"/>
                <path className="train-seat__dash" d="M878.7,71.7L878.7,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
		C877.3,72.4,877.9,71.7,878.7,71.7z"/>
                <text transform="matrix(1 0 0 1 853.6228 91.8621)" className="train-seat__text">15</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('16') ?
                (selectedSeats.includes('16') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('16')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('16')}>
                <path className="train-seat__border" d="M853.9,133.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
	c0,1.4-1.2,2.6-2.6,2.6h-20.2 M853.9,133.7h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
	V128C848.2,131.1,850.8,133.7,853.9,133.7L853.9,133.7z"/>
                <path className="train-seat__dash" d="M878.7,104.6L878.7,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V106
	C877.3,105.3,877.9,104.6,878.7,104.6z"/>
                <text transform="matrix(1 0 0 1 853.6228 124.7556)" className="train-seat__text">16</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('17') ?
                (selectedSeats.includes('17') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('17')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('17')}>
                <path className="train-seat__border" d="M813.4,234.4c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M813.4,234.9h20.2c1.7,0,3.1-1.4,3.1-3.1V209c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C807.7,232.4,810.3,234.9,813.4,234.9L813.4,234.9z"/>
                <path className="train-seat__dash" d="M838.3,205.9L838.3,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C836.8,206.5,837.5,205.9,838.3,205.9z"/>
                <text transform="matrix(1 0 0 1 813.3886 225.967)" className="train-seat__text">17</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('18') ?
                (selectedSeats.includes('18') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('18')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('18')}>
                <path className="train-seat__border" d="M813.4,201.5c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6V199
		c0,1.4-1.2,2.6-2.6,2.6h-20.2 M813.4,202h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C807.7,199.5,810.3,202,813.4,202L813.4,202z"/>
                <path className="train-seat__dash" d="M838.3,173L838.3,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C836.8,173.6,837.5,173,838.3,173z"/>
                <text transform="matrix(1 0 0 1 813.3885 193.0735)" className="train-seat__text">18</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('19') ?
                (selectedSeats.includes('19') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('19')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('19')}>
                <path className="train-seat__border" d="M731.2,234.4c-1.4,0-2.6-1.2-2.6-2.6V209c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2v17.5
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M731.2,234.9h20.2c3.2,0,5.8-2.6,5.8-5.8v-17.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C728.1,233.5,729.5,234.9,731.2,234.9L731.2,234.9z"/>
                <path className="train-seat__dash" d="M726.6,234.9L726.6,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C728.1,234.3,727.4,234.9,726.6,234.9z"/>
                <text transform="matrix(1 0 0 1 732.9033 225.967)" className="train-seat__text">19</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('20') ?
                (selectedSeats.includes('20') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('20')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('20')}>
                <path className="train-seat__border" d="M731.2,201.5c-1.4,0-2.6-1.2-2.6-2.6v-22.8c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2v17.5
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M731.2,202h20.2c3.2,0,5.8-2.6,5.8-5.8v-17.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C728.1,200.6,729.5,202,731.2,202L731.2,202z"/>
                <path className="train-seat__dash" d="M726.6,202L726.6,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C728.1,201.4,727.4,202,726.6,202z"/>
                <text transform="matrix(1 0 0 1 732.9032 193.0735)" className="train-seat__text">20</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('21') ?
                (selectedSeats.includes('21') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('21')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('21')}>
                <path className="train-seat__border" d="M813.4,100.3c-2.9,0-5.2-2.4-5.2-5.2V77.6c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6L813.4,100.3 M813.4,100.8h20.2c1.7,0,3.1-1.4,3.1-3.1V74.9c0-1.7-1.4-3.1-3.1-3.1h-20.2
		c-3.2,0-5.8,2.6-5.8,5.8v17.5C807.7,98.3,810.3,100.8,813.4,100.8L813.4,100.8z"/>
                <path className="train-seat__dash" d="M838.3,71.7L838.3,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.2
		C836.8,72.4,837.5,71.7,838.3,71.7z"/>
                <text transform="matrix(1 0 0 1 813.1375 91.8621)" className="train-seat__text">21</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('22') ?
                (selectedSeats.includes('22') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('22')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('22')}>
                <path className="train-seat__border" d="M813.4,133.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
	c0,1.4-1.2,2.6-2.6,2.6h-20.2 M813.4,133.7h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
	V128C807.7,131.1,810.3,133.7,813.4,133.7L813.4,133.7z"/>
                <path className="train-seat__dash" d="M838.3,104.6L838.3,104.6c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V106
	C836.8,105.3,837.5,104.6,838.3,104.6z"/>
                <text transform="matrix(1 0 0 1 813.1375 124.7556)" className="train-seat__text">22</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('23') ?
                (selectedSeats.includes('23') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('23')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('23')}>
                <path className="train-seat__border" d="M731.2,100.3c-1.4,0-2.6-1.2-2.6-2.6V74.9c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2V95
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M731.2,100.8h20.2c3.2,0,5.8-2.6,5.8-5.8V77.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C728.1,99.4,729.5,100.8,731.2,100.8L731.2,100.8z"/>
                <path className="train-seat__dash" d="M726.6,100.8L726.6,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C728.1,100.2,727.4,100.8,726.6,100.8z"/>
                <text transform="matrix(1 0 0 1 733.4468 92.8562)" className="train-seat__text">23</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('24') ?
                (selectedSeats.includes('24') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('24')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('24')}>
                <path className="train-seat__border" d="M731.2,133.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2v17.5
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M731.2,133.7h20.2c3.2,0,5.8-2.6,5.8-5.8v-17.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C728.1,132.3,729.5,133.7,731.2,133.7L731.2,133.7z"/>
                <path className="train-seat__dash" d="M726.6,133.7L726.6,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C728.1,133.1,727.4,133.7,726.6,133.7z"/>
                <text transform="matrix(1 0 0 1 733.4469 125.7498)" className="train-seat__text">24</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('33') ?
                (selectedSeats.includes('33') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('33')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('33')}>
                <path className="train-seat__border" d="M606.4,100.9c-2.9,0-5.2-2.4-5.2-5.2V78.1c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6H606.4 M606.4,101.4h20.2c1.7,0,3.1-1.4,3.1-3.1V75.5c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C600.6,98.8,603.2,101.4,606.4,101.4L606.4,101.4z"/>
                <path className="train-seat__dash" d="M631.2,72.3L631.2,72.3c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.7
		C629.7,72.9,630.4,72.3,631.2,72.3z"/>
                <text transform="matrix(1 0 0 1 605.1764 92.9579)" className="train-seat__text">33</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('34') ?
                (selectedSeats.includes('34') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('34')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('34')}>
                <path className="train-seat__border" d="M606.4,133.8c-2.9,0-5.2-2.4-5.2-5.2V111c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6H606.4 M606.4,134.3h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C600.6,131.7,603.2,134.3,606.4,134.3L606.4,134.3z"/>
                <path className="train-seat__dash" d="M631.2,105.2L631.2,105.2c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C629.7,105.8,630.4,105.2,631.2,105.2z"/>
                <text transform="matrix(1 0 0 1 605.1764 125.8514)" className="train-seat__text">34</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('35') ?
                (selectedSeats.includes('35') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('35')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('35')}>
                <path className="train-seat__border" d="M565.9,100.9c-2.9,0-5.2-2.4-5.2-5.2V78.1c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6H565.9 M565.9,101.4h20.2c1.7,0,3.1-1.4,3.1-3.1V75.5c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C560.1,98.8,562.7,101.4,565.9,101.4L565.9,101.4z"/>
                <path className="train-seat__dash" d="M590.7,72.3L590.7,72.3c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.7
		C589.3,72.9,589.9,72.3,590.7,72.3z"/>
                <text transform="matrix(1 0 0 1 564.691 92.9579)" className="train-seat__text">35</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('36') ?
                (selectedSeats.includes('36') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('36')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('36')}>
                <path className="train-seat__border" d="M565.9,133.8c-2.9,0-5.2-2.4-5.2-5.2V111c0-2.9,2.4-5.2,5.2-5.2h20.2c1.4,0,2.6,1.2,2.6,2.6v22.8
		c0,1.4-1.2,2.6-2.6,2.6H565.9 M565.9,134.3h20.2c1.7,0,3.1-1.4,3.1-3.1v-22.8c0-1.7-1.4-3.1-3.1-3.1h-20.2c-3.2,0-5.8,2.6-5.8,5.8
		v17.5C560.1,131.7,562.7,134.3,565.9,134.3L565.9,134.3z"/>
                <path className="train-seat__dash" d="M590.7,105.2L590.7,105.2c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C589.3,105.8,589.9,105.2,590.7,105.2z"/>
                <text transform="matrix(1 0 0 1 564.6832 125.8573)" className="train-seat__text">36</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('37') ?
                (selectedSeats.includes('37') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('37')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('37')}>
                <path className="train-seat__border" d="M545.6,206.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M545.6,205.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		V209C548.7,207.3,547.3,205.9,545.6,205.9L545.6,205.9z"/>
                <path className="train-seat__dash" d="M550.2,205.9L550.2,205.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C548.8,206.5,549.4,205.9,550.2,205.9z"/>
                <text transform="matrix(1 0 0 1 525.4962 226.968)" className="train-seat__text">37</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('38') ?
                (selectedSeats.includes('38') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('38')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('38')}>
                <path className="train-seat__border" d="M545.6,173.5c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M545.6,173h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C548.7,174.4,547.3,173,545.6,173L545.6,173z"/>
                <path className="train-seat__dash" d="M550.2,173L550.2,173c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C548.8,173.6,549.4,173,550.2,173z"/>
                <text transform="matrix(1 0 0 1 525.4961 194.0745)" className="train-seat__text">38</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('39') ?
                (selectedSeats.includes('39') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('39')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('39')}>
                <path className="train-seat__border" d="M446.2,234.4c-1.4,0-2.6-1.2-2.6-2.6V209c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2v17.5
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M446.2,234.9h20.2c3.2,0,5.8-2.6,5.8-5.8v-17.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C443.1,233.5,444.5,234.9,446.2,234.9L446.2,234.9z"/>
                <path className="train-seat__dash" d="M441.7,234.9L441.7,234.9c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C443.1,234.3,442.5,234.9,441.7,234.9z"/>
                <text transform="matrix(1 0 0 1 448.4731 226.9655)" className="train-seat__text">39</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('40') ?
                (selectedSeats.includes('40') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('40')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('40')}>
                <path className="train-seat__border" d="M446.2,201.5c-1.4,0-2.6-1.2-2.6-2.6v-22.8c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2v17.5
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M446.2,202h20.2c3.2,0,5.8-2.6,5.8-5.8v-17.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C443.1,200.6,444.5,202,446.2,202L446.2,202z"/>
                <path className="train-seat__dash" d="M441.7,202L441.7,202c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C443.1,201.4,442.5,202,441.7,202z"/>
                <text transform="matrix(1 0 0 1 448.473 194.0719)" className="train-seat__text">40</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('41') ?
                (selectedSeats.includes('41') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('41')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('41')}>
                <path className="train-seat__border" d="M545.6,72.4c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.7
		c0-2.9,2.4-5.2,5.2-5.2L545.6,72.4 M545.6,71.9h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2
		c1.7,0,3.1-1.4,3.1-3.1V75C548.7,73.3,547.3,71.9,545.6,71.9L545.6,71.9z"/>
                <path className="train-seat__dash" d="M550.2,71.9L550.2,71.9c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.3
	C548.8,72.5,549.4,71.9,550.2,71.9z"/>
                <text transform="matrix(1 0 0 1 525.498 92.9729)" className="train-seat__text">41</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('42') ?
                (selectedSeats.includes('42') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('42')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('42')}>
                <path className="train-seat__border" d="M545.6,105.3c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M545.6,104.8h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		V108C548.7,106.2,547.3,104.8,545.6,104.8L545.6,104.8z"/>
                <path className="train-seat__dash" d="M550.2,104.8L550.2,104.8c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C548.8,105.4,549.4,104.8,550.2,104.8z"/>
                <text transform="matrix(1 0 0 1 525.4988 125.8657)" className="train-seat__text">42</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('43') ?
                (selectedSeats.includes('43') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('43')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('43')}>
                <path className="train-seat__border" d="M446.2,100.3c-1.4,0-2.6-1.2-2.6-2.6V74.9c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2V95
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M446.2,100.8h20.2c3.2,0,5.8-2.6,5.8-5.8V77.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C443.1,99.4,444.5,100.8,446.2,100.8L446.2,100.8z"/>
                <path className="train-seat__dash" d="M441.7,100.8L441.7,100.8c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C443.1,100.2,442.5,100.8,441.7,100.8z"/>
                <text transform="matrix(1 0 0 1 448.4732 91.8591)" className="train-seat__text">43</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('44') ?
                (selectedSeats.includes('44') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('44')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('44')}>
                <path className="train-seat__border" d="M446.2,133.2c-1.4,0-2.6-1.2-2.6-2.6v-22.8c0-1.4,1.2-2.6,2.6-2.6h20.2c2.9,0,5.2,2.4,5.2,5.2v17.5
		c0,2.9-2.4,5.2-5.2,5.2h-20.2 M446.2,133.7h20.2c3.2,0,5.8-2.6,5.8-5.8v-17.5c0-3.2-2.6-5.8-5.8-5.8h-20.2c-1.7,0-3.1,1.4-3.1,3.1
		v22.8C443.1,132.3,444.5,133.7,446.2,133.7L446.2,133.7z"/>
                <path className="train-seat__dash" d="M441.7,133.7L441.7,133.7c-2,0-3.5-1.6-3.5-3.5v-22c0-2,1.6-3.5,3.5-3.5l0,0c0.8,0,1.4,0.6,1.4,1.4v26.2
		C443.1,133.1,442.5,133.7,441.7,133.7z"/>
                <text transform="matrix(1 0 0 1 448.4732 124.7527)" className="train-seat__text">44</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('45') ?
                (selectedSeats.includes('45') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('45')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('45')}>
                <path className="train-seat__border" d="M278.7,207c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M278.7,206.4h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C281.8,207.8,280.4,206.4,278.7,206.4L278.7,206.4z"/>
                <path className="train-seat__dash" d="M283.2,206.4L283.2,206.4c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C281.8,207,282.4,206.4,283.2,206.4z"/>
                <text transform="matrix(1 0 0 1 257.7212 227.0643)" className="train-seat__text">45</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('46') ?
                (selectedSeats.includes('46') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('46')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('46')}>
                <path className="train-seat__border" d="M278.7,174.1c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M278.7,173.6h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C281.8,174.9,280.4,173.6,278.7,173.6L278.7,173.6z"/>
                <path className="train-seat__dash" d="M283.2,173.5L283.2,173.5c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C281.8,174.2,282.4,173.5,283.2,173.5z"/>
                <text transform="matrix(1 0 0 1 257.7212 194.1708)" className="train-seat__text">46</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('47') ?
                (selectedSeats.includes('47') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('47')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('47')}>
                <path className="train-seat__border" d="M226.6,207c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M226.6,206.4h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C229.7,207.8,228.3,206.4,226.6,206.4L226.6,206.4z"/>
                <path className="train-seat__dash" d="M231.2,206.4L231.2,206.4c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C229.8,207,230.4,206.4,231.2,206.4z"/>
                <text transform="matrix(1 0 0 1 205.6781 227.0643)" className="train-seat__text">47</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('48') ?
                (selectedSeats.includes('48') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('48')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('48')}>
                <path className="train-seat__border" d="M226.6,174.1c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M226.6,173.6h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C229.7,174.9,228.3,173.6,226.6,173.6L226.6,173.6z"/>
                <path className="train-seat__dash" d="M231.2,173.5L231.2,173.5c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C229.8,174.2,230.4,173.5,231.2,173.5z"/>
                <text transform="matrix(1 0 0 1 205.6781 194.1708)" className="train-seat__text">48</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('49') ?
                (selectedSeats.includes('49') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('49')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('49')}>
                <path className="train-seat__border" d="M258.9,72.2c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2V77.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M258.9,71.7h-20.2c-3.2,0-5.8,2.6-5.8,5.8V95c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1V74.9
		C262.1,73.1,260.7,71.7,258.9,71.7L258.9,71.7z"/>
                <path className="train-seat__dash" d="M263.5,71.7L263.5,71.7c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4V73.1
		C262.1,72.3,262.7,71.7,263.5,71.7z"/>
                <text transform="matrix(1 0 0 1 237.6018 91.8591)" className="train-seat__text">49</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('51') ?
                (selectedSeats.includes('51') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('51')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('51')}>
                <path className="train-seat__border" d="M330.7,207c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M330.7,206.4h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C333.8,207.8,332.4,206.4,330.7,206.4L330.7,206.4z"/>
                <path className="train-seat__dash" d="M335.3,206.4L335.3,206.4c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C333.8,207,334.5,206.4,335.3,206.4z"/>
                <text transform="matrix(1 0 0 1 309.7643 227.0643)" className="train-seat__text">51</text>
            </g>
            <g className={(availableSeats.hasOwnProperty('52') ?
                (selectedSeats.includes('52') ? 'selected' : 'available')
                : 'disabled') + ' train-seat'}
               onMouseEnter={() => handleHover('52')}
               onMouseLeave={() => handleEndHover()}
               onClick={() => handleClick('52')}>
                <path className="train-seat__border" d="M330.7,174.1c1.4,0,2.6,1.2,2.6,2.6v22.8c0,1.4-1.2,2.6-2.6,2.6h-20.2c-2.9,0-5.2-2.4-5.2-5.2v-17.5
		c0-2.9,2.4-5.2,5.2-5.2h20.2 M330.7,173.6h-20.2c-3.2,0-5.8,2.6-5.8,5.8v17.5c0,3.2,2.6,5.8,5.8,5.8h20.2c1.7,0,3.1-1.4,3.1-3.1
		v-22.8C333.8,174.9,332.4,173.6,330.7,173.6L330.7,173.6z"/>
                <path className="train-seat__dash" d="M335.3,173.5L335.3,173.5c2,0,3.5,1.6,3.5,3.5v22c0,2-1.6,3.5-3.5,3.5l0,0c-0.8,0-1.4-0.6-1.4-1.4v-26.2
		C333.8,174.2,334.5,173.5,335.3,173.5z"/>
                <text transform="matrix(1 0 0 1 309.7643 194.1708)" className="train-seat__text">52</text>
            </g>
        </svg>
    );
}
