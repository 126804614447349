import * as types from './../constants/ActionTypesBooking';

export function paramSet(key,
                         value) {
    return {
        type: types.BOOKING_PARAM_SET,
        payload: {
            key,
            value,
        },
    }
}

export function fetchList(data) {
    return {
        type: types.BOOKING_FEATCH_SEATS,
        payload: data,
    }
}

export function clear() {
    return {
        type: types.BOOKING_CLEAR,
    }
}

export function clearNew(global_order_id) {
    return {
        type: types.BOOKING_CLEAR_NEW,
        payload: global_order_id
    }
}
export function getStorage(global_order_id) {
    return {
        type: types.BOOKING_GET_STORAGE,
        payload: global_order_id
    }
}

export function selectSeat(car,
                           seat) {
    return {
        type: types.BOOKING_SELECT_SEAT,
        payload: {
            car,
            seat,
        },
    }
}

export function removeSeat(car,
                           seat) {
    return {
        type: types.BOOKING_REMOVE_SEAT,
        payload: {
            car,
            seat,
        },
    }
}

export function seatDataUpdate(car,
                               seat,
                               key,
                               value) {
    return {
        type: types.BOOKING_SEAT_DATA_UPDATE,
        payload: {
            car,
            seat,
            key,
            value,
        },
    }
}

export function seatsClear() {
    return {
        type: types.BOOKING_SEATS_CLEAR,
    }
}

export function bookingStep(step) {
    return {
        type: types.BOOKING_SET_STEP,
        payload: {step},
    }
}
