import {connect} from 'react-redux';
import Archive from './../../components/Cabinet/Archive';
import {getReservationsArchive, ArchiveDelete, getReservationsArchiveDelete} from "../../actions/User";

const mapStateToProps =
  state => {
    return {

      list  : state.user.refunded,
      cities: state.citiesglobal,
      status: state.statusglobal,
    };
  }

export default connect(mapStateToProps, {
    getReservationsArchive, getReservationsArchiveDelete
})(Archive);
